import { api } from "../../../../utils/api"
import { Serialize, Deserialize } from "cerialize"
import { FileUploadResponse } from "../../../../models/common/fileUpload.model"
import { RequestErrorResponse } from "../../../../models/common/error.model"
import moment from "moment"
import { SceneGetResponse, SceneSaveRequest, SceneNumbersModel } from "../../../../models/ScenesModels/scene.model"
import { sceneFormGetStore, sceneFormSaveStore } from "../../../../stores/CRM/AdminCRM/scenes/sceneForm.store"
import { sceneModerationStore } from "../../../../stores/CRM/AdminCRM/scenes/sceneModeration.store"
import { platformModerationStore } from "../../../../stores/CRM/AdminCRM/scenes/platformModeration.store"

export function getSceneForm(id?: number): Promise<void> {
  const userRole = localStorage.getItem('userRole')
  const url = userRole === 'ADMIN' ? `/crm/admin/place_forms/${id}/` : `/crm/expert/place_forms/${id}/`
  sceneFormGetStore.setIsLoading(true)

  return api.get(
    url
  ).then((res) => {

    const data: SceneGetResponse = Deserialize(res.data, SceneGetResponse)
    const partnerLogos: Array<FileUploadResponse | undefined> = []

    if (data.partnerLogos?.length) {
      data.partnerLogos.forEach(partnerLogo => partnerLogos.push(partnerLogo))
    }

    while (partnerLogos && partnerLogos.length < 8) {
      partnerLogos.push(undefined)
    }

    platformModerationStore.setPlatformStatus(data.areaForm.status)

    sceneFormGetStore.setSceneData(data)
    sceneFormGetStore.setFormData({
      ...data,
      partnerLogos,
      genres: data.genres.map(genre => genre.label)
    })
    sceneFormGetStore.setIsLoading(false)
    sceneModerationStore.setSceneName(data.name)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse =  error.response.data
    sceneFormGetStore.setIsLoading(false)
    sceneFormGetStore.setErrors(errorResponseData.errors)
    throw error
  })
}


export function saveSceneForm(request: SceneSaveRequest, id?: number): Promise<void> {
  const userRole = localStorage.getItem('userRole')
  const url = userRole === 'ADMIN' ? `/crm/admin/place_forms/${id}/` : `/crm/expert/place_forms/${id}/`
  const prepared = {
    photoSite: request.photoSite?.id || null,
    photoSite2: request.photoSite2?.id || null,
    photoSiteHeader: request.photoSiteHeader?.id || null,
    partnerLogos: request.partnerLogos.filter(Boolean).map(partnerLogo => partnerLogo?.id)
  }
  const serializedData = Serialize({...request, ...prepared}, SceneSaveRequest)
  const serializedNumbers = Serialize(request.numbers, SceneNumbersModel)
  
  sceneFormSaveStore.setIsLoading(true)

  return api.put(
    url,
    {
      ...serializedData,
      numbers: serializedNumbers
    }
  ).then((res) => {
    const data: SceneGetResponse = Deserialize(res.data, SceneGetResponse)

    platformModerationStore.setPlatformStatus(data.areaForm.status)
    sceneFormSaveStore.setIsLoading(false)
    sceneFormSaveStore.setSaveTime(moment().toDate())
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse =  error.response.data
    sceneFormSaveStore.setIsLoading(false)
    sceneFormSaveStore.setErrors(errorResponseData.errors)
    throw error
  })
}
import React from 'react'
import { ReactComponent as Add } from '../../../../../assets/icons/add.svg'

import './AddMemberButton.style.sass'

interface PropTypes {
  onClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
  text: string;
}

const block = 'add-member-button'

export default class AddMemberButtonComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    return (
      <div className={block} onClick={this.props.onClick}>
        <div className={`${block}__icon`}>
          <Add />
        </div>
        <div className={`${block}__text`}>
          {this.props.text}
        </div>
      </div>
    )
  }
}
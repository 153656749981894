import { observable, action } from 'mobx'
import { RequestError } from '../../models/common/error.model'

export class ApiRequestStore {
  @observable requestErrors?: RequestError[]
  @observable isLoading = false

  @action
  setErrors(errors: RequestError[]): void {
    this.requestErrors = errors
  }

  @action
  clearErrors(): void {
    this.requestErrors = undefined
  }

  @action
  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }
}

import React, { FC } from 'react'
import { 
  SelectionExpertArtistGetResponse 
} from '../../../../../models/SelectionExpertsModels/selectionExpertEvaluation.model'
import Button from '../../../../Button/Button.component'
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/arrow-icon.svg'

import './style.sass'
import GenreRadioButtonComponent from '../../../common/GenreRadioButton/GenreRadioButton.component'
import { genreRadioButtonsData } from '../../../ArtistCRM/ArtistForm/data.const'
import { ReactComponent as InstagramIcon } from '../../../../../assets/icons/instagram.svg'
import { ReactComponent as VKIcon } from '../../../../../assets/icons/vk.svg'
import { ReactComponent as TikTokIcon } from '../../../../../assets/icons/tiktok.svg'
import { ReactComponent as SpotifyIcon } from '../../../../../assets/icons/spotify-icon.svg'
import { ReactComponent as FacebookIcon } from '../../../../../assets/icons/fb.svg'
import { ReactComponent as SoundcloudIcon } from '../../../../../assets/icons/soundcloud.svg'

interface Props {
  artistData: SelectionExpertArtistGetResponse;
  onBackClick: () => void;
}

type GenreIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement>>

const block = 'evaluation-artist-info'

const ArtistInfo: FC<Props> = (props) => {
  const { artistData, onBackClick } = props

  return (
    <div className={block}>
      <div className={`${block}__back-button`}>
        <Button 
          text="Назад"
          icon={<ArrowIcon />} 
          onClick={onBackClick}
          background="black"
        />
      </div>
      <div className={`${block}__title`}>
        {artistData.name}
      </div>

      <div className={`${block}__city`}>
        {artistData.city}
      </div>

      <div className={`${block}__genre`}>
        <GenreRadioButtonComponent
          checked={false}
          caption={genreRadioButtonsData.find(genre => genre.value === artistData.genre.name)?.caption as string}
          name={artistData.genre.name}
          value={artistData.genre.name}
          onChange={(): null => null}
          icon={genreRadioButtonsData.find(genre => genre.value === artistData.genre.name)?.icon as GenreIcon}
          type="radio"
          disabled={true}
        />
      </div>

      <div className={`${block}__description`}>
        {artistData.description}
      </div>

      <div className={`${block}__links`}>
        {artistData.audioUrls.map((url, index) => (
          <div key={`${url}-${index}`}>
            <a target="_blank" rel="noopener noreferrer" href={url} className={`${block}__link`}>
              {url}
            </a>
          </div>
        ))}
        {artistData.videoUrls.map((url, index) => (
          <div key={`${url}-${index}`}>
            <a target="_blank" rel="noopener noreferrer" href={url} className={`${block}__link`}>
              {url}
            </a>
          </div>
        ))}
      </div>

      <div className={`${block}__social`}>
        {artistData.instagramUrl && (
          <a 
            href={artistData.instagramUrl} 
            className={`${block}__social-item`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </a>
        )}

        {artistData.vkontakteUrl && (
          <a 
            href={artistData.vkontakteUrl} 
            className={`${block}__social-item`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <VKIcon />
          </a>
        )}

        {artistData.tiktokUrl && (
          <a 
            href={artistData.tiktokUrl} 
            className={`${block}__social-item`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TikTokIcon />
          </a>
        )}

        {artistData.facebookUrl && (
          <a 
            href={artistData.facebookUrl} 
            className={`${block}__social-item`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
        )}

        {artistData.soundcloudUrl && (
          <a 
            href={artistData.soundcloudUrl} 
            className={`${block}__social-item`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SoundcloudIcon />
          </a>
        )}

        {artistData.spotifyUrl && (
          <a 
            href={artistData.spotifyUrl} 
            className={`${block}__social-item`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SpotifyIcon />
          </a>
        )}
      </div>
    </div>
  )
}

export default ArtistInfo
import React, { FC } from 'react'
import { ArtistRating } from '../../../../models/ArtistFormNotificationsModels/ArtistFormNotifications.model'
import Evaluation from '../Evaluation/Evaluation'
import './ArtistEvaluationsBlock.style.sass'


type Props = {
  artistRating: ArtistRating;
}
const ArtistEvaluationsBlock: FC<Props> = ({artistRating}) => {
  const {music, emotional, concertActivities, total} = artistRating
  const qualityMaxValue = 10
  const emotionamMaxValue = 10
  const experienceMaxValue = 5

  const totalMaxValue = qualityMaxValue + emotionamMaxValue + experienceMaxValue
  return (
    <div className="evaluations">
      <Evaluation
        title={'качество музыкальных произведений'}
        evaluationMaxValue={qualityMaxValue}
        evaluationValue={music}
      />
      <Evaluation
        title={'Уровень эмоционального отклика'}
        evaluationMaxValue={emotionamMaxValue}
        evaluationValue={emotional}
      />
      <Evaluation
        title={'Опыт концертной деятельности'}
        evaluationMaxValue={experienceMaxValue}
        evaluationValue={concertActivities}
      />
      <Evaluation
        title={'Ваш средний балл'}
        evaluationMaxValue={totalMaxValue}
        evaluationValue={total}
        isTotal
      />
    </div>
  )
}

export default ArtistEvaluationsBlock

import { registrationStore, loginStore, recoverPasswordStore } from "../../stores/MainPage/auth.store"
import { api, deleteToken, setToken } from "../../utils/api"
import {
  RegisterRequest,
  RegisterResponse
} from "../../models/AuthModels/register.model"
import { Serialize, Deserialize } from "cerialize"
import { RequestErrorResponse } from "../../models/common/error.model"
import { LoginRequest, LoginResponse, PassRecoveryRequest } from "../../models/AuthModels/login.model"
import { userStore } from "../../stores/common/user.store"
import { setUserEmail, setUserID, setUserRole } from "../../utils/localStorage"

export function registerUser(request: RegisterRequest): Promise<void> {
  const serializedData = Serialize(request, RegisterRequest)
  registrationStore.setIsLoading(true)

  return api
    .post("/crm/artist/registration/", serializedData)
    .then((res) => {
      const data: RegisterResponse = Deserialize(res.data, RegisterResponse)

      registrationStore.setIsLoading(false)
      userStore.setUserData(data.user)
      setToken(data.token)
      setUserEmail(data.user.email)
      setUserID(data.user.profileId)
      setUserRole(data.user.crmRole)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response.data
      registrationStore.setIsLoading(false)
      registrationStore.setErrors(errorResponseData.errors)
      throw error
    })
}

export function logIn(request: LoginRequest): Promise<void> {
  const serializedData = Serialize(request, LoginRequest)
  loginStore.setIsLoading(true)

  return api
    .post("/crm/login/", serializedData)
    .then((res) => {
      const data: LoginResponse = Deserialize(res.data, LoginResponse)

      loginStore.setIsLoading(false)
      userStore.setUserData(data.user)
      setToken(data.token)
      setUserEmail(data.user.email)
      setUserID(data.user.profileId)
      setUserRole(data.user.crmRole)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response.data
      loginStore.setIsLoading(false)
      loginStore.setErrors(errorResponseData.errors)
      throw error
    })
}

export function clearErrors(): void {
  registrationStore.clearErrors()
  loginStore.clearErrors()
}

export function recoverUserPassword(request: PassRecoveryRequest): Promise<void> {
  const serializedData = Serialize(request, PassRecoveryRequest)
  recoverPasswordStore.setIsLoading(true)

  deleteToken()

  return api
    .post(
      "/auth/password_reset/", 
      serializedData
    )
    .then(() => {
      recoverPasswordStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response.data
      recoverPasswordStore.setIsLoading(false)
      recoverPasswordStore.setErrors(errorResponseData.errors)
      throw error
    })
}
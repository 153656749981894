import React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"

import SearchComponent from "../../components/Search/Search.component"
import { debounce } from "debounce"

interface State {
  value: string;
}

interface PropTypes {
  onSearchChange?: (value: string) => void;
}

class SearchInputContainer extends React.Component<RouteComponentProps & PropTypes, State> {
  private onSearchChange = (value: string): void => {
    this.props.onSearchChange && this.props.onSearchChange(value)
  }

  state: State = {
    value: new URLSearchParams(this.props.location.search).get("name") || ''
  };

  constructor(props) {
    super(props)
    this.onSearchChange = debounce(this.onSearchChange.bind(this), 300)
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ value: event.target.value })
    if (event.target.value.trim() === "") {
      this.props.history.push(`${this.props.location.pathname}`)
    } else {
      this.props.history.push(
        `${this.props.location.pathname}?name=${event.target.value}`
      )
    }

    this.onSearchChange(event.target.value)
  };
  onClear = (): void => {
    this.setState({ value: "" })
    this.props.history.push(`${this.props.location.pathname}`)

    this.props.onSearchChange && this.props.onSearchChange('')
  };

  render(): JSX.Element {
    const { value } = this.state
    return (
      <SearchComponent
        value={value}
        onChange={this.onChange}
        onClear={this.onClear}
      />
    )
  }
}

export default withRouter(SearchInputContainer)

import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { useHistory } from 'react-router'
import 
  EvaluateFormComponent 
from 
  '../../../components/CRM/SelectionExpertCRM/SelectionExpertEvaluation/EvaluateForm/EvaluateForm.component'
import { PATH_SELECTION_EXPERT_DASHBOARD } from '../../../const/routes.const'
import { 
  EvaluateForm, SelectionExpertArtistEvaluateRequest
} from '../../../models/SelectionExpertsModels/selectionExpertEvaluation.model'
import { 
  evaluateArtist
} from '../../../services/CRM/SelectionExpertCRM/evaluation.service'
import {
  selectionExpertEvaluateStore,
  selectionExpertArtistIdGetStore
} from '../../../stores/CRM/SelectionExpertCRM/selectionExpertEvaluation.store'

const EvaluateFormContainer: FC = () => {
  const history = useHistory()

  const onSubmit = (request: EvaluateForm): void => {
    const preparedRequest: SelectionExpertArtistEvaluateRequest = {
      musicRating: parseInt(request.musicRating),
      emotionalRating: parseInt(request.emotionalRating),
      concertActivitiesRating: parseInt(request.concertActivitiesRating)
    }

    evaluateArtist(preparedRequest).then(() => {
      history.push(PATH_SELECTION_EXPERT_DASHBOARD)
    })
  }

  const {
    isLoading
  } = selectionExpertEvaluateStore

  const { rating, artist } = selectionExpertArtistIdGetStore

  return (
    <EvaluateFormComponent 
      onSubmit={onSubmit}
      isLoading={isLoading}
      error={selectionExpertEvaluateStore.requestErrors && selectionExpertEvaluateStore.requestErrors[0].text}
      data={rating}
      genre={artist?.genre}
    />
  )
}

export default observer(EvaluateFormContainer)
import React from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import PlatformItemContainer from "../../../../containers/CRM/AdminCRM/PlatformList/PlatformItem/PlatformItem.container"
import SearchInputContainer from "../../../../containers/SearchInput/SearchInput.container"
import CircleButtonComponent from "../../common/CircleButton/CircleButton.component"
import { ReactComponent as PlusIcon } from "../../../../assets/icons/icon-plus.svg"
import { 
  ListPlatformModel, 
  PlatformListGetResponse, 
  AdminPlatformListCategories 
} from "../../../../models/ScenesModels/platformList.model"
import LoaderComponent from "../../../common/Loader/Loader.component"
import ModalComponent from "../../../common/Modal/Modal.component"
import InputComponent from "../../common/Input/Input.component"
import Button from "../../../Button/Button.component"
import classNames from "classnames"

import "react-tabs/style/react-tabs.css"
const block = "platform-list"

interface PropTypes {
  tabIndex: number;
  isLoading: boolean;
  platforms: PlatformListGetResponse | ListPlatformModel[] | null;
  onSelect: (tabIndex: number) => void;
  onPlatformNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  newPlatformName: string;
  onPlatformCreate: () => void;
  onModalToggle: () => void;
  onPlusButtonClick: () => void;
  isModalVisible: boolean;
  isPlatformCreating: boolean;
}

const tabNames: { [key in AdminPlatformListCategories]: string } = {
  newCategory: "Новые",
  remoderation: "На повторной модерации",
  approved: "Утвержденные",
  checked: "Вычитанные"
}

export default class PlatformListComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const {
      tabIndex,
      isLoading,
      platforms,
      onSelect,
      onPlatformCreate,
      newPlatformName,
      onPlatformNameChange,
      onModalToggle,
      onPlusButtonClick,
      isModalVisible,
      isPlatformCreating
    } = this.props
    const userRole = localStorage.getItem('userRole')

    return (
      <div className={`${block} admin-layout-content`}>
        {isLoading ? (
          <div className="admin-layout-content__loading">
            <LoaderComponent width={100} height={100} />
          </div>
        ) : (
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(tabIndex: number): void => onSelect(tabIndex)}
          >
            <div className="admin-layout-content__header">
              {userRole === 'ADMIN' && platforms && (
                <>
                <TabList>
                  {Object.keys(platforms as PlatformListGetResponse).map((platformListCategory, index): JSX.Element => (
                    <Tab key={`platforms-tab-${index}`}>
                      {tabIndex === index && (
                        <div className="admin-layout-content__counter">
                          {(platforms as PlatformListGetResponse)[
                            platformListCategory as AdminPlatformListCategories
                          ] ? (
                            (platforms as PlatformListGetResponse)[platformListCategory as AdminPlatformListCategories]
                              .length
                          ) : (
                            0
                          )}
                        </div>
                      )}
                      {tabNames[platformListCategory as AdminPlatformListCategories]}
                    </Tab>
                  ))}
                </TabList>

                <div className="admin-layout-content__controls">
                  <SearchInputContainer />
                  <div
                    className="admin-layout-content__add"
                  >
                    <p className="admin-layout-content__add-title">Новая площадка</p>
                    <CircleButtonComponent onClick={onPlusButtonClick}>
                      <PlusIcon fill="#FFFFFF" />
                    </CircleButtonComponent>
                  </div>
                </div>

                <ModalComponent isVisible={isModalVisible} title="Создать новую площадку" onModalClose={onModalToggle}>
                  <div className="margin margin--top-s">
                    <InputComponent 
                      name="platform-name"
                      placeholder="Введите название площадки"
                      value={newPlatformName}
                      onChange={onPlatformNameChange}
                    />
                  </div>
                  <div className="margin margin--top-s margin--auto margin--bottom-0">
                    <Button
                      onClick={onPlatformCreate}
                      text="Создать площадку"
                      isDisabled={!newPlatformName.length}
                      background="purple"
                      isLoading={isPlatformCreating}
                    />
                  </div>
                </ModalComponent>
                </>
              )}

              {userRole === 'EXPERT' && (
                <SearchInputContainer />
              )}
            </div>

            <div className={classNames(
              "admin-layout-content__body",
              userRole === 'EXPERT' && "admin-layout-content__body--padding-top-s"
            )}>
              {userRole === 'ADMIN' && platforms ?
                Object.keys(platforms).map((platformListCategory, index): JSX.Element => (
                  <TabPanel key={`platforms-tab-body-${platformListCategory}`}>
                    {platforms[platformListCategory].length ? (
                      platforms[platformListCategory].map((platform: ListPlatformModel) => (
                      <PlatformItemContainer 
                        platformData={platform}
                        key={platform.avatar || `${tabNames[platformListCategory]}-platform-${platform.id}`}
                        platformCategory={(Object.keys(tabNames) as AdminPlatformListCategories[])[index]}
                      />
                    ))) : (
                      <div className="admin-layout-content__empty">
                        Список пуст
                      </div>
                    )}
                  </TabPanel>
                )) : (
                  userRole === 'EXPERT' && platforms && (platforms as ListPlatformModel[]).length ? (
                    (platforms as ListPlatformModel[]).map((platform: ListPlatformModel, index) => (
                      <PlatformItemContainer 
                        platformData={platform}
                        key={platform.avatar || `platform-${index}`}
                        platformCategory={(Object.keys(tabNames) as AdminPlatformListCategories[])[index]}
                      />
                    )
                  )) : (
                    <div className="admin-layout-content__empty">
                      Список пуст
                    </div>
                  )
                )}
            </div>
          </Tabs>
        )}
      </div>
    )
  }
}

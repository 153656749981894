import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getExpertList } from '../../../../services/CRM/AdminCRM/experts/expertList.service'
import { observer } from 'mobx-react'
import { PATH_EXPERTS } from '../../../../const/routes.const'
import 
  SelectionExpertListComponent 
from 
  '../../../../components/CRM/AdminCRM/SelectionExpertList/SelectionExpertList.components'
import { selectionExpertListStore } from '../../../../stores/CRM/AdminCRM/selectionExperts/selectionExpertsList.store'
import { forceCheck } from 'react-lazyload'

@observer
class SelectionExpertListContainer extends React.Component<RouteComponentProps> {
  componentDidMount(): void {
    getExpertList()
  }

  componentWillUnmount(): void {
    selectionExpertListStore.clearData()
    selectionExpertListStore.clearErrors()
  }

  componentDidUpdate(prevProps: RouteComponentProps): void {
    if (prevProps.location.search !== this.props.location.search) {
      forceCheck()
    }
  }

  goToExpert = (id: number): void => {
    this.props.history.push(`${PATH_EXPERTS}/${id}`)
  }

  render(): JSX.Element {
    const {
      location: { search }
    } = this.props
    const params = new URLSearchParams(search)
    const query = params.get("name")

    return (
      <SelectionExpertListComponent 
        isLoading={selectionExpertListStore.isLoading}
        onExpertClick={this.goToExpert}
        experts={selectionExpertListStore.getFilteredExperts(query || '')}
      />
    )
  }
}

export default withRouter(SelectionExpertListContainer)
import React, { useEffect } from 'react'
import 
  ArtistsIntersectionsComponent 
from 
  '../../../../components/CRM/AdminCRM/ArtistsIntersections/ArtistsIntersections.component'
import { getArtistsIntersections } from '../../../../services/CRM/AdminCRM/artists/artistsIntersections.service'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { artistsIntersectionsStore } from '../../../../stores/CRM/AdminCRM/artists/artistsIntersections.strore'
import { observer } from 'mobx-react'

const intersectionsToLoad = 20

function ArtistsIntersectionsContainer(props: RouteComponentProps): JSX.Element {
  const getIntersections = (search?: string, limit?: number, offset?: number): void => {

    const requestParams = {
      limit,
      offset,
      search
    }

    getArtistsIntersections(requestParams)
  }

  const onSearchChange = (value: string): void => {
    artistsIntersectionsStore.clearData()

    getIntersections(
      value || ''
    )
  }

  const loadMore = (): void => {
    const search = new URLSearchParams(props.location.search).get("name") || undefined
    const currentOffset = artistsIntersectionsStore.currentOffset

    getIntersections(
      search,
      intersectionsToLoad,
      currentOffset ? (
        currentOffset + intersectionsToLoad
      ) : intersectionsToLoad
    )

    artistsIntersectionsStore.setOffset(currentOffset ? currentOffset + intersectionsToLoad : intersectionsToLoad)
  }

  const getShouldLoadMore = (): boolean => {
    const { count, artistIntersections } = artistsIntersectionsStore

    if (count && artistIntersections) {
      return count > artistIntersections.length
    } else return true
  }

  useEffect(() => {
    const searchString = new URLSearchParams(props.location.search).get("name") || undefined

    getIntersections(searchString)

    return (): void => {
      artistsIntersectionsStore.clearData()
    }
  }, [])

  return (
    <ArtistsIntersectionsComponent 
      isLoading={artistsIntersectionsStore.isLoading}
      onSearchChange={onSearchChange}
      onLoadMore={loadMore}
      shouldLoadMore={getShouldLoadMore()}
      intersectionsData={artistsIntersectionsStore.artistIntersections}
    />
  )
}

export default withRouter(observer(ArtistsIntersectionsContainer))
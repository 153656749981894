import React from 'react'
import SearchInputContainer from '../../../../containers/SearchInput/SearchInput.container'
import { ArtistIntersectionModel } from '../../../../models/ArtistsIntersectionsModels/ArtistsIntersections.model'
import LoaderComponent from '../../../common/Loader/Loader.component'
import LazyLoad from 'react-lazyload'
import LazyLoadComponent from '../../common/LazyLoad/LazyLoad.component'
import ArtistIntersections from './ArtistIntersectionsItem/ArtistIntersectionsItem.component'

interface PropTypes {
  isLoading: boolean;
  intersectionsData?: ArtistIntersectionModel[];
  onSearchChange: (value: string) => void;
  onLoadMore: () => void;
  shouldLoadMore: boolean;
}

const block = 'artists-intersections'

export default function ArtistsIntersectionsComponent(props: PropTypes): JSX.Element {
  const { 
    isLoading, 
    intersectionsData, 
    onSearchChange,
    onLoadMore,
    shouldLoadMore
  } = props

  return (
    <div className={`${block} admin-layout-content`}>
      <div className="admin-layout-content__header">
        <div className="admin-layout-content__controls">
          <SearchInputContainer onSearchChange={onSearchChange} />
        </div>
      </div>
      {isLoading && !intersectionsData?.length && (
        <div className="admin-layout-content__loading">
          <LoaderComponent width={100} height={100} />
        </div>
      )}
      <div className="admin-layout-content__body admin-layout-content__body--padding-top-s">
        {intersectionsData && intersectionsData.length ? (
          <>
          {intersectionsData.map((intersection: ArtistIntersectionModel) => (
            <ArtistIntersections
              name={intersection.name} 
              artists={intersection.artists}
              key={intersection.id} 
            />
          ))}
          {shouldLoadMore && (
            <LazyLoad height={10} unmountIfInvisible={true}>
              <LazyLoadComponent isLoading={isLoading} onMount={onLoadMore} />
            </LazyLoad>
          )}
          </>
        ) : !isLoading && (
          <div className="admin-layout-content__empty">
            Список пуст
          </div>
        )}
      </div>
    </div>
  )
}
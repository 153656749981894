import React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import AdminHeader from "../AdminHeader/AdminHeader.component"
import SideBar from "../SideBar/SideBar.component"

import "./AdminCRMLayout.style.sass"

interface PropTypes {
  title?: string;
  backRoute?: string;
  isStaticHeader?: boolean;
}

const block = "admin-layout"

class AdminCRMLayout extends React.Component<RouteComponentProps & PropTypes> {
  render(): JSX.Element {
    const { title, backRoute, isStaticHeader } = this.props

    return (
      <div className={block}>
        <SideBar {...this.props} />
        <div className={`${block}__body`}>
          <AdminHeader 
            title={title || ''} 
            {...this.props}
            backRoute={backRoute}
            isStatic={isStaticHeader} 
          />
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default withRouter(AdminCRMLayout)

import React from 'react'
import LoaderComponent from '../../../common/Loader/Loader.component'
import classNames from 'classnames'

import './LazyLoad.style.sass'

interface PropTypes {
  isLoading: boolean;
  onMount: () => void;
}

const block = 'lazy-loader'

export default class LazyLoadComponent extends React.Component<PropTypes> {
  componentDidMount(): void {
    this.props.onMount()
  }

  render(): JSX.Element {
    return (        
      <div className={classNames(
        block,
        this.props.isLoading && `${block}--loading`
      )}>
        {this.props.isLoading ? (
          <LoaderComponent width={50} height={50} />
        ) : (
          <div />
        )}
      </div> 
    )
  }
}
import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import PlatformExpertsComponent from '../../../../../components/CRM/AdminCRM/PlatformModeration/PlatformExperts'
import { addPlatformExpert } from '../../../../../services/CRM/AdminCRM/scenes/addExpert.service'
import { observer } from 'mobx-react'
import { addExpertStore } from '../../../../../stores/CRM/AdminCRM/scenes/addExpert.store'

@observer
class PlatformExpertsContainer extends React.Component<RouteComponentProps<{platformId: string}>> {
  state = {
    expertEmail: '',
    isInputFocused: false
  }

  onInputFocus = (): void => {
    this.setState({
      isInputFocused: true
    })
  }

  onInputBlur = (): void => {
    this.setState({
      isInputFocused: false
    })
  }

  setExpertEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      expertEmail: event.target.value
    })
  }

  addExpert = (): void => {
    const platformId = parseInt(this.props.match.params.platformId)

    addPlatformExpert(
      {email: this.state.expertEmail}, 
      platformId
    )
  }

  componentWillUnmount(): void {
    addExpertStore.clearErrors()
  }

  render(): JSX.Element {
    return (
      <PlatformExpertsComponent
        expertEmail={this.state.expertEmail}
        isInputFocused={this.state.isInputFocused}
        onInputChange={this.setExpertEmail}
        onInputBlur={this.onInputBlur}
        onInputFocus={this.onInputFocus}
        inputError={addExpertStore.requestErrors && addExpertStore.requestErrors[0].text}
        onExpertAdd={this.addExpert}
      />
    )
  }
}

export default withRouter(PlatformExpertsContainer)
import React from 'react'
import { Switch } from 'react-router-dom'
import { PrivateRoute } from '../../../../PrivateRoute/PrivateRoute'
import SceneInfoLayoutComponent from './SceneInfoLayout/SceneInfoLayout.component'
import { PATH_PLATFORM_SCENE_FORM, PATH_PLATFORM_SCENE_SHEDULE } from '../../../../../const/routes.const'
import SceneFormContainer from '../../../../../containers/CRM/AdminCRM/PlatformModeration/SceneForm/SceneForm.container'
import ScheduleComponent from '../Schedule/Schedule.component'

interface PropTypes {
  onBackClick: () => void;
  sceneName?: string;
  onTabClick: (route: string) => void;
}

type tabNames = 'experts' | 'shedule'

interface TabModel {
  name: tabNames;
  text: string;
  route: string;
}

export default class SceneInfoComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { onBackClick, sceneName, onTabClick } = this.props

    return (
      <Switch>
        <PrivateRoute exact path={PATH_PLATFORM_SCENE_FORM}>
          <SceneInfoLayoutComponent
            activeTabName="experts"
            onBackClick={onBackClick}
            onTabClick={onTabClick}
            sceneName={sceneName}
          >
            <SceneFormContainer />
          </SceneInfoLayoutComponent>
        </PrivateRoute>

        <PrivateRoute exact path={PATH_PLATFORM_SCENE_SHEDULE}>
          <SceneInfoLayoutComponent
            activeTabName="shedule"
            onBackClick={onBackClick}
            onTabClick={onTabClick}
            sceneName={sceneName}
          >
            <ScheduleComponent />
          </SceneInfoLayoutComponent>
        </PrivateRoute>
      </Switch>
    )
  }
}
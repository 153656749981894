import { ApiRequestStore } from '../../../common/apiRequest.store'
import { artistFormStatuses } from '../../../../models/ArtistFormModels/artistForm.model'
import { action, observable } from 'mobx'

class ArtistModerationStore extends ApiRequestStore {
  @observable formStatus?: artistFormStatuses;

  @action
  setFormStatus(status: artistFormStatuses): void {
    this.formStatus = status
  }
}

export const artistModerationStore: ArtistModerationStore = new ArtistModerationStore()

import { deserializeAs, autoserialize, autoserializeAs } from "cerialize"
import { artistFormStatuses } from "../ArtistFormModels/artistForm.model"

export type ArtistListCategory = 'new' | 'remoderation' | 'approved' | 'checked'

export class ListArtistAvatar {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserialize url: string;
  @autoserializeAs('url_s') urlSizeS?: string;
  @autoserializeAs('url_m') urlSizeM?: string;
  @autoserializeAs('url_l') urlSizeL?: string;
  
  constructor(id: number, name: string, url: string) {
    this.id = id
    this.name = name
    this.url = url
  }
}

export class ListArtistModel {
  @autoserialize id: number;
  @autoserializeAs('admin_status') adminStatus: artistFormStatuses;
  @autoserializeAs('updated_at') updatedAt: string;
  @autoserialize name: string;
  @autoserializeAs(ListArtistAvatar) avatar: ListArtistAvatar | null;

  constructor(
    id: number,
    adminStatus: artistFormStatuses,
    updatedAt: string,
    name: string,
    avatar: ListArtistAvatar
  ) {
    this.id = id
    this.adminStatus = adminStatus
    this.updatedAt = updatedAt
    this.name = name
    this.avatar = avatar
  }
}

export class AdminArtistFormsGetRequest {
  @autoserialize type: ArtistListCategory;
  @autoserialize limit?: number;
  @autoserialize offset?: number;
  @autoserialize search?: string;

  constructor(
    type: ArtistListCategory,
    limit?: number,
    offset?: number,
    search?: string
  ) {
    this.type = type
    this.limit = limit
    this.offset = offset
    this.search = search
  }
}

export class AdminArtistFormsGetResponse {
  @autoserializeAs(ListArtistModel, 'artist_forms') artistForms?: ListArtistModel[];
  @autoserialize count?: number;
}

import { action, observable } from 'mobx'
import { PlatformStatus, AdminPlatformListCategories } from '../../../../models/ScenesModels/platformList.model'

class PlatformModerationStore {
  @observable platformStatus?: PlatformStatus;
  @observable platformCategory?: AdminPlatformListCategories;

  @action
  setPlatformStatus(status: PlatformStatus): void {
    this.platformStatus = status
  }

  @action
  setPlatformCategory(type: AdminPlatformListCategories): void {
    this.platformCategory = type
  }
}

export const platformModerationStore: PlatformModerationStore = new PlatformModerationStore()

import { ApiRequestStore } from "../../../common/apiRequest.store"
import { action, observable } from "mobx"
import { 
  ListSelectionExpertModel, 
  SelectionExpertListGetResponse 
} from "../../../../models/SelectionExpertsModels/selectionExpert.model"

class SelectionExpertListStore extends ApiRequestStore {
  @observable selectionExperts?: ListSelectionExpertModel[]

  @action
  setExpertsData(expertsData: SelectionExpertListGetResponse): void {
    this.selectionExperts = expertsData.experts
  }

  @action
  clearData = (): void => {
    this.selectionExperts = undefined
  }

  getFilteredExperts = (value: string): ListSelectionExpertModel[] | [] => {
    if (this.selectionExperts) {
      return this.selectionExperts.filter(expert => (
        expert.email.toLowerCase().indexOf(value) >= 0 || 
          (expert.name && expert.name.toLowerCase().indexOf(value) >= 0)
      ))
    } else {
      return []
    }
  }
}

export const selectionExpertListStore: SelectionExpertListStore = new SelectionExpertListStore()

import React from "react"

import "./CommentInput.style.sass"

interface PropTypes {
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
}

const block = "comment-input"

export default class CommentInputComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    return (
      <div className={block}>
        <textarea
          onChange={this.props.onChange}
          className={`${block}__input`}
          placeholder="Введите комментарий"
          value={this.props.value}
        />
      </div>
    )
  }
}

import React from 'react'
import InputComponent from '../../common/Input/Input.component'
import Button from '../../../Button/Button.component'
import classNames from 'classnames'

import './AddExpert.style.sass'
import ModalComponent from '../../../common/Modal/Modal.component'

interface PropTypes {
  expertEmail: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputError?: string;
  onExpertAdd: () => void;
  isSuccessModalVisible: boolean;
  onSuccessModalClose: () => void;
}

const block = 'add-expert-page'

export default class AddExpertComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { 
      expertEmail, 
      onInputChange,
      inputError,
      onExpertAdd,
      isSuccessModalVisible,
      onSuccessModalClose
    } = this.props

    return (
      <div className={`${block} form admin-layout-content`}>
        <div className={classNames(
          "admin-layout-content__body",
          "admin-layout-content__body--padding-top-0",
          `${block}__body`
        )}>
          <div className="form__form-block">
            <div className="form__input-block">
              <div className="form__input-title">
                Email эксперта, зарегистрированного на сайте UMN
              </div>
              <div className="form__input margin margin--top-xxs">
                <InputComponent
                  placeholder="Введите текст"
                  value={expertEmail}
                  name="new-platform-expert-email"
                  onChange={onInputChange}
                  error={inputError}
                />
              </div>
            </div>
          </div>

          <div className="margin--top-m">
            <Button 
              onClick={onExpertAdd}
              background="purple"
              text="Добавить эксперта"
              isDisabled={!expertEmail.length}
            />
          </div>
        </div>

        <ModalComponent
          isVisible={isSuccessModalVisible}
          title="Эксперт был добавлен успешно"
          onModalClose={onSuccessModalClose}
        >
          <div className="margin--auto margin--top-s margin--bottom-0">
            <Button
              text="OK"
              background="purple"
              onClick={onSuccessModalClose}
            />
          </div>
        </ModalComponent>
      </div>
    )
  }
}
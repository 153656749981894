import React from "react"
import CRMListItemComponent from "../../../../../components/CRM/AdminCRM/CrmListItem/CrmListItem.component"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { PATH_PLATFORM_MODERATE } from "../../../../../const/routes.const"
import { 
  ListPlatformModel, 
  PlatformStatus, 
  AdminPlatformListCategories 
} from "../../../../../models/ScenesModels/platformList.model"
import { platformModerationStore } from "../../../../../stores/CRM/AdminCRM/scenes/platformModeration.store"

interface PropTypes {
  platformData: ListPlatformModel;
  platformCategory: AdminPlatformListCategories;
}

const statuses: {
  [key in PlatformStatus]: {
    title: string;
    color: "red" | "grey" | "yellow" | "green" | "blue";
  };
} = {
  EMPTY: {
    title: "Не заполнена",
    color: "red"
  },
  EDITING: {
    title: "Редактируется экспертом",
    color: "grey"
  },
  MODERATION: {
    title: "Требуется модерация",
    color: "yellow"
  },
  APPROVED: {
    title: "Заявка утверждена",
    color: "green"
  },
  CHECKED: {
    title: "Вычитано",
    color: "blue"
  }
}

class PlatformItemContainer extends React.Component<PropTypes & RouteComponentProps> {
  onClick = (): void => {
    const { platformData, platformCategory } = this.props
    
    platformModerationStore.setPlatformStatus(platformData.platformStatus)
    platformModerationStore.setPlatformCategory(platformCategory)
    this.props.history.push(
      `${PATH_PLATFORM_MODERATE}/${platformData.id}`
    )
  }

  render(): JSX.Element {
    const { platformData } = this.props

    return (
      <CRMListItemComponent 
        title={platformData.name}
        image={platformData.avatar}
        statusColor={statuses[platformData.platformStatus].color}
        statusText={statuses[platformData.platformStatus].title}
        onClick={this.onClick} 
      />
    )
  }
}

export default withRouter(PlatformItemContainer)
import { serialize, deserialize, deserializeAs, autoserialize } from 'cerialize'
import { UserModel } from '../common/user.model'

export class LoginRequest {
  @serialize email: string;
  @serialize password: string;

  constructor(email: string, password: string) {
    this.email = email
    this.password = password
  }
}

export class LoginResponse {
  @deserialize token: string;
  @deserializeAs(UserModel) user: UserModel;

  constructor(token: string, user: UserModel) {
    this.token = token
    this.user = user
  }
}

export class PassRecoveryRequest {
  @autoserialize email: string;
  
  constructor(
    email: string
  ) {
    this.email = email
  }
}
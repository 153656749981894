import { api } from "../../../../utils/api"
import { Deserialize, Serialize } from "cerialize"
import { RequestErrorResponse } from "../../../../models/common/error.model"
import { 
  ExpertProfileGetResponse, 
  ExpertProfileSaveRequest 
} from "../../../../models/ExpertsModels/expertProfile.model"
import { expertProfileStore, expertSaveStore } from "../../../../stores/CRM/AdminCRM/experts/expertProfile.store"

export function getExpertProfile(expertId?: number): Promise<void> {
  const userRole = localStorage.getItem('userRole')
  const url = userRole === 'EXPERT' ? '/crm/expert/' : `/crm/admin/experts/${expertId}/`
  expertProfileStore.setIsLoading(true)
  
  return api.get(url).then((res) => {
    const expertData: ExpertProfileGetResponse = Deserialize(
      res.data,
      ExpertProfileGetResponse
    )
    
    expertProfileStore.setExpertData(expertData)
    expertProfileStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    expertProfileStore.setIsLoading(false)
    expertProfileStore.setErrors(errorResponseData.errors)
    throw error
  })
}

export function saveExpertProfile(request: ExpertProfileSaveRequest, expertId?: number): Promise<void> {
  const userRole = localStorage.getItem('userRole')
  const url = userRole === 'EXPERT' ? '/crm/expert/' : `/crm/admin/experts/${expertId}/`
  const prepared = {
    photo: request.photo?.id
  }
  const serializedData = Serialize({...request, ...prepared}, ExpertProfileSaveRequest) 

  expertSaveStore.setIsLoading(true)
  
  return api.put(
    url,
    serializedData
  ).then(() => {
    expertSaveStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    expertSaveStore.setIsLoading(false)
    expertSaveStore.setErrors(errorResponseData.errors)
    throw error
  })
}

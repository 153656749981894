import { deserialize, deserializeAs } from 'cerialize'
import { artistFormStatuses } from '../ArtistFormModels/artistForm.model'

export type notificationsType = 'SENT_TO_MODERATION' | 'NEED_EDITING' | 'APPROVED' | 'SENT_TO_REMODERATION' | 'CHECKED'

export class FormNotification {
  @deserialize type: notificationsType;
  @deserialize text: string;
  @deserializeAs('created_at') createdAt: string;

  constructor(type: notificationsType, text: string, createdAt: string) {
    this.type = type
    this.text = text
    this.createdAt = createdAt
  }
}

export class ArtistRating {
  @deserialize music: number;
  @deserialize emotional: number;
  @deserializeAs('concert_activities') concertActivities: number;
  @deserialize total: number;


  constructor(music: number, emotional: number, concertActivities: number, total: number) {
    this.music = music
    this.emotional = emotional 
    this.concertActivities = concertActivities
    this.total = total
  }
}

export class ArtistFormNotificationsResponse {
  @deserialize id: number;
  @deserialize status: artistFormStatuses;
  @deserializeAs('sent_to_moderation_at') sentToModerationAt: string;
  @deserializeAs('moderated_at') moderatedAt: string;
  @deserializeAs('approved_at') approvedAt: string;
  @deserializeAs('created_at') createdAt: string;
  @deserializeAs('updated_at') updatedAt: string;
  @deserializeAs(FormNotification) notifications: FormNotification[];
  @deserializeAs(ArtistRating) ratings: ArtistRating;

  constructor(
    id: number,
    status: artistFormStatuses,
    sentToModerationAt: string,
    moderatedAt: string,
    approvedAt: string,
    createdAt: string,
    updatedAt: string,
    notifications: FormNotification[],
    ratings: ArtistRating
  ) {
    this.id = id
    this.status = status
    this.sentToModerationAt = sentToModerationAt
    this.moderatedAt = moderatedAt
    this.approvedAt = approvedAt
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.notifications = notifications
    this.ratings = ratings
  }
}

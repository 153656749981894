import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import AddExpertComponent from '../../../../components/CRM/AdminCRM/AddExpert/AddExpert.component'
import { addCrmExpert } from '../../../../services/CRM/AdminCRM/experts/expertAdd.service'
import { crmExpertAddStore } from '../../../../stores/CRM/AdminCRM/experts/expertAdd.store'
import { observer } from 'mobx-react'

@observer
class AddExpertContainer extends React.Component<RouteComponentProps> {
  state = {
    expertEmail: '',
    isSuccessModalVisible: false
  }

  setExpertEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      expertEmail: event.target.value
    })
  }

  addExpert = (): void => {
    addCrmExpert({email: this.state.expertEmail}).then(() => {
      this.setState({
        expertEmail: '',
        isSuccessModalVisible: true
      })
    })
  }

  closeModal = (): void => {
    this.setState({
      isSuccessModalVisible: false
    })
  }

  componentWillUnmount(): void {
    crmExpertAddStore.clearErrors()
  }

  render(): JSX.Element {
    return (
      <AddExpertComponent
        expertEmail={this.state.expertEmail}
        onInputChange={this.setExpertEmail}
        inputError={crmExpertAddStore.requestErrors && crmExpertAddStore.requestErrors[0].text}
        onExpertAdd={this.addExpert}
        isSuccessModalVisible={this.state.isSuccessModalVisible}
        onSuccessModalClose={this.closeModal}
      />
    )
  }
}

export default withRouter(AddExpertContainer)
import { api } from "../../../../utils/api"
import { Deserialize } from "cerialize"
import { RequestErrorResponse } from "../../../../models/common/error.model"
import { scenesListStore } from "../../../../stores/CRM/AdminCRM/scenes/scenesList.store"
import { ScenesListGetResponse } from "../../../../models/ScenesModels/scenesList.model"

export function getScenesList(platformId): Promise<void> {
  const userRole = localStorage.getItem('userRole')
  const url = userRole === 'ADMIN' ? (
    `/crm/admin/area_forms/${platformId}/place_forms/`
  ) : (
    `/crm/expert/area_forms/${platformId}/place_forms/`
  )

  scenesListStore.setIsLoading(true)
  
  return api
    .get(url)
    .then((res) => {
      const scenesData: ScenesListGetResponse = Deserialize(
        res.data,
        ScenesListGetResponse
      )
      
      scenesListStore.setScenesListData(scenesData)
      scenesListStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response.data
      scenesListStore.setIsLoading(false)
      scenesListStore.setErrors(errorResponseData.errors)
    })
}

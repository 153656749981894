import React from 'react'

import './CircleButton.style.sass'

interface PropTypes {
  onClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
  children: React.ReactNode;
}

const block = 'circle-button'

export default class CircleButtonComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { children, onClick } = this.props

    return (
      <div className={block} onClick={onClick}>
        {children}
      </div>
    )
  }
}

import React from 'react'
import classNames from 'classnames'

import './TabMenu.style.sass'

interface TabModel {
  name: string;
  text: string;
  isActive: boolean;
  onClick: () => void;
}

interface PropTypes {
  tabs: (TabModel | undefined)[];
  style: 'light' | 'dark';
}

const block = 'tab-menu'

export default class TabMenu extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { tabs, style } = this.props

    return (
      <div 
        className={classNames(
          block,
          style === 'dark' && `${block}--dark`
        )}
      >
        {tabs.map(tab => (
          tab && (
            <div 
              onClick={tab.onClick}
              className={classNames(
                `${block}__tab`,
                tab.isActive && `${block}__tab--active`
              )}
              key={tab.name}
            >
              {tab.text}
            </div>
          )
        ))}
      </div>
    )
  }
}
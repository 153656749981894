import React from 'react'
import LoginFormComponent from '../../components/LoginForm/LoginForm.component'
import { LoginRequest, PassRecoveryRequest } from '../../models/AuthModels/login.model'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { logIn, recoverUserPassword } from '../../services/MainPage/auth.service'
import { loginStore, recoverPasswordStore } from '../../stores/MainPage/auth.store'
import { observer } from 'mobx-react'
import PassRecoveryFormComponent from '../../components/PassRecoveryForm/PassRecoveryForm.component'
import PassRecoverySuccessComponent 
from '../../components/PassRecoveryForm/PassRecoverySuccess/PassRecoverySuccess.component'

interface State {
  visibleForm: VisibleFormType;
}

type VisibleFormType = 'loginForm' | 'passwordRecovery' | 'passRecoveryEmailSent'

@observer
class LoginFormContainer extends React.Component<RouteComponentProps> {
  onSubmit = (values: LoginRequest): void => {
    logIn(values).then(() => this.props.history.push('/crm'))
  }

  state: State = {
    visibleForm: 'loginForm'
  }

  changeForm = (state: VisibleFormType): void => {
    this.setState({
      visibleForm: state
    })
  }

  recoverPassword = (data: PassRecoveryRequest): void => {
    recoverUserPassword(data).finally(() => {
      this.changeForm('passRecoveryEmailSent')
      recoverPasswordStore.clearErrors()
    })
  }

  renderForm = (): JSX.Element => {
    switch (this.state.visibleForm) {
      case 'loginForm':
        return (
          <LoginFormComponent
            onForgotPassClick={(): void => this.changeForm('passwordRecovery')}
            onSubmit={this.onSubmit}
            isLoading={loginStore.isLoading}
            submittionErrors={loginStore.requestErrors}
          />
        )
      case 'passwordRecovery':
        return (
          <PassRecoveryFormComponent
            onSubmit={(request): void => this.recoverPassword(request)}
            error={recoverPasswordStore.requestErrors && recoverPasswordStore.requestErrors[0].text}
            isLoading={recoverPasswordStore.isLoading}
            onLoginClick={(): void => this.changeForm('loginForm')}
          />
        )
      case 'passRecoveryEmailSent':
        return (
          <PassRecoverySuccessComponent onLoginClick={(): void => this.changeForm('loginForm')} />
        )
      default:
        return (
          <LoginFormComponent
            onForgotPassClick={(): void => this.changeForm('passwordRecovery')}
            onSubmit={this.onSubmit}
            isLoading={loginStore.isLoading}
            submittionErrors={loginStore.requestErrors}
          />
        )
    }
  }
  render(): JSX.Element {
    return(
      this.renderForm()
    )
  }
}

export default withRouter(LoginFormContainer)

import React from 'react'
import { ReactComponent as Remove } from '../../../../../assets/icons/remove.svg'

import './DeleteMemberButton.style.sass'

interface PropTypes {
  onClick: (event: React.SyntheticEvent<HTMLElement>) => void;
}

const block = 'delete-member-button'

export default class DeleteMemberButtonComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    return (
      <div className={block} onClick={this.props.onClick}>
        <div className={`${block}__text`}>
          Удалить участника
        </div>
        <div className={`${block}__icon`}>
          <Remove />
        </div>
      </div>
    )
  }
}
import { api } from "../../../../utils/api"
import { Deserialize, Serialize } from "cerialize"
import { 
  ArtistsIntersectionsGetRequest, ArtistsIntersectionsGetResponse
} from "../../../../models/ArtistsIntersectionsModels/ArtistsIntersections.model"
import { RequestErrorResponse } from "../../../../models/common/error.model"
import { artistsIntersectionsStore } from "../../../../stores/CRM/AdminCRM/artists/artistsIntersections.strore"

export function getArtistsIntersections(request: ArtistsIntersectionsGetRequest): Promise<void> {
  const serializedData = Serialize(request, ArtistsIntersectionsGetRequest) 

  artistsIntersectionsStore.setIsLoading(true)

  return api.get(
    "/crm/admin/musician_intersections/",
    {
      params: serializedData
    }
  ).then((res) => {
    const data: ArtistsIntersectionsGetResponse = Deserialize(
      res.data,
      ArtistsIntersectionsGetResponse
    )

    artistsIntersectionsStore.setArtistFormsData({
      ...data
    })
    artistsIntersectionsStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    artistsIntersectionsStore.setIsLoading(false)
    artistsIntersectionsStore.setErrors(errorResponseData.errors)
  })
}
import React from 'react'
import SearchInputContainer from '../../../../containers/SearchInput/SearchInput.container'
import FilesGroupComponent from './FilesGroup/FilesGroup.component'
import { FileGroupsPlace } from '../../../../models/FilesModels/TechRiders.model'
import LoaderComponent from '../../../common/Loader/Loader.component'
import classNames from 'classnames'
import { FileUploadResponse } from '../../../../models/common/fileUpload.model'
import LazyLoad from 'react-lazyload'
import LazyLoadComponent from '../../common/LazyLoad/LazyLoad.component'

const block = 'files-group-list'

interface PropTypes {
  items?: FileGroupsPlace[];
  isLoading: boolean;
  onDownloadAllFiles: (files: FileUploadResponse[]) => void;
  shouldLoadMore: boolean;
  onLoadMore: () => void;
  onSearchChange: (value: string) => void;
}

export default class FilesGroupListComponent extends React.Component<PropTypes> {


  render(): JSX.Element {
    const { items, isLoading, onDownloadAllFiles, shouldLoadMore, onLoadMore, onSearchChange } = this.props

    return (
      <div className={`${block} admin-layout-content`}>
        <div className="admin-layout-content__header">
          <div className="admin-layout-content__controls">
            <SearchInputContainer onSearchChange={onSearchChange} />
          </div>
        </div>
        {isLoading && !items?.length && (
          <div className="admin-layout-content__loading">
            <LoaderComponent width={100} height={100} />
          </div>
        )}

        <div className="admin-layout-content__body admin-layout-content__body--padding-top-s">
          {items && items.length ? (
            <>
            {items.map((item, index) => (
              <div key={item.id} className={classNames(index !== 0 && 'margin--top-xs')}>
                <FilesGroupComponent
                  filesGroup={item}
                  onDownloadClick={onDownloadAllFiles}
                />
              </div>
            ))}
            {shouldLoadMore && (
              <div className="margin--top-xs">
                <LazyLoad height={10} unmountIfInvisible={true}>
                  <LazyLoadComponent isLoading={isLoading} onMount={onLoadMore} />
                </LazyLoad>
              </div>
            )}
            </>
          ) : !isLoading && (
            <div className="admin-layout-content__empty">
              Список пуст
            </div>
          )}
        </div>
      </div>
    )
  }
}
import { api } from "../../utils/api"
import { fileUploadStore } from "../../stores/common/fileUpload.store"
import { RequestErrorResponse } from "../../models/common/error.model"
import { FileUploadResponse } from "../../models/common/fileUpload.model"
import { Deserialize } from "cerialize"

export function uploadFile(file: File[], stateName: string, fileName?: string): Promise<FileUploadResponse> {
  const formData = new FormData()
  formData.append('file', file[0], file[0].name)
  fileName && formData.append('file_name', fileName)
  fileUploadStore.setIsLoading(true, stateName)

  return api.post(
    '/crm/upload_file/',
    formData,
    {headers: { 'content-type': 'multipart/form-data' }}
  ).then((res) => {
    const responseData: FileUploadResponse = Deserialize(res.data, FileUploadResponse)

    fileUploadStore.setUploadedFileData(responseData, stateName)

    return responseData
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    fileUploadStore.setIsLoading(false, stateName)
    fileUploadStore.setSubmittionErrors(errorResponseData.errors, stateName)
    throw error
  })
}

import { autoserialize, autoserializeAs } from 'cerialize'
import { FileUploadResponse } from '../common/fileUpload.model'

export class FileGroupsPlace {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserializeAs(FileUploadResponse) files: FileUploadResponse[];

  constructor(id: number, name: string, files: FileUploadResponse[]) {
    this.id = id
    this.name = name
    this.files = files
  }
}

export class FileGroupsGetRequest {
  @autoserialize search?: string;
  @autoserialize limit?: number;
  @autoserialize offset?: number;
}

export class FileGroupsGetResponse {
  @autoserializeAs(FileGroupsPlace) places: FileGroupsPlace[]
  @autoserialize count: number

  constructor(places: FileGroupsPlace[], count: number) {
    this.places = places
    this.count = count
  }
}
import { autoserialize, autoserializeAs } from "cerialize"
import { FileUploadResponse } from "../common/fileUpload.model"

export class SelectionExpertDashboardArtist {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserializeAs(FileUploadResponse) avatar: FileUploadResponse;
  @autoserializeAs('rating_sum') ratingSum: number;
  @autoserializeAs('evaluated_at') evaluatedAt: string;

  constructor(
    id: number,
    name: string,
    avatar: FileUploadResponse,
    ratingSum: number,
    evaluatedAt: string
  ) {
    this.id = id
    this.name = name
    this.avatar = avatar
    this.ratingSum = ratingSum
    this.evaluatedAt = evaluatedAt
  }
}

export class SelectionExpertDashboardGetResponse {
  @autoserializeAs('already_evaluated') alreadyEvaluated: number;
  @autoserializeAs('remains_evaluated') remainsEvaluated: number;
  @autoserializeAs(SelectionExpertDashboardArtist) items: SelectionExpertDashboardArtist[];

  constructor(
    alreadyEvaluated: number,
    remainsEvaluated: number,
    items: SelectionExpertDashboardArtist[]
  ) {
    this.alreadyEvaluated = alreadyEvaluated
    this.remainsEvaluated = remainsEvaluated
    this.items = items
  }
}
import React from 'react'
import PlatformModerationLayoutComponent from './PlatformModerationLayout'
import { Switch, Redirect } from 'react-router-dom'
import {
  PATH_PLATFORM_SCENES, 
  PATH_PLATFORM_EXPERTS, 
  PATH_PLATFORM_MODERATE, 
  PATH_CRM_SCENES, 
  PATH_PLATFORM_SCENE_FORM 
} from '../../../../const/routes.const'
import { PrivateRoute } from '../../../PrivateRoute/PrivateRoute'
import PlatformFormContainer from '../../../../containers/CRM/AdminCRM/PlatformModeration/PlatformForm'
import PlatformExpertsContainer from '../../../../containers/CRM/AdminCRM/PlatformModeration/PlatformExperts'
import ScenesListContainer from '../../../../containers/CRM/AdminCRM/PlatformModeration/ScenesList'
import SceneInfoContainer from '../../../../containers/CRM/AdminCRM/PlatformModeration/SceneInfo/SceneInfo.container'

export default class PlatformModerationComponent extends React.Component {
  render(): JSX.Element {
    const userRole = localStorage.getItem('userRole')

    return (
      <Switch>
        <PrivateRoute exact path={`${PATH_PLATFORM_MODERATE}/:platformId`}>
          <PlatformModerationLayoutComponent 
            activeTabName="platform"
          >
            <PlatformFormContainer />
          </PlatformModerationLayoutComponent>
        </PrivateRoute>

        <PrivateRoute exact path={PATH_PLATFORM_SCENES}>
          <PlatformModerationLayoutComponent 
            activeTabName="scenes"
          >
            <ScenesListContainer />
          </PlatformModerationLayoutComponent>
        </PrivateRoute>

        <PrivateRoute path={PATH_PLATFORM_SCENE_FORM}>
          <PlatformModerationLayoutComponent 
            activeTabName="scenes"
          >
            <SceneInfoContainer />
          </PlatformModerationLayoutComponent>
        </PrivateRoute>

        {userRole === 'ADMIN' && (
          <PrivateRoute exact path={PATH_PLATFORM_EXPERTS}>
            <PlatformModerationLayoutComponent 
              activeTabName="experts"
            >
              <PlatformExpertsContainer />
            </PlatformModerationLayoutComponent>
          </PrivateRoute>
        )}

        <Redirect
          to={PATH_CRM_SCENES}
        />
      </Switch>
    )
  }
}
import { observable, action } from 'mobx'
import { UserModel } from '../../models/common/user.model'
import { getAuthToken, getUserEmail, getUserID, getUserRole } from '../../utils/localStorage'

export class UserStore {
  @observable userData?: UserModel

  constructor(userData?: UserModel) {
    this.userData = userData
  }

  @action
  setUserData(userData: UserModel): void {
    this.userData = userData
  }

  @action
  clearUserData(): void {
    this.userData = undefined
  }
}

const hasUserData = getUserEmail() && getUserRole() && getUserID() && getAuthToken()

const userStoreDefaultData: UserModel | undefined = hasUserData ? {
  email: getUserEmail() as string,
  crmRole: getUserRole() as UserModel['crmRole'],
  profileId: getUserID() as string
} : undefined

export const userStore: UserStore = new UserStore(userStoreDefaultData)

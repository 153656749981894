import { autoserialize, autoserializeAs } from "cerialize"
import { FileUploadResponse } from "../common/fileUpload.model"

export class PlatformExpertModel {
  @autoserialize id: number;
  @autoserialize email: string;
  @autoserializeAs(FileUploadResponse) photo: FileUploadResponse | null;

  constructor(
    id: number,
    email: string,
    photo: FileUploadResponse
  ) {
    this.id = id
    this.email = email
    this.photo = photo
  }
}

export class PlatformExpertsResponse {
  @autoserializeAs(PlatformExpertModel) experts: PlatformExpertModel[] | []

  constructor(experts: PlatformExpertModel[]) {
    this.experts = experts
  }
}
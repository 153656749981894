import React from 'react'
import Dropzone, { DropEvent } from 'react-dropzone'
import AddMemberButtonComponent from '../../ArtistCRM/ArtistForm/AddMemberButton/AddMemberButton.component'
import { FileUploadResponse } from '../../../../models/common/fileUpload.model'

import './DocumentUpload.style.sass'
import ErrorLabelComponent from '../ErrorLabel/ErrorLabel.component'

interface PropTypes {
  onDrop: <T extends File>(acceptedFiles: T[], rejectedFiles: T[], event: DropEvent) => void;
  fileTypes: string[];
  uploadedFile?: FileUploadResponse;
  error: string;
  disabled?: boolean;
}

const block = 'upload-document'

export default class DocumentUploadComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { fileTypes, error, disabled } = this.props

    return (
      <div className={`${block}`}>
        {error && (
          <ErrorLabelComponent text={error} />
        )}
        
        <Dropzone onDrop={this.props.onDrop} accept={fileTypes} disabled={disabled}>
          {({getRootProps, getInputProps}): JSX.Element => (
            <div className={`${block}__dropzone`} {...getRootProps()}>
              <AddMemberButtonComponent text={'Добавить документ'} />
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
      </div>
    )
  }
}
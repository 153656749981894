import { ApiRequestStore } from "../../../common/apiRequest.store"
import { observable, action } from "mobx"
import { 
  PerformanceArtistModel, 
  PerformanceArtistGetResponse 
} from "../../../../models/ScenesModels/performances.model"

class PerformanceArtistsStore extends ApiRequestStore {
  @observable artists?: PerformanceArtistModel[];
  @observable count?: number;
  @observable currentOffset?: number;

  @action
  setArtists = (artistsData: PerformanceArtistGetResponse): void => {
    if (this.artists && artistsData.artists) {
      this.artists = [...this.artists, ...artistsData.artists]
    } else {
      this.artists = artistsData.artists
    }

    this.count = artistsData.count
  }

  @action
  clearData = (): void => {
    this.artists = undefined
    this.count = undefined
    this.currentOffset = undefined
  }

  @action
  setOffset = (newOffset: number): void => {
    this.currentOffset = newOffset
  }

  @action
  deleteArtistFromList = (id: number): void => {
    this.artists = this.artists?.filter(artist => artist.id !== id)
    this.count = this.count ? this.count - 1 : undefined
  }
}

export const performanceArtistsStore: PerformanceArtistsStore = new PerformanceArtistsStore()
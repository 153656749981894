import { ApiRequestStore } from '../common/apiRequest.store'

class RegistrationStore extends ApiRequestStore {}

class LoginStore extends ApiRequestStore {}

class RecoverPasswordStore extends ApiRequestStore {}

export const loginStore: LoginStore = new LoginStore()
export const registrationStore: RegistrationStore = new RegistrationStore()
export const recoverPasswordStore: RecoverPasswordStore = new RecoverPasswordStore()

import React from 'react'
import { ReactComponent as Check } from '../../../../assets/icons/check.svg'
import { checkboxStore } from '../../../../stores/common/checkboxStore'

import './Checkbox.style.sass'
import { observer } from 'mobx-react'

interface PropTypes {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  defaultIsChecked?: boolean;
}
const block = "checkbox-component"

@observer
export default class CheckboxComponent extends React.Component<PropTypes> {
  constructor(props: PropTypes) {
    super(props)
    checkboxStore.registration(this.props.name, this.props.defaultIsChecked || false)
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    checkboxStore.setChecked(this.props.name, event.target.checked)

    this.props.onChange && this.props.onChange(event)
  }

  componentWillUnmount(): void {
    checkboxStore.unmount(this.props.name)
  }

  render(): JSX.Element {
    const { name, children } = this.props

    return (
      <label className={block}>
        <input
          type="checkbox"
          onChange={this.onChange}
          name={name}
          checked={checkboxStore.getItem(this.props.name).checked}
        />
        <div className={`${block}__check`}>
          <Check />
        </div>
        <div className={`${block}__content`}>
          {children}
        </div>
      </label>
    )
  }
}

import { api } from '../../../../utils/api'
import { adminExportsStore } from "../../../../stores/CRM/AdminCRM/exports/exports.store"
import { RequestErrorResponse } from '../../../../models/common/error.model'

export function getExports(): Promise<void> {
  adminExportsStore.setIsLoading(true)

  return api
    .get('/crm/admin/exports/')
    .then((res) => {
      adminExportsStore.setExportsData(res.data)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse =  error.response.data
      adminExportsStore.setErrors(errorResponseData.errors)
      throw error
    })
    .finally(() => {
      adminExportsStore.setIsLoading(false)
    })
}

export function getExportByName(name: string): Promise<void> {

  return api.get(`/crm/admin/exports/${name}/`)
  .then((res) => {
    adminExportsStore.setExportByNameData(res.data)
  })
}

import React from "react"
import PlatformFormComponent from "../../../../../components/CRM/AdminCRM/PlatformModeration/PlatformForm"
import { PlatformSaveRequest } from "../../../../../models/ScenesModels/platform.model"
import {
  platformFormGetStore,
  platformFormSaveStore,
  platformFormApplyStore,
  platformFormReadStore,
  platformFormApproveStore,
  platformFormModerateStore,
  platformFormDeleteStore
} from "../../../../../stores/CRM/AdminCRM/scenes/platformGetStore"
import {
  getPlatformForm,
  savePlatformForm,
  applyForm,
  approvePlatform,
  checkPlatform,
  moderatePlatform,
  deletePlatform
} from "../../../../../services/CRM/AdminCRM/scenes/platformForm.service"
import { observer } from "mobx-react"
import { formCommentStore } from "../../../../../stores/CRM/AdminCRM/artists/formComment.store"
import { platformModerationStore } from "../../../../../stores/CRM/AdminCRM/scenes/platformModeration.store"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { LoadScript } from "@react-google-maps/api"
import LoaderComponent from "../../../../../components/common/Loader/Loader.component"
import { AdminPlatformListCategories } from "../../../../../models/ScenesModels/platformList.model"
import {
  PATH_CRM_SCENES_APPROVED,
  PATH_CRM_SCENES_CHECKED,
  PATH_CRM_SCENES_NEW,
  PATH_CRM_SCENES_REMODERATION,
  PATH_PLATFORM_MODERATE
} from "../../../../../const/routes.const"
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url"

interface State {
  showValidationErrors: boolean;
  isAddressValid: boolean;
  isSuccessModalVisible: boolean;
  isCheckModalVisible: boolean;
  isGoogleMapLoaded: boolean;
  isDeleteModalVisible: boolean;
}

const libraries: Libraries = ["places"]

@observer
class PlatformFormContainer extends React.Component<
  RouteComponentProps<{ platformId: string }>,
  State
> {
  state: State = {
    showValidationErrors: false,
    isAddressValid: true,
    isCheckModalVisible: false,
    isSuccessModalVisible: false,
    isGoogleMapLoaded: false,
    isDeleteModalVisible: false
  };

  componentDidMount(): void {
    const platformId = this.props.match.params.platformId

    getPlatformForm(parseInt(platformId))
  }

  getPlatformListRoute = (category: AdminPlatformListCategories): string => {
    switch (category) {
      case "approved":
        return PATH_CRM_SCENES_APPROVED
      case "checked":
        return PATH_CRM_SCENES_CHECKED
      case "newCategory":
        return PATH_CRM_SCENES_NEW
      case "remoderation":
        return PATH_CRM_SCENES_REMODERATION
    }
  };

  redirectToList = (): void => {
    if (platformModerationStore.platformCategory) {
      this.props.history.push(
        this.getPlatformListRoute(platformModerationStore.platformCategory)
      )
    } else {
      this.props.history.push(PATH_CRM_SCENES_NEW)
    }
  };

  onSubmit = (values: PlatformSaveRequest, isApply?: boolean): void => {
    const platformId = this.props.match.params.platformId
    const userRole = localStorage.getItem("userRole")
    savePlatformForm(values, parseInt(platformId)).then(() => {
      isApply &&
        (userRole === "EXPERT"
          ? applyForm(parseInt(platformId)).then(() => {
              this.redirectToList()
            })
          : approvePlatform(parseInt(platformId)).then(() => {
              this.redirectToList()
            }))
    })
  };

  moderate = (values: PlatformSaveRequest): void => {
    const platformId = parseInt(this.props.match.params.platformId)

    savePlatformForm(values, platformId).then(() => {
      moderatePlatform(formCommentStore.comment, platformId).then(() => {
        this.redirectToList()
      })
    })
  };

  onApprove = (values: PlatformSaveRequest): void => {
    const platformId = parseInt(this.props.match.params.platformId)

    savePlatformForm(values, platformId).then(() => {
      approvePlatform(platformId).then(() => {
        this.redirectToList()
      })
    })
  };

  onCheckForm = (values: PlatformSaveRequest): void => {
    const platformId = parseInt(this.props.match.params.platformId)

    savePlatformForm(values, platformId).then(() => {
      checkPlatform(platformId)
        .then(() => {
          this.setState({
            isCheckModalVisible: false,
            isSuccessModalVisible: true
          })
        })
        .catch(() => this.setState({ isCheckModalVisible: false }))
    })
  };

  componentWillUnmount(): void {
    platformFormGetStore.clearData()
    platformFormSaveStore.clearData()
    platformFormApplyStore.clearErrors()
    platformFormReadStore.clearErrors()
    platformFormApproveStore.clearErrors()
    platformFormModerateStore.clearErrors()
    platformFormDeleteStore.clearErrors()
  }

  showErrors = (): void => {
    this.setState({
      showValidationErrors: true
    })
  };

  toggleCheckPopup = (isVisible: boolean): void => {
    this.setState({
      isCheckModalVisible: isVisible
    })
  };

  toggleSuccessPopup = (isVisible: boolean): void => {
    this.setState({
      isSuccessModalVisible: isVisible
    })
  };

  toggleDeleteModal = (): void => {
    this.setState((prevState: State) => ({
      isDeleteModalVisible: !prevState.isDeleteModalVisible
    }))
  }
  
  deletePlatform = (): void => {
    const platformId = parseInt(this.props.match.params.platformId)

    deletePlatform(platformId)
    .then(() => {
      this.toggleDeleteModal()
      this.props.history.push(`${PATH_PLATFORM_MODERATE}`)
    })
  }

  render(): JSX.Element {
    return (
      <LoadScript
        id="google-maps-script-loader"
        googleMapsApiKey="AIzaSyBCK3AyJE3cNNFqy6Ah6NY4TRjYtraFvhY"
        libraries={libraries}
        onLoad={(): void => {
          this.setState({
            isGoogleMapLoaded: true
          })
        }}
        loadingElement={<LoaderComponent width={100} height={100} />}
      >
        <PlatformFormComponent
          onSubmit={this.onSubmit}
          isLoading={platformFormGetStore.isLoading}
          isFirstEdit={platformModerationStore.platformStatus === "EMPTY"}
          showErrors={this.showErrors}
          showValidationErrors={this.state.showValidationErrors}
          formInitialValues={platformFormGetStore.formData}
          onApprove={this.onApprove}
          onCheckForm={(): void => this.toggleCheckPopup(true)}
          onModerate={this.moderate}
          formStatus={platformModerationStore.platformStatus || "EMPTY"}
          comments={platformFormGetStore.comments}
          submittionErrors={
            platformFormApplyStore.requestErrors ||
            platformFormReadStore.requestErrors ||
            platformFormApproveStore.requestErrors ||
            platformFormModerateStore.requestErrors ||
            platformFormSaveStore.requestErrors
          }
          isSuccessModalVisible={this.state.isSuccessModalVisible}
          isCheckModalVisible={this.state.isCheckModalVisible}
          onPopupApply={this.onCheckForm}
          onCheckModalClose={(): void => this.toggleCheckPopup(false)}
          onSuccessModalClose={(): void => this.toggleSuccessPopup(false)}
          isApplyLoading={platformFormApplyStore.isLoading}
          isApproveLoading={platformFormApproveStore.isLoading}
          isCheckLoading={platformFormReadStore.isLoading}
          onDeleteModalToggle={this.toggleDeleteModal}
          onDeleteModalApply={this.deletePlatform}
          isDeleteModalVisible={this.state.isDeleteModalVisible}
          isDeleteLoading={platformFormDeleteStore.isLoading}
        />
      </LoadScript>
    )
  }
}

export default withRouter(PlatformFormContainer)

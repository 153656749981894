import classNames from 'classnames'
import React, { FC } from 'react'
import './Evaluation.style.sass'

type Props = {
  title: string;
  evaluationValue: number;
  evaluationMaxValue: number;
  isTotal?: boolean;
}
const Evaluation: FC<Props> = ({
  title,
  evaluationMaxValue,
  evaluationValue,
  isTotal = false
}) => {
  const block = 'evaluation'

  return (
    <div className={classNames(block, isTotal && `${block}--total`)}>
      <div className={`${block}__wrapper`}>
        <h3
          className={classNames(
            `${block}__value`,
            isTotal && `${block}__value--total`
          )}
        >
          {evaluationValue}
        </h3>
        <span className={`${block}__separator`}>/</span>
        <h3 className={`${block}__max`}>{evaluationMaxValue}</h3>
      </div>
      <h4 className={`${block}__title`}>{title}</h4>
    </div>
  )
}

export default Evaluation

import React, { FC } from 'react'
import 
  SelectionExpertHeaderContainer 
from 
  '../../../../containers/CRM/SelectionExpertCRM/SelectionExpertHeader.container'
import Footer from '../../../common/Footer/Footer.component'
import './style.sass'

const block = 'selection-expert-crm-layout'

const SelectionExpertCRMLayout: FC = (props): JSX.Element => (
    <div className={block}>
      <SelectionExpertHeaderContainer />
      <div className={`${block}__body`}>
        <div className="container">
          {props.children}
        </div>
      </div>
      <Footer />
    </div>
  )

export default SelectionExpertCRMLayout
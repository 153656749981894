import { api } from "../../../../utils/api"
import { Deserialize } from "cerialize"
import { RequestErrorResponse } from "../../../../models/common/error.model"
import { expertListStore } from "../../../../stores/CRM/AdminCRM/experts/expertsList.store"
import { ExpertListGetResponse } from "../../../../models/ExpertsModels/expertList.model"

export function getExpertList(): Promise<void> {
  expertListStore.setIsLoading(true)
  
  return api.get('/crm/admin/experts/').then((res) => {
    const expertsData: ExpertListGetResponse = Deserialize(
      res.data,
      ExpertListGetResponse
    )
    
    expertListStore.setExpertsData(expertsData)
    expertListStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    expertListStore.setIsLoading(false)
    expertListStore.setErrors(errorResponseData.errors)
    throw error
  })
}

import { api } from "../../../../utils/api"
import { performanceTimelineStore } from "../../../../stores/CRM/AdminCRM/scenes/timelineStore"
import { 
  TimelineGetResponse, 
  TimelineGetRequest, 
  PerformanceArtistGetRequest, 
  PerformanceArtistGetResponse,
  PerformanceCreateRequest,
  PerformanceListResponse,
  PerfomanceSaveRequest,
  PerformanceGetModel
} from "../../../../models/ScenesModels/performances.model"
import { Deserialize, Serialize } from "cerialize"
import { RequestErrorResponse } from "../../../../models/common/error.model"
import { performanceArtistsStore } from "../../../../stores/CRM/AdminCRM/scenes/performanceArtists.store"
import { performanceCreateStore } from "../../../../stores/CRM/AdminCRM/scenes/performanceCreate.store"
import { performanceListStore } from "../../../../stores/CRM/AdminCRM/scenes/performanceList.store"
import { performanceSaveStore } from "../../../../stores/CRM/AdminCRM/scenes/performanceSave.store"
import { 
  performanceApproveStore, 
  performanceDeleteStore 
} from "../../../../stores/CRM/AdminCRM/scenes/performanceModeration.store"
import { platformModerationStore } from "../../../../stores/CRM/AdminCRM/scenes/platformModeration.store"

export function getTimeline(request: TimelineGetRequest): Promise<void> {
  const requestData = Serialize(request, TimelineGetRequest)

  performanceTimelineStore.setIsLoading(true)
  
  return api.get(
    `/crm/timeline/`,
    {
      params: requestData
    }
  )
  .then((res) => {
    const timelineData = Deserialize(
      res.data,
      TimelineGetResponse
    )
    
    performanceTimelineStore.setTimelineData(timelineData)
    performanceTimelineStore.setIsLoading(false)
  })
  .catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    performanceTimelineStore.setIsLoading(false)
    performanceTimelineStore.setErrors(errorResponseData.errors)
    throw error
  })
}

export function getPerformanceArtists(request: PerformanceArtistGetRequest): Promise<void> {
  const requestData = Serialize(request, PerformanceArtistGetRequest)

  performanceArtistsStore.setIsLoading(true)

  return api.get(
    `/crm/artists/`,
    {
      params: requestData
    }
  )
  .then((res) => {
    const timelineData = Deserialize(
      res.data,
      PerformanceArtistGetResponse
    )
    
    performanceArtistsStore.setArtists(timelineData)
    performanceArtistsStore.setIsLoading(false)
  })
  .catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    performanceArtistsStore.setIsLoading(false)
    performanceArtistsStore.setErrors(errorResponseData.errors)
    throw error
  })
}

export function createPerformance(request: PerformanceCreateRequest): Promise<void> {
  const requestData = Serialize(request, PerformanceCreateRequest)

  performanceCreateStore.setIsLoading(true)

  return api.post(
    `/crm/place_forms/${request.sceneId}/performances/`,
    requestData
  )
  .then((res) => {
    const performanceData: PerformanceGetModel = Deserialize(res.data, PerformanceGetModel)

    platformModerationStore.setPlatformStatus(performanceData.areaForm.status)
    performanceCreateStore.setIsLoading(false)
    performanceArtistsStore.deleteArtistFromList(request.artistId)
  })
  .catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    performanceCreateStore.setIsLoading(false)
    performanceCreateStore.setErrors(errorResponseData.errors)
    throw error
  })
}

export function fetchPerformances(sceneId: number): Promise<void> {
  performanceListStore.setIsLoading(true)

  return api.get(
    `/crm/place_forms/${sceneId}/performances/`
  )
  .then((res) => {
    const performancesData: PerformanceListResponse = Deserialize(res.data, PerformanceListResponse)
    performanceListStore.setIsLoading(false)
    performanceListStore.setPerformances(performancesData)
  })
  .catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    performanceListStore.setIsLoading(false)
    performanceListStore.setErrors(errorResponseData.errors)
    throw error
  })
}

export function savePerformance(values: PerfomanceSaveRequest, performanceId: number): Promise<void> {
  const requestData = Serialize(values, PerfomanceSaveRequest)

  performanceSaveStore.setIsLoading(true)

  return api.put(
    `/crm/performances/${performanceId}/`,
    requestData
  )
  .then((res) => {
    const savedPerformance: PerformanceGetModel = Deserialize(res.data, PerformanceGetModel)
    platformModerationStore.setPlatformStatus(savedPerformance.areaForm.status)
    performanceListStore.updatePerformanceData(performanceId, savedPerformance)
    performanceSaveStore.setIsLoading(false)
  })
  .catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    performanceSaveStore.setIsLoading(false)
    performanceSaveStore.setErrors(errorResponseData.errors)
    throw error
  })
}

export function approvePerformance(performanceId: number): Promise<void> {
  performanceApproveStore.setIsLoading(true)

  return api.post(
    `/crm/performances/${performanceId}/approve/`
  )
  .then(() => {
    performanceListStore.updatePerformanceStatus(performanceId, 'APPROVED')
    performanceApproveStore.setIsLoading(false)
  })
  .catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    performanceApproveStore.setIsLoading(false)
    performanceApproveStore.setErrors(errorResponseData.errors)
    throw error
  })
}

export function deletePerformance(performanceId: number): Promise<void> {
  performanceDeleteStore.setIsLoading(true)

  return api.delete(
    `/crm/performances/${performanceId}/`
  )
  .then(() => {
    if (localStorage.getItem('userRole') === 'EXPERT') {
      platformModerationStore.setPlatformStatus(
        platformModerationStore.platformStatus === 'EMPTY' ||
          platformModerationStore.platformStatus === 'EDITING' ? (
            platformModerationStore.platformStatus
        ) : (
          'MODERATION'
        )
      )
    }
    
    performanceDeleteStore.setIsLoading(false)
    performanceListStore.deletePerformance(performanceId)
  })
  .catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    performanceDeleteStore.setIsLoading(false)
    performanceDeleteStore.setErrors(errorResponseData.errors)
    throw error
  })
}
import React from 'react'
import 
  PlatformModerationHeaderContainer 
from 
  '../../../../../containers/CRM/AdminCRM/PlatformModeration/PlatformModerationHeader'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { 
  PATH_PLATFORM_SCENES, 
  PATH_PLATFORM_FORM, 
  PATH_PLATFORM_EXPERTS, 
  PATH_PLATFORM_MODERATE 
} from '../../../../../const/routes.const'
import TabMenu from '../../../common/TabMenu/TabMenu.component'

type tabNames = 'platform' | 'scenes' | 'experts'

interface PropTypes {
  activeTabName: tabNames;
}

interface TabModel {
  name: tabNames;
  text: string;
  route: string;
}

const tabs: TabModel[] = [
  {
    name: 'platform',
    text: 'Информация о площадке',
    route: PATH_PLATFORM_FORM.replace(PATH_PLATFORM_FORM, '')
  },
  {
    name: 'scenes',
    text: 'Сцены',
    route: PATH_PLATFORM_SCENES.replace(PATH_PLATFORM_FORM, '')
  },
  {
    name: 'experts',
    text: 'Эксперты',
    route: PATH_PLATFORM_EXPERTS.replace(PATH_PLATFORM_FORM, '')
  }
]

const block = "platform-moderation-layout"

class PlatformModerationLayoutComponent extends React.Component<PropTypes & RouteComponentProps<{platformId: string}>> {
  render(): JSX.Element {
    const { activeTabName } = this.props
    const platformId = this.props.match.params.platformId
    const userRole = localStorage.getItem('userRole')

    return (
      <div className={block}>
        <PlatformModerationHeaderContainer>
          <div className="margin--top-l">
            <TabMenu 
              tabs={tabs.map((tab) => {
                if (userRole === 'EXPERT' && tab.name === 'experts') return

                return ({
                  onClick: (): void => this.props.history.push(`${PATH_PLATFORM_MODERATE}/${platformId}${tab.route}`),
                  isActive: activeTabName === tab.name,
                  ...tab
                })
              })}
              style="light"
            />
          </div>
        </PlatformModerationHeaderContainer>

        {this.props.children}
      </div>
    )
  }
}

export default withRouter(PlatformModerationLayoutComponent)
import React from 'react'
import ScenesListComponent from '../../../../../components/CRM/AdminCRM/PlatformModeration/ScenesList'
import { getScenesList } from '../../../../../services/CRM/AdminCRM/scenes/scenesList.service'
import { scenesListStore } from '../../../../../stores/CRM/AdminCRM/scenes/scenesList.store'
import { observer } from 'mobx-react'
import { createScene } from '../../../../../services/CRM/AdminCRM/scenes/createScene.service'
import { createSceneStore } from '../../../../../stores/CRM/AdminCRM/scenes/createScene.store'
import { sceneModerationStore } from '../../../../../stores/CRM/AdminCRM/scenes/sceneModeration.store'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { PATH_PLATFORM_MODERATE, PATH_CRM_SCENES_NEW } from '../../../../../const/routes.const'
import { deleteSceneStore } from '../../../../../stores/CRM/AdminCRM/scenes/deleteScene.store'
import { deleteScene } from '../../../../../services/CRM/AdminCRM/scenes/deleteScene.service'
import { platformFormGetStore } from '../../../../../stores/CRM/AdminCRM/scenes/platformGetStore'

interface State {
  newSceneName: string;
  isModalVisible: boolean;
  isDeleteModalVisible: boolean;
  deletingSceneId?: number;
}

@observer
class ScenesListContainer extends React.Component<RouteComponentProps<{platformId: string}>, State> {
  state = {
    newSceneName: '',
    isModalVisible: false,
    isDeleteModalVisible: false,
    deletingSceneId: undefined
  };

  componentDidMount(): void {
    const platformId = parseInt(this.props.match.params.platformId)

    if (isNaN(platformId)) {
      this.props.history.replace(PATH_CRM_SCENES_NEW)

      return
    }

    getScenesList(platformId)
  }

  componentWillUnmount(): void {
    scenesListStore.clearData()
  }

  setSceneName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      newSceneName: event.target.value,
      deletingSceneId: undefined
    })
  }

  toggleModal = (): void => {
    this.setState((prevState: State) => ({
      isModalVisible: !prevState.isModalVisible,
      deletingSceneId: undefined
    }))
  }

  showDeleteModal = (sceneId: number): void => {
    this.setState({
      isDeleteModalVisible: true,
      deletingSceneId: sceneId
    })
  }

  hideDeleteModal = (): void => {
    this.setState({
      isDeleteModalVisible: false,
      deletingSceneId: undefined
    })
  }
  
  deleteScene = (): void => {
    if (this.state.deletingSceneId) {
      deleteScene(Number(this.state.deletingSceneId))
      .then(() => this.hideDeleteModal())
    }
  }
  
  createScene = (): void => {
    const platformId = parseInt(this.props.match.params.platformId)

    createScene({name: this.state.newSceneName}, platformId).then(() => {
      this.toggleModal()
    })
    
  }

  onSceneClick = (sceneId: number, name: string): void => {
    sceneModerationStore.setSceneName(name)
    const platformId = parseInt(this.props.match.params.platformId)

    this.props.history.push(`${PATH_PLATFORM_MODERATE}/${platformId}/scenes/${sceneId}`)
  }

  render(): JSX.Element {
    return (
      <ScenesListComponent 
        scenes={scenesListStore.scenesList}
        onSceneAddClick={this.toggleModal}
        onSceneCreate={this.createScene}
        isModalVisible={this.state.isModalVisible}
        isScenesLoading={scenesListStore.isLoading}
        newSceneName={this.state.newSceneName}
        onSceneNameChange={this.setSceneName}
        isSceneCreating={createSceneStore.isLoading}
        onModalToggle={this.toggleModal}
        onSceneClick={this.onSceneClick}
        onDelete={this.showDeleteModal}
        onDeleteModalClose={this.hideDeleteModal}
        onDeleteModalApply={this.deleteScene}
        isDeleteModalVisible={this.state.isDeleteModalVisible}
        isDeleteLoading={deleteSceneStore.isLoading}
        isCheckedStatus={!!platformFormGetStore.checkedAt}
      />
    )
  }
}

export default withRouter(ScenesListContainer)
import { ReactComponent as FB } from '../../../../../assets/icons/fb.svg'
import { ReactComponent as Instagram } from '../../../../../assets/icons/instagram.svg'
import { ReactComponent as VK } from '../../../../../assets/icons/vk.svg'
import { ReactComponent as Telegram } from '../../../../../assets/icons/tg-circle.svg'
import { ReactComponent as Twitter } from '../../../../../assets/icons/twitter.svg'

export interface SocialNetworksField {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name: string;
  placeholder: string;
}

export const platformSocial: SocialNetworksField[] = [
  {
    name: 'facebookUrl',
    icon: FB,
    placeholder: 'Facebook'
  },
  {
    name: 'instagramUrl',
    icon: Instagram,
    placeholder: 'Instagram'
  },
  {
    name: 'vkontakteUrl',
    icon: VK,
    placeholder: 'Vkontakte'
  },
  {
    name: 'telegramUrl',
    icon: Telegram,
    placeholder: 'Telegram'
  }
]

export const contactSocial: SocialNetworksField[] = [
  {
    name: 'contactTelegramUrl',
    icon: Telegram,
    placeholder: 'Telegram'
  },
  {
    name: 'contactFacebookUrl',
    icon: FB,
    placeholder: 'Facebook'
  },
  {
    name: 'contactInstagramUrl',
    icon: Instagram,
    placeholder: 'Instagram'
  },
  {
    name: 'contactVkontakteUrl',
    icon: VK,
    placeholder: 'Vkontakte'
  },
  {
    name: 'contactTwitterUrl',
    icon: Twitter,
    placeholder: 'Twitter'
  }
]
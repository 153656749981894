import { action, observable } from "mobx"
import { 
  SelectionExpertDashboardGetResponse 
} from "../../../models/SelectionExpertsModels/selectionExpertDashboard.model"
import { ApiRequestStore } from "../../common/apiRequest.store"

class SelectionExpertDashboardStore extends ApiRequestStore {
  @observable dashboardData?: SelectionExpertDashboardGetResponse
  
  @action
  setDashboardData(data: SelectionExpertDashboardGetResponse): void {
    this.dashboardData = data
  }

  @action
  clearData(): void {
    this.dashboardData = undefined
  }
}

export const selectionExpertDashboardStore: SelectionExpertDashboardStore = new SelectionExpertDashboardStore()
import React from 'react'
import { Field, withTypes } from 'react-final-form'
import AuthFormInput from '../AuthFormInput/AuthFormInput.component'
import { LoginRequest } from '../../models/AuthModels/login.model'
import Button from '../Button/Button.component'
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg'
import { ReactComponent as PasswordIcon } from '../../assets/icons/password.svg'
import AuthFormLayoutComponent from '../AuthFormLayout/AuthFormLayout.component'
import { composeValidators, fieldRequired, incorrectEmail } from '../../const/validators.const'

import './LoginForm.style.sass'
import { RequestError } from '../../models/common/error.model'

interface PropTypes {
  onSubmit: (values: LoginRequest) => void;
  onForgotPassClick: () => void;
  isLoading: boolean;
  submittionErrors?: RequestError[];
}

const { Form } = withTypes<LoginRequest>()
const block = 'login-form'

export default class LoginFormComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { onSubmit, isLoading, submittionErrors, onForgotPassClick } = this.props

    return(
      <AuthFormLayoutComponent title="АВТОРИЗАЦИЯ" className={block}>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, pristine, hasValidationErrors, errors, touched }): JSX.Element => (
            <form className={`${block}__form`} onSubmit={handleSubmit}>
              <Field name="email" validate={composeValidators(fieldRequired, incorrectEmail)}>
                {({ input }): JSX.Element => (
                  <div className="margin margin--top-xs">
                    <AuthFormInput
                      placeholder="Email"
                      onChange={input.onChange}
                      name={input.name}
                      value={input.value}
                      type={input.type}
                      icon={<EmailIcon fill="#D2DAE4" />}
                    />
                  </div>
                )}
              </Field>
              <Field name="password" type="password" validate={fieldRequired}>
                {({ input }): JSX.Element => (
                  <div className="margin margin--top-xs">
                    <AuthFormInput
                      placeholder="Пароль"
                      onChange={input.onChange}
                      name={input.name}
                      value={input.value}
                      type={input.type}
                      icon={<PasswordIcon fill="#D2DAE4" />}
                    />
                  </div>
                )}
              </Field>

              {hasValidationErrors && touched && touched[Object.keys(errors)[0]] ? (
                <div className={'auth-form-layout__error'}>
                  {errors[Object.keys(errors)[0]]}
                </div>
              ) : (submittionErrors?.length && (
                  <div className={'auth-form-layout__error'}>
                    {submittionErrors[0].text}
                  </div>
              ))}

              <div className={`auth-form-layout__pass-recovery`} onClick={onForgotPassClick}>
                Забыли пароль?
              </div>

              <button className={`margin margin--top-m`} disabled={pristine || isLoading}>
                <Button
                  text="Войти"
                  background="purple"
                  onClick={(): null => null}
                  isDisabled={pristine || isLoading}
                />
              </button>
            </form>
          )}
        />
      </AuthFormLayoutComponent>
    )
  }
}

import { serialize } from 'cerialize'

export class SceneExpertAddRequest {
  @serialize email: string;

  constructor(email: string) {
    this.email = email
  }
}

export class PlatformExpertAddRequest {
  @serialize email: string;

  constructor(email: string) {
    this.email = email
  }
}
import React from 'react'
import { ReactComponent as Arrow } from '../../../../../../assets/icons/arrow.svg'
import { PATH_PLATFORM_SCENE_FORM, PATH_PLATFORM_SCENE_SHEDULE } from '../../../../../../const/routes.const'

import './SceneInfoLayout.style.sass'
import TabMenu from '../../../../common/TabMenu/TabMenu.component'

interface PropTypes {
  onBackClick: () => void;
  onTabClick: (route: string) => void;
  sceneName?: string;
  activeTabName: tabNames;
}

type tabNames = 'experts' | 'shedule'

interface TabModel {
  name: tabNames;
  text: string;
  route: string;
}

const block = 'scene-info-layout'

const tabs: TabModel[] = [
  {
    name: 'experts',
    text: 'Эксперты',
    route: PATH_PLATFORM_SCENE_FORM.replace(PATH_PLATFORM_SCENE_FORM, '')
  },
  {
    name: 'shedule',
    text: 'Расписание',
    route: PATH_PLATFORM_SCENE_SHEDULE.replace(PATH_PLATFORM_SCENE_FORM, '')
  }
]

export default class SceneInfoLayoutComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { onBackClick, sceneName, activeTabName, onTabClick } = this.props
    
    return (
      <div className={block}>
        <div className="container">
          <div className={`${block}__title`}>
            <div className={`${block}__back`} onClick={onBackClick}>
              <Arrow fill="#000" />
            </div>
            <div className="page-title page-title--black">
              {sceneName}
            </div>
          </div>

          <div className="margin--top-l">
            <TabMenu 
              tabs={tabs.map(tab => ({
                onClick: (): void => onTabClick(tab.route),
                isActive: activeTabName === tab.name,
                ...tab
              }))}
              style="dark"
            />
          </div>
          
          <div className="margin--top-l">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}
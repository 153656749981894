import React from "react"
import { ListArtistModel } from "../../../../../models/AdminArtistFormsModels/adminArtistForms.model"
import CRMListItemComponent from "../../../../../components/CRM/AdminCRM/CrmListItem/CrmListItem.component"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { PATH_ADMIN_ARTIST_MODERATE } from "../../../../../const/routes.const"
import { artistModerationStore } from "../../../../../stores/CRM/AdminCRM/artists/artistModeration.store"
import { artistFormStatuses } from "../../../../../models/ArtistFormModels/artistForm.model"

interface PropTypes {
  artistData: ListArtistModel;
}

const statuses: {
  [key in artistFormStatuses]: {
    title: string;
    color: "red" | "grey" | "yellow" | "green" | "blue";
  };
} = {
  NEW: {
    title: "Новая заявка",
    color: "red"
  },
  EDITING: {
    title: "Редактируется артистом",
    color: "grey"
  },
  MODERATION: {
    title: "Требуется модерация",
    color: "yellow"
  },
  APPROVED: {
    title: "Заявка утверждена",
    color: "green"
  },
  CHECKED: {
    title: "Вычитано",
    color: "blue"
  }
}

class ArtistItemContainer extends React.Component<PropTypes & RouteComponentProps> {
  onClick = (): void => {
    const { artistData } = this.props

    artistModerationStore.setFormStatus(artistData.adminStatus)

    this.props.history.push(
      `${PATH_ADMIN_ARTIST_MODERATE}/${artistData.id}`
    )
  }

  render(): JSX.Element {
    const { artistData } = this.props

    return (
      <CRMListItemComponent 
        title={artistData.name}
        image={artistData.avatar ? (artistData.avatar.urlSizeS || artistData.avatar.url) : undefined}
        statusColor={statuses[artistData.adminStatus].color}
        statusText={statuses[artistData.adminStatus].title}
        onClick={this.onClick} 
      />
    )
  }
}

export default withRouter(ArtistItemContainer)
import React from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import ArtistItemContainer from "../../../../containers/CRM/AdminCRM/ArtistList/ArtistItem/ArtistItem.container"
import SearchInputContainer from "../../../../containers/SearchInput/SearchInput.container"
import { 
  ListArtistModel, ArtistListCategory
} from "../../../../models/AdminArtistFormsModels/adminArtistForms.model"
import LoaderComponent from "../../../common/Loader/Loader.component"

import "react-tabs/style/react-tabs.css"
import LazyLoadComponent from "../../common/LazyLoad/LazyLoad.component"
import LazyLoad from "react-lazyload"


const block = "artist-list"

interface PropTypes {
  tabIndex: number;
  isLoading: boolean;
  artistsData?: ListArtistModel[];
  onSelect: (tabIndex: number) => void;
  onSearchChange: (value: string) => void;
  onLoadMore: () => void;
  artistsCount?: number;
  shouldLoadMore: boolean;
}

const tabNames: { [key in ArtistListCategory]: string } = {
  new: "Новые",
  remoderation: "На повторной модерации",
  approved: "Утвержденные",
  checked: "Вычитанные"
}

export default class ArtistListComponent extends React.Component<PropTypes> {
  onLoadMore = (): void => {
    !this.props.isLoading && this.props.onLoadMore()
  }

  render(): JSX.Element {
    const {
      tabIndex,
      isLoading,
      artistsData,
      onSelect,
      onSearchChange,
      shouldLoadMore,
      artistsCount
    } = this.props

    return (
      <div className={`${block} admin-layout-content`}>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(tabIndex: number): void => onSelect(tabIndex)}
        >
          <div className="admin-layout-content__header">
            <TabList>
              {Object.keys(tabNames).map((category, index) => (
                <Tab key={category}>
                  {tabIndex === index && (
                    <div className="admin-layout-content__counter">
                      {artistsCount || 0}
                    </div>
                  )}
                  {tabNames[category]}
                </Tab>
              ))}
            </TabList>

            <div className="admin-layout-content__controls">
              <SearchInputContainer onSearchChange={onSearchChange} />
            </div>
          </div>
          {isLoading && !artistsData?.length && (
            <div className="admin-layout-content__loading">
              <LoaderComponent width={100} height={100} />
            </div>
          )}
          <div className="admin-layout-content__body">
            {Object.keys(tabNames).map(category => (
              <TabPanel key={category}>
                {artistsData && artistsData.length ? (
                  <>
                  {artistsData.map((artist: ListArtistModel) => (
                    <ArtistItemContainer artistData={artist} key={artist.id} />
                  ))}
                  {shouldLoadMore && (
                    <LazyLoad height={10} unmountIfInvisible={true}>
                      <LazyLoadComponent isLoading={isLoading} onMount={this.onLoadMore} />
                    </LazyLoad>
                  )}
                  </>
                ) : !isLoading && (
                  <div className="admin-layout-content__empty">
                    Список пуст
                  </div>
                )}
              </TabPanel>
            ))}
          </div>
        </Tabs>
      </div>
    )
  }
}

import React from 'react'
import InputComponent from '../../../common/Input/Input.component'
import InputLabelComponent from '../../../ArtistCRM/ArtistForm/InputLabel/InputLabel.component'

import './PlatformExperts.style.sass'
import Button from '../../../../Button/Button.component'
import PlatformExpertsListContainer from '../../../../../containers/CRM/AdminCRM/PlatformModeration/PlatformExpertsList'

interface PropTypes {
  expertEmail: string;
  isInputFocused: boolean;
  onInputFocus: () => void;
  onInputBlur: () => void;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputError?: string;
  onExpertAdd: () => void;
}

const block = "platform-experts"

export default class PlatformExpertsComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { 
      expertEmail, 
      isInputFocused, 
      onInputFocus, 
      onInputBlur,
      onInputChange,
      inputError,
      onExpertAdd
    } = this.props

    return (
      <div className={block}>
        <div className="container">
          <div className={`${block}__form form`}>
            <div className="form__title form__title--black">
              ДОБАВЛЕНИЕ ЭКСПЕРТОВ
            </div>
            <div className="form__form-block margin--top-xs">
              <div className="form__input-block">
                <div className="form__input-title">
                  Email эксперта, зарегистрированного на сайте UMN
                </div>
                <div className="form__input margin margin--top-xxs">
                  <InputComponent
                    placeholder="Введите текст"
                    value={expertEmail}
                    name="new-platform-expert-email"
                    onChange={onInputChange}
                    error={inputError}
                    onBlur={onInputBlur}
                    onFocus={onInputFocus}
                  />
                  <div className="form__input-label">
                    <InputLabelComponent isFocused={isInputFocused}>
                      Добавьте экспертов, которые смогут редактировать информацию о площадке
                    </InputLabelComponent>
                  </div>
                </div>
              </div>
            </div>

            <div className={`margin--top-xs ${block}__button`}>
              <Button 
                onClick={onExpertAdd}
                background="purple"
                text="Добавить эксперта"
              />
            </div>
          </div>

          <div className="margin--top-xl">
            <PlatformExpertsListContainer 
              isSceneExperts={false} 
            />
          </div>
        </div>
      </div>
    )
  }
}
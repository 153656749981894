import { observable, action, get, set } from 'mobx'

export class SingleCheckboxState {
  @observable checked: boolean;

  constructor(checked: boolean) {
    this.checked = checked
  }
}

export class CheckboxStore {
  @observable items = new Map([])

  @action
  registration = (name: string, defaultChecked: boolean): void => {
    const nameExists = get(this.items, name)
    if (nameExists) return

    const value = new SingleCheckboxState(defaultChecked)
    set(this.items, name, value)
  }

  @action
  unmount = (name: string): void => {
    this.items.delete(name)
  };

  @action
  setChecked = (name: string, isChecked: boolean): void => {
    const item = get(this.items, name)
    item.checked = isChecked
  }

  getItem = (name: string): SingleCheckboxState => get(this.items, name)
}

export const checkboxStore: CheckboxStore = new CheckboxStore()

import React from 'react'
import { Field, Form } from 'react-final-form'
import { composeValidators, fieldRequired, incorrectEmail } from '../../const/validators.const'
import { PassRecoveryRequest } from '../../models/AuthModels/login.model'
import AuthFormInput from '../AuthFormInput/AuthFormInput.component'
import AuthFormLayoutComponent from '../AuthFormLayout/AuthFormLayout.component'
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg'

import './PassRecoveryForm.style.sass'
import Button from '../Button/Button.component'
import { RequestError } from '../../models/common/error.model'

interface Props {
  error?: string;
  isLoading?: boolean;
  onSubmit: (data: PassRecoveryRequest) => void;
  onLoginClick: () => void;
  submittionErrors?: RequestError[];
}

const block = 'pass-recovery-form'

const PassRecoveryFormComponent = (props: Props): JSX.Element => {
  const { error, isLoading, onSubmit, onLoginClick, submittionErrors } = props

  return (
    <AuthFormLayoutComponent 
      title="ВОССТАНОВЛЕНИЕ ПАРОЛЯ" 
      className={block}
      error={error}
    >
      <div className={`${block}__text`}>
        Забыли пароль? Не беда. <br/>
        Мы поможем вам его восстановить!
      </div>

      <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, pristine, hasValidationErrors, errors, touched }): JSX.Element => (
            <form className={`${block}__form`} onSubmit={handleSubmit}>
              <Field name="email" validate={composeValidators(fieldRequired, incorrectEmail)}>
                {({ input }): JSX.Element => (
                  <div className="margin margin--top-xs">
                    <AuthFormInput
                      placeholder="Email"
                      onChange={input.onChange}
                      name={input.name}
                      value={input.value}
                      type={input.type}
                      icon={<EmailIcon fill="#D2DAE4" />}
                    />
                  </div>
                )}
              </Field>

              <div className={`auth-form-layout__pass-recovery`} onClick={onLoginClick}>
                Войти
              </div>

              {hasValidationErrors && touched && touched[Object.keys(errors)[0]] ? (
                <div className={'auth-form-layout__error'}>
                  {errors[Object.keys(errors)[0]]}
                </div>
              ) : (submittionErrors?.length && (
                  <div className={'auth-form-layout__error'}>
                    {submittionErrors[0].text}
                  </div>
              ))}

              <button className={`margin margin--top-m`} disabled={pristine || isLoading}>
                <Button
                  text={'Отправить'}
                  background="purple"
                  onClick={(): null => null}
                  isDisabled={pristine || isLoading}
                />
              </button>
            </form>
          )}
        />
      
    </AuthFormLayoutComponent>
  )
}

export default PassRecoveryFormComponent
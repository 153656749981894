import React from 'react'
import { ReactComponent as Remove } from '../../../../assets/icons/remove.svg'

import './ErrorLabel.style.sass'

interface PropTypes {
  text: string;
}

const block = "error-label"


export default class ErrorLabelComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    return (
      <div className={`${block}__error`}>
        <div className={`${block}__error-img`}>
          <Remove width="25" height="25" />
        </div>
        {this.props.text}
      </div>
    )
  }
}
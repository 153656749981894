import { api } from "../../../../utils/api"
import { RequestErrorResponse } from "../../../../models/common/error.model"
import { deleteSceneStore } from "../../../../stores/CRM/AdminCRM/scenes/deleteScene.store"

import { scenesListStore } from "../../../../stores/CRM/AdminCRM/scenes/scenesList.store"

export function deleteScene(id: number): Promise<void> {
  deleteSceneStore.setIsLoading(false)

  return api.delete(
    `/crm/admin/place_forms/${id}`
  ).then(() => {
    scenesListStore.deleteScene(id)
    deleteSceneStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    deleteSceneStore.setIsLoading(false)
    deleteSceneStore.setErrors(errorResponseData.errors)
    throw error
  })
}
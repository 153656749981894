import React from "react"
import {
  ArtistFormFields,
  ArtistFormSaveRequest
} from "../../../../models/ArtistFormModels/artistForm.model"
import ArtistForm from "../../../../components/CRM/ArtistCRM/ArtistForm/ArtistForm.component"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { observer } from "mobx-react"
import {
  artistFormGetStore,
  artistFormApplyStore,
  artistFormModerateStore,
  artistFormApproveStore,
  artistFormReadStore,
  artistFormSaveStore
} from "../../../../stores/CRM/ArtistCRM/artistForm.store"
import { checkboxStore } from "../../../../stores/common/checkboxStore"
import {
  saveArtistForm,
  getArtistForm,
  applyForm,
  moderateArtist,
  approveArtist,
  checkForm,
  commentByArtist,
  unCheckForm
} from "../../../../services/CRM/ArtistCRM/artistForm.service"
import {
  PATH_ARTIST_NOTIFICATIONS,
  PATH_CRM_ARTISTS_NEW,
  PATH_CRM_SCENES,
  PATH_ROOT
} from "../../../../const/routes.const"
import { artistModerationStore } from "../../../../stores/CRM/AdminCRM/artists/artistModeration.store"
import { formCommentStore } from "../../../../stores/CRM/AdminCRM/artists/formComment.store"
import { artistFormNotificationsStore } from "../../../../stores/CRM/ArtistCRM/artistFormNotifications.store"
import { userStore } from "../../../../stores/common/user.store"
import { getUserRole } from "../../../../utils/localStorage"

const agreeCheckboxesData = [
  { name: "accessCheckbox", isChecked: true },
  { name: "termsCheckbox", isChecked: true }
]

@observer
class ArtistFormContainer extends React.Component<
  RouteComponentProps<{ artistId: string }>
> {
  state = {
    showValidationErrors: false
  };

  constructor(props: RouteComponentProps<{ artistId: string }>) {
    super(props)

    artistFormGetStore.clearData()
  }

  componentDidMount(): void {
    const artistId = parseInt(this.props.match.params.artistId)
    const userRole = getUserRole()

    if (!userStore.userData) {
      this.props.history.replace(PATH_ROOT)
      return
    }

    if (userRole === "ADMIN" && isNaN(artistId)) {
      this.props.history.replace(PATH_CRM_ARTISTS_NEW)
      return
    }

    if (userRole === "EXPERT" && isNaN(artistId)) {
      this.props.history.replace(PATH_CRM_SCENES)
      return
    }

    if (userRole === "ADMIN" || userRole === "EXPERT") {
      getArtistForm(artistId)
    } else {
      getArtistForm()
    }
  }

  componentWillUnmount(): void {
    artistFormGetStore.clearData()
    artistFormApproveStore.clearErrors()
    artistFormModerateStore.clearErrors()
    artistFormReadStore.clearErrors()
  }

  showErrors = (): void => {
    this.setState({
      showValidationErrors: true
    })
  };

  moderate = (values: ArtistFormSaveRequest): void => {
    const artistId = parseInt(this.props.match.params.artistId)

    saveArtistForm(values, artistId).then(() => {
      moderateArtist(formCommentStore.comment, artistId).then(() => {
        this.props.history.goBack()
      })
    })
  };

  comment = (): void => {
    const userRole = getUserRole()
    if (userRole && userRole === "ARTIST") {
      commentByArtist(formCommentStore.comment).then(() => {
        formCommentStore.clearComment()
      })
    }
  };

  onApprove = (values: ArtistFormSaveRequest): void => {
    const artistId = parseInt(this.props.match.params.artistId)

    saveArtistForm(values, artistId).then(() => {
      approveArtist(artistId).then(() => {
        this.props.history.goBack()
      })
    })
  };

  onCheckForm = (values: ArtistFormSaveRequest): void => {
    const artistId = parseInt(this.props.match.params.artistId)

    if (artistId) {
      const id = artistId

      saveArtistForm(values, id).then(() => {
        checkForm(id).then(() => {
          this.props.history.goBack()
        })
      })
    }
  };

  onUnCheckForm = (values: ArtistFormSaveRequest): void => {
    const artistId = parseInt(this.props.match.params.artistId)

    if (artistId) {
      const id = artistId

      saveArtistForm(values, id).then(() => {
        unCheckForm(formCommentStore.comment, id).then(() => {
          this.props.history.goBack()
        })
      })
    }
  }

  onSubmit = (values: ArtistFormFields, isApply?: boolean): void => {
    const { comment, ...saveRequestValues } = values
    const userRole = getUserRole()
    const artistId = parseInt(this.props.match.params.artistId)
    saveArtistForm(saveRequestValues, artistId).then(() => {
      isApply &&
        (userRole === "ARTIST"
          ? applyForm({ comment }).then(() =>
              this.props.history.replace(PATH_ARTIST_NOTIFICATIONS)
            )
          : approveArtist(artistId).then(() => {
              this.props.history.goBack()
            }))
    })
  };

  getIsSubmitEnabled = (): boolean =>
    agreeCheckboxesData.filter(
      checkboxData =>
        checkboxStore.getItem(checkboxData.name) &&
        checkboxStore.getItem(checkboxData.name).checked
    ).length === agreeCheckboxesData.length;

  render(): JSX.Element {
    const artistId = parseInt(this.props.match.params.artistId)

    return (
      <ArtistForm
        onSubmit={this.onSubmit}
        isLoading={artistFormGetStore.isLoading}
        isSubmitEnabled={this.getIsSubmitEnabled()}
        agreeCheckboxesData={agreeCheckboxesData}
        formInitialValues={artistFormGetStore.formData}
        showValidationErrors={this.state.showValidationErrors}
        showErrors={this.showErrors}
        formId={artistId}
        isModerateActive={!!formCommentStore.comment.length}
        onModerate={this.moderate}
        onComment={this.comment}
        onApprove={this.onApprove}
        onCheckForm={this.onCheckForm}
        onUnCheckForm={this.onUnCheckForm}
        comments={artistFormGetStore.comments}
        formStatus={artistModerationStore.formStatus || "NEW"}
        isFirstEdit={
          !artistFormNotificationsStore.notificationsData?.notifications.length
        }
        isFormApplyLoading={artistFormApplyStore.isLoading}
        isModerateLoading={artistFormModerateStore.isLoading}
        isApproveLoading={artistFormApproveStore.isLoading}
        isCheckLoading={artistFormReadStore.isLoading}
        submittionErrors={
          artistFormApplyStore.requestErrors ||
          artistFormReadStore.requestErrors ||
          artistFormApproveStore.requestErrors ||
          artistFormModerateStore.requestErrors ||
          artistFormSaveStore.requestErrors
        }
        userData={userStore.userData}
        sentToModerationAt={
          artistFormNotificationsStore.notificationsData?.sentToModerationAt
        }
      />
    )
  }
}

export default withRouter(ArtistFormContainer)

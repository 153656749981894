import React from 'react'
import LoaderComponent from '../../../common/Loader/Loader.component'
import SearchInputContainer from '../../../../containers/SearchInput/SearchInput.container'
import CircleButtonComponent from '../../common/CircleButton/CircleButton.component'
import { ReactComponent as PlusIcon } from "../../../../assets/icons/icon-plus.svg"
import { ListExpertModel } from '../../../../models/ExpertsModels/expertList.model'
import CrmListItemComponent from '../CrmListItem/CrmListItem.component'

interface PropTypes {
  isLoading: boolean;
  onPlusButtonClick: () => void;
  experts?: ListExpertModel[];
  onExpertClick: (id: number) => void;
}

export default class ExpertListComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { isLoading, onPlusButtonClick, experts, onExpertClick } = this.props

    return (
      <div className="admin-layout-content">
        {isLoading ? (
          <div className="admin-layout-content__loading">
            <LoaderComponent width={100} height={100} />
          </div>
        ) : (
          <>
          <div className="admin-layout-content__header">
            <div className="admin-layout-content__controls">
              <SearchInputContainer />
              <div
                className="admin-layout-content__add"
              >
                <p className="admin-layout-content__add-title">Новый эксперт</p>
                <CircleButtonComponent onClick={onPlusButtonClick}>
                  <PlusIcon fill="#FFFFFF" />
                </CircleButtonComponent>
              </div>
            </div>
          </div>

          <div className="admin-layout-content__body admin-layout-content__body--padding-top-s">
            {experts && experts.length ? (
              experts.map(expert => (
                <CrmListItemComponent
                  key={expert.id} 
                  image={expert.photo && (expert.photo.urlSizeS || expert.photo.url)}
                  onClick={(): void => onExpertClick(expert.id)}
                  title={(expert.name ? `${expert.name} ` : '') + expert.email}
                />
              ))
            ) : (
              <div className="admin-layout-content__empty">
                Список пуст
              </div>
            )}
          </div>
          </>
        )}
      </div>
    )
  }
}
import { api } from "../../../../utils/api"
import { Serialize, Deserialize } from "cerialize"
import { PlatformGetResponse, PlatformSaveRequest, NumbersModel } from "../../../../models/ScenesModels/platform.model"
import { FileUploadResponse } from "../../../../models/common/fileUpload.model"
import { 
  platformFormGetStore, 
  platformFormSaveStore, 
  platformFormApplyStore, 
  platformFormModerateStore, 
  platformFormReadStore, 
  platformFormApproveStore,
  platformFormDeleteStore
} from "../../../../stores/CRM/AdminCRM/scenes/platformGetStore"
import { RequestErrorResponse } from "../../../../models/common/error.model"
import moment from "moment"
import { platformModerationStore } from "../../../../stores/CRM/AdminCRM/scenes/platformModeration.store"

export function getPlatformForm(id?: number): Promise<void> {
  const userRole = localStorage.getItem('userRole')
  const url = userRole === 'ADMIN' ? `/crm/admin/area_forms/${id}/` : `/crm/expert/area_forms/${id}/`
  platformFormGetStore.setIsLoading(true)

  return api.get(
    url
  ).then((res) => {

    const data: PlatformGetResponse = Deserialize(res.data, PlatformGetResponse)
    const partnerLogos: Array<FileUploadResponse | undefined> = []
    const volunteers = data.volunteers.length ? data.volunteers : ['']
    const { comments, ...formData } = data

    if (data.partnerLogos?.length) {
      data.partnerLogos.forEach(partnerLogo => partnerLogos.push(partnerLogo))
    }

    while (partnerLogos && partnerLogos.length < 8) {
      partnerLogos.push(undefined)
    }

    platformFormGetStore.setFormData({
      ...formData,
      partnerLogos,
      volunteers,
      sceneGenres: data.sceneGenres.map(genre => genre.label)
    })
    platformFormGetStore.setComments(comments)
    platformFormGetStore.setCheckedAt(data.checkedAt)
    platformFormGetStore.setIsLoading(false)
    platformModerationStore.setPlatformStatus(data.status)
  }).catch((error) => {
    if (error.response) {
      const errorResponseData: RequestErrorResponse =  error.response.data
      platformFormGetStore.setErrors(errorResponseData.errors)
    }
    
    platformFormGetStore.setIsLoading(false)

    throw error
  })
}


export function savePlatformForm(request: PlatformSaveRequest, id?: number): Promise<void> {
  const userRole = localStorage.getItem('userRole')
  const url = userRole === 'ADMIN' ? `/crm/admin/area_forms/${id}/` : `/crm/expert/area_forms/${id}/`
  const prepared = {
    equipmentCapabilities: 
      request.equipmentCapabilities?.filter(Boolean).map(equipmentCapability => equipmentCapability?.id),
    additionalEquipmentRequirements:
      request.additionalEquipmentRequirements?.filter(Boolean).map(additionalEquipmentRequirement => (
        additionalEquipmentRequirement?.id
      )),
    photoSite: request.photoSite?.id || null,
    photoSite2: request.photoSite2?.id || null,
    photoSiteHeader: request.photoSiteHeader?.id || null,
    logo: request.logo?.id || null,
    partnerLogos: request.partnerLogos.filter(Boolean).map(partnerLogo => partnerLogo?.id),
    volunteers: request.volunteers.filter(Boolean)
  }
  const serializedData = Serialize({...request, ...prepared}, PlatformSaveRequest)
  const serializedNumbers = Serialize(request.numbers, NumbersModel)
  
  platformFormSaveStore.setIsLoading(true)

  return api.put(
    url,
    {
      ...serializedData,
      numbers: serializedNumbers
    }
  ).then((response) => {
    const responseData: PlatformGetResponse = Deserialize(response.data, PlatformGetResponse)
    platformModerationStore.setPlatformStatus(responseData.status)

    platformFormSaveStore.setIsLoading(true)
    platformFormSaveStore.setSaveTime(moment().toDate())
  }).catch((error) => {
    if (error.response) {
      const errorResponseData: RequestErrorResponse =  error.response.data
      platformFormSaveStore.setErrors(errorResponseData.errors)
    }

    platformFormSaveStore.setIsLoading(false)

    throw error
  })
}


export function applyForm(id?: number): Promise<void> {
  platformFormApplyStore.setIsLoading(true)

  return api.post(
    `/crm/expert/area_forms/${id}/apply/`
  ).then(() => {
    platformFormApplyStore.setIsLoading(false)
  }).catch((error) => {
    if (error.response) {
      const errorResponseData: RequestErrorResponse =  error.response.data
      platformFormApplyStore.setErrors(errorResponseData.errors)
    }

    platformFormApplyStore.setIsLoading(false)

    throw error
  })
}

export function moderatePlatform(comment: string, id?: number): Promise<void> {
  platformFormModerateStore.setIsLoading(true)

  return api.post(
    `/crm/admin/area_forms/${id}/moderate/`,
    {comment}
  ).then(() => {
    platformFormModerateStore.setIsLoading(false)
  }).catch((error) => {
    if (error.response) {
      const errorResponseData: RequestErrorResponse =  error.response.data
      platformFormModerateStore.setErrors(errorResponseData.errors)
    }

    platformFormModerateStore.setIsLoading(false)

    throw error
  })
}

export function approvePlatform(id?: number): Promise<void> {
  platformFormApproveStore.setIsLoading(true)

  return api.post(
    `/crm/admin/area_forms/${id}/approve/`
  ).then(() => {
    platformFormApproveStore.setIsLoading(false)
  }).catch((error) => {
    if (error.response) {
      const errorResponseData: RequestErrorResponse =  error.response.data
      platformFormApproveStore.setErrors(errorResponseData.errors)
    }

    platformFormApproveStore.setIsLoading(false)

    throw error
  })
}

export function checkPlatform(id: number): Promise<void> {
  platformFormReadStore.setIsLoading(true)

  return api.post(
    `/crm/admin/area_forms/${id}/check/`
  ).then(() => {
    platformFormReadStore.setIsLoading(false)
  }).catch((error) => {
    if (error.response) {
      const errorResponseData: RequestErrorResponse =  error.response.data
      platformFormReadStore.setErrors(errorResponseData.errors)
    }

    platformFormReadStore.setIsLoading(false)

    throw error
  })
}

export function deletePlatform(id: number): Promise<void> {
  platformFormDeleteStore.setIsLoading(false)

  return api.delete(
    `/crm/admin/area_forms/${id}`
  ).then(() => {
    platformFormDeleteStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    platformFormDeleteStore.setIsLoading(false)
    platformFormDeleteStore.setErrors(errorResponseData.errors)
    throw error
  })
}
import { action, observable } from "mobx"
import {
  SelectionExpertArtistGetResponse,
  SelectionExpertArtistWithRatingGetResponse,
  EvaluateForm
} from "../../../models/SelectionExpertsModels/selectionExpertEvaluation.model"
import { ApiRequestStore } from "../../common/apiRequest.store"

class SelectionExpertArtistGetStore extends ApiRequestStore {
  @observable artist?: SelectionExpertArtistGetResponse;

  @action
  setArtistFormData(data: SelectionExpertArtistGetResponse): void {
    this.artist = data
  }

  @action
  clearData(): void {
    this.artist = undefined
  }
}

class SelectionExpertArtistIdGetStore extends ApiRequestStore {
  @observable artist?: SelectionExpertArtistGetResponse;
  @observable rating?: EvaluateForm;

  @action
  setArtistFormData(data: SelectionExpertArtistWithRatingGetResponse): void {
    this.artist = data.artistForm
    this.rating = data.rating
  }

  @action
  clearData(): void {
    this.artist = undefined
    this.rating = undefined
  }
}

class SelectionExpertEvaluate extends ApiRequestStore {}

export const selectionExpertArtistGetStore: SelectionExpertArtistGetStore =
  new SelectionExpertArtistGetStore()
export const selectionExpertArtistIdGetStore: SelectionExpertArtistIdGetStore =
  new SelectionExpertArtistIdGetStore()
export const selectionExpertEvaluateStore: SelectionExpertEvaluate =
  new SelectionExpertEvaluate()

import React from 'react'
import FormHeaderComponent from '../../../../../components/CRM/ArtistCRM/FormHeader/FormHeader.component'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { platformModerationStore } from '../../../../../stores/CRM/AdminCRM/scenes/platformModeration.store'
import { observer } from 'mobx-react'
import { PlatformStatus } from '../../../../../models/ScenesModels/platformList.model'
import { platformFormSaveStore } from '../../../../../stores/CRM/AdminCRM/scenes/platformGetStore'
import { sceneFormSaveStore } from '../../../../../stores/CRM/AdminCRM/scenes/sceneForm.store'
import { 
  PATH_CRM_SCENES_NEW, 
  PATH_CRM_SCENES_APPROVED, 
  PATH_CRM_SCENES_CHECKED, 
  PATH_CRM_SCENES_REMODERATION 
} from '../../../../../const/routes.const'

interface PropTypes {
  children: React.ReactNode;
}

const statuses: {[key in PlatformStatus]: string} = {
  EMPTY: 'Заполняется экспертом',
  EDITING: 'Заполняется экспертом',
  MODERATION: 'На модерации',
  APPROVED: 'Утверждена',
  CHECKED: 'Вычитано'
}

@observer
class PlatformModerationHeaderContainer extends React.Component<RouteComponentProps & PropTypes> {
  onSave = (): void => {
    const sceneForm = document.getElementById('scene-form')
    const platformForm = document.getElementById('platform-form')
    const form = sceneForm || platformForm
    form && form.dispatchEvent(new Event('submit', { cancelable: true }))
  }

  onBack = (): void => {
    switch (platformModerationStore.platformCategory) {
      case 'newCategory':
        this.props.history.push(PATH_CRM_SCENES_NEW)
        break

      case 'remoderation':
        this.props.history.push(PATH_CRM_SCENES_REMODERATION)
        break

      case 'approved':
        this.props.history.push(PATH_CRM_SCENES_APPROVED)
        break

      case 'checked':
        this.props.history.push(PATH_CRM_SCENES_CHECKED)
        break

      default:
        this.props.history.push(PATH_CRM_SCENES_NEW)
    }
    
  }

  render(): JSX.Element {
    return (
      <FormHeaderComponent
        onSave={this.onSave}
        requestStatusTitle={platformModerationStore.platformStatus && "Статус площадки"}
        requestStatus={platformModerationStore.platformStatus && statuses[platformModerationStore.platformStatus]}
        isStatic={true}
        isHeaderFading={false}
        onBack={this.onBack}
        title="Создание площадки"
        hasExitButton={true}
        hasBackButton={true}
        hasSaveButton={true}
        saveTime={platformFormSaveStore.saveTime || sceneFormSaveStore.saveTime}
      >
        {this.props.children}
      </FormHeaderComponent>
    )
  }
}

export default withRouter(PlatformModerationHeaderContainer)
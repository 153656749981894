import React from 'react'
import FormHeaderComponent from '../../../../components/CRM/ArtistCRM/FormHeader/FormHeader.component'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { artistFormStatuses } from '../../../../models/ArtistFormModels/artistForm.model'
import { observer } from 'mobx-react'
import { artistFormSaveStore } from '../../../../stores/CRM/ArtistCRM/artistForm.store'
import { artistModerationStore } from '../../../../stores/CRM/AdminCRM/artists/artistModeration.store'
import { artistFormNotificationsStore } from '../../../../stores/CRM/ArtistCRM/artistFormNotifications.store'
import { ReactComponent as PDFIcon } from '../../../../assets/icons/pdf.svg'
import classNames from 'classnames'

interface PropTypes {
  requestStatus?: artistFormStatuses;
}

interface State {
  saveTime?: Date;
  showFixedHeader: boolean;
  isFixedHeaderFading: boolean;
}

const statuses: {[key in artistFormStatuses]: string} = {
  NEW: 'На модерации',
  EDITING: 'Заполняется артистом',
  MODERATION: 'На модерации',
  APPROVED: 'Утверждена',
  // Требования заказчика оставить надпись утверждена
  CHECKED: 'Утверждена'
}

@observer
class ArtistHeaderContainer extends React.Component<RouteComponentProps & PropTypes, State> {
  private headerFadeTimeout?: number;
  private currentScroll = 0;

  state = {
    saveTime: undefined,
    showFixedHeader: false,
    isFixedHeaderFading: false
  }

  onBack = (): void => {
    this.props.history.goBack()
  }

  onSave = (): void => {
    const form = document.getElementById('artist-form')
    form && form.dispatchEvent(new Event('submit', { cancelable: true }))
  }

  checkScroll = (): void => {
    if (window.scrollY > 300 && this.currentScroll <= 300) {
      this.currentScroll = window.scrollY
      this.setState({
        showFixedHeader: true
      })
    } 
    
    if (this.currentScroll >= 300 && window.scrollY < 300) {
      this.currentScroll = window.scrollY
      this.setState({
        isFixedHeaderFading: true
      })

      this.headerFadeTimeout = window.setTimeout(() => {
        this.setState({
          showFixedHeader: false,
          isFixedHeaderFading: false
        })
      }, 200)
    }
  }

  componentDidMount(): void {
    window.addEventListener('scroll', this.checkScroll)
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.checkScroll)
    clearTimeout(this.headerFadeTimeout)
  }

  render(): JSX.Element {
    const role = localStorage.getItem('userRole')
    
    return (
      <>
      <FormHeaderComponent
        onSave={this.onSave}
        requestStatus={statuses[artistModerationStore.formStatus || 'EDITING']}
        saveTime={artistModerationStore.formStatus === 'EDITING' ? artistFormSaveStore.saveTime : undefined}
        isStatic={true}
        isHeaderFading={false}
        onBack={this.onBack}
        hasSaveButton={
          artistModerationStore.formStatus === 'EDITING' 
            && !artistFormNotificationsStore.notificationsData?.notifications.length
        }
        title="АНКЕТА УЧАСТНИКА ФЕСТИВАЛЯ UMN"
        hasExitButton={true}
        hasBackButton={role !== 'ARTIST'}
        requestStatusTitle="Статус заявки"
      >
        {role === 'ARTIST' && (
          <>
          <div className={classNames(`form-header__text`, 'margin margin--top-xs')}>
            Внимательно заполните все поля и получите шанс стать участником фестиваля UMN. <br/>
            Только корректно и полностью заполненные анкеты попадают на модерацию и рассматриваются организаторами.
          </div>
          <div className={classNames(`form-header__selection-policy`, 'margin margin--top-s')}>
            <div className={`form-header__selection-policy-icon`}>
              <PDFIcon />
            </div>
            <a 
              // eslint-disable-next-line max-len
              href="https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2F2aUfy2p4fwmWk8Ew%2FJeBWReR6AD7QKjZbqmQwpd0%2FvaYfaO8cAVqf3AGvYc6LwYZMrZMrpRHvcl9PkWNPWglZQ%3D%3D&name=%D0%9F%D0%BE%D1%80%D1%8F%D0%B4%D0%BE%D0%BA%20%D0%BE%D1%82%D0%B1%D0%BE%D1%80%D0%B0%20%D0%B7%D0%B0%D1%8F%D0%B2%D0%BE%D0%BA%20%D0%9D%D0%BE%D1%87%D1%8C%20%D0%9C%D1%83%D0%B7%D1%8B%D0%BA%D0%B8%202022.PDF&nosw=1"
              className={`form-header__selection-policy-text`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Порядок отбора на фестиваль
            </a>
          </div>
          </>
        )}
      </FormHeaderComponent>
      {this.state.showFixedHeader && artistModerationStore.formStatus === 'EDITING' && (
        <FormHeaderComponent
          onSave={this.onSave}
          requestStatus={statuses[artistModerationStore.formStatus || 'EDITING']}
          saveTime={artistModerationStore.formStatus === 'EDITING' ? artistFormSaveStore.saveTime : undefined}
          isStatic={false}
          isHeaderFading={this.state.isFixedHeaderFading}
          hasSaveButton={
            artistModerationStore.formStatus === 'EDITING' 
              && !artistFormNotificationsStore.notificationsData?.notifications.length
          }
          title="АНКЕТА УЧАСТНИКА ФЕСТИВАЛЯ UMN"
          hasExitButton={true}
          requestStatusTitle="Статус заявки"
        />
      )}
      </>
    )
  }
}

export default withRouter(ArtistHeaderContainer)
import React from 'react'
import { ReactComponent as Loader } from "../../../assets/icons/loading.svg"

import './Loader.style.sass'

const block = "loader"

interface PropTypes {
  width: number;
  height: number;
}

export default class LoaderComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { width, height } = this.props

    return (
      <div className={block}>
        <Loader fill="#933ec5" width={width} height={height} />
      </div>
    )
  }
}
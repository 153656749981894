import React, { ReactNode } from 'react'

import './InputLabel.style.sass'
import classNames from 'classnames'

interface PropTypes {
  children: React.ReactNode;
  isFocused?: boolean;
}

const block = 'input-label'

export default class InputLabelComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    return (
      <div className={classNames(
        block,
        this.props.isFocused && `${block}--focused`
      )}>
        {this.props.children}
      </div>
    )
  }
}

import { appConfig } from "../const/appConfig"
import { UserRole } from "../models/common/user.model"

const authTokenKey = appConfig.localStorage.authTokenStorageName
const userRoleKey = appConfig.localStorage.userRoleStorageName
const userIdKey = appConfig.localStorage.userProfileIdStorageName
const userEmailKey = appConfig.localStorage.userEmailStorageName

// AuthToken
export const getAuthToken = (): string | null => localStorage.getItem(authTokenKey)

export const setAuthToken = (token: string): void => {
  localStorage.setItem(authTokenKey, token)
}

export const removeAuthToken = (): void => {
  localStorage.removeItem(authTokenKey)
}

// UserRole
export const getUserRole = (): UserRole | null => localStorage.getItem(userRoleKey) as UserRole | null

export const setUserRole = (userRole: string): void => {
  localStorage.setItem(userRoleKey, userRole)
}

export const removeUserRole = (): void => {
  localStorage.removeItem(userRoleKey)
}

// UserEmail
export const getUserEmail = (): string | null => localStorage.getItem(userEmailKey)

export const setUserEmail = (userEmail: string): void => {
  localStorage.setItem(userEmailKey, userEmail)
}

export const removeUserEmail = (): void => {
  localStorage.removeItem(userEmailKey)
}

// UserID
export const getUserID = (): string | null => localStorage.getItem(userIdKey)

export const setUserID = (userID: string): void => {
  localStorage.setItem(userIdKey, userID)
}

export const removeUserID = (): void => {
  localStorage.removeItem(userIdKey)
}

// Common
export const clearLocalStorage = (): void => {
  localStorage.clear()
}
import React from 'react'
import { ReactComponent as Arrow } from '../../../../../assets/icons/arrow.svg'
import { ReactComponent as Filter } from '../../../../../assets/icons/filter.svg'
import CircleButtonComponent from '../../../common/CircleButton/CircleButton.component'
import PerformanceComponent from '../Performance/Performance.component'
import LoaderComponent from '../../../../common/Loader/Loader.component'
import { 
  PerformanceArtistModel, 
  PerformanceArtistFilterModel, 
  TimelineGetResponse,
  PerfomanceSaveRequest,
  ScheduleTimelineItem
} from '../../../../../models/ScenesModels/performances.model'
import ArtistsFilterComponent from '../ArtistsFilter'
import SearchInputContainer from '../../../../../containers/SearchInput/SearchInput.container'
import LazyLoad from 'react-lazyload'
import LazyLoadComponent from '../../../common/LazyLoad/LazyLoad.component'
import { RequestError } from '../../../../../models/common/error.model'
import RequestErrorModalComponent from '../../../common/RequestErrorModal/RequestErrorModal.component'

import './PerformanceArtists.style.sass'
import classNames from 'classnames'

interface PropTypes {
  onBackClick: () => void;
  onSearchChange: (value: string) => void;
  onFilterToggle: (isVisible?: boolean) => void;
  isArtistsLoading: boolean;
  artistsData?: PerformanceArtistModel[];
  onFilterApply: (values: PerformanceArtistFilterModel) => void;
  onFilterReset: () => void;
  filterValues?: PerformanceArtistFilterModel;
  isFilterVisible: boolean;
  onLoadMore: () => void;
  shouldLoadMore: boolean;
  onArtistOpen: (id: number) => void;
  onArtistClose: () => void;
  visibleTimelineId?: number;
  isTimelineLoading: boolean;
  timelineData?: TimelineGetResponse;
  onPerformanceCreate: (values: PerfomanceSaveRequest, artistId: number) => void;
  isPerformanceCreating: boolean;
  requestErrors?: RequestError[];
  onErrorsModalClose: () => void;
  onEyeClick: (artistFormId: number | null, artistId: number) => void;
  currentScheduleData?: ScheduleTimelineItem[];
}

const block = 'perfomance-artists'

export default class PerformanceArtistsComponent extends React.Component<PropTypes> {
  onLoadMore = (): void => {
    !this.props.isArtistsLoading && this.props.onLoadMore()
  }

  render(): JSX.Element {
    const { 
      onBackClick,
      onSearchChange,
      onFilterToggle,
      isArtistsLoading,
      artistsData,
      filterValues,
      onFilterApply,
      onFilterReset,
      isFilterVisible,
      shouldLoadMore,
      onArtistOpen,
      onArtistClose,
      visibleTimelineId,
      isTimelineLoading,
      timelineData,
      onPerformanceCreate,
      isPerformanceCreating,
      requestErrors,
      onErrorsModalClose,
      onEyeClick,
      currentScheduleData
    } = this.props

    return (
      <div className={block}>
        <div className={`${block}__title`}>
          <div className={`${block}__back`} onClick={onBackClick}>
            <Arrow fill="#000" />
          </div>
          ДОБАВЛЕНИЕ АРТИСТОВ
        </div>

        <div className={`${block}__controls margin--top-s`}>
          <SearchInputContainer
            onSearchChange={onSearchChange}
          />
          <div className={`${block}__filter`}>
            <CircleButtonComponent onClick={(): void => onFilterToggle()}>
              <Filter fill="#FFFFFF" />
            </CircleButtonComponent>

            <div className={`${block}__filter-hint`}>
              Дополнительные параметры поиска
            </div>

            {isFilterVisible && (
              <div className={classNames(
                `${block}__filter-form`,
                !currentScheduleData?.length && `${block}__filter-form--right-sided`
              )}>
                <ArtistsFilterComponent 
                  formInitialValues={filterValues}
                  onFilterApply={onFilterApply}
                  onFilterReset={onFilterReset}
                  onClose={(): void => onFilterToggle(false)} 
                />
              </div>
            )}
          </div>
        </div>

        <div className={`${block}__list margin--top-l`}>
          {isArtistsLoading && !artistsData?.length && (
            <div className={`${block}__loader`}>
              <LoaderComponent width={100} height={100} />
            </div>
          )}

          {artistsData && artistsData.length ? (
            <>
            {artistsData?.map((artist): JSX.Element => (
              <PerformanceComponent 
                key={artist.id}
                name={artist.name}
                image={artist.avatar || ''}
                isTimelineVisible={visibleTimelineId === artist.id}
                isTimelineLoading={isTimelineLoading}
                timelineData={timelineData}
                onSubmit={(values: PerfomanceSaveRequest): void => onPerformanceCreate(values, artist.id)}
                isSubmitLoading={isPerformanceCreating}
                type="artist"
                onEditClick={(): void => {
                  if (visibleTimelineId === artist.id) {
                    onArtistClose()
                  } else {
                    onArtistOpen(artist.id)
                  }
                }}
                onEyeClick={(): void => onEyeClick(artist.formId, artist.id)}
              />
            ))}
            {shouldLoadMore && (
              <LazyLoad height={10} unmountIfInvisible={true}>
                <LazyLoadComponent isLoading={isArtistsLoading} onMount={this.onLoadMore} />
              </LazyLoad>
            )}
            </>
          ) : !isArtistsLoading && (
            <div>
              Список пуст
            </div>
          )}
        </div>

        <RequestErrorModalComponent 
          requestErrors={requestErrors}
          onModalClose={onErrorsModalClose}
        />
      </div>
    )
  }
}
import { observer } from 'mobx-react'
import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router'
import 
  SelectionExpertEvaluationComponent 
from 
  '../../../components/CRM/SelectionExpertCRM/SelectionExpertEvaluation/SelectionExpertEvaluation.component'
import { PATH_SELECTION_EXPERT_DASHBOARD } from '../../../const/routes.const'
import { RequestErrorResponse } from '../../../models/common/error.model'
import { getSelectionExpertArtistData } from '../../../services/CRM/SelectionExpertCRM/evaluation.service'
import { selectionExpertArtistGetStore } from '../../../stores/CRM/SelectionExpertCRM/selectionExpertEvaluation.store'

const SelectionExpertEvaluationContainer: FC = () => {
  const history = useHistory()

  useEffect(() => {
    getSelectionExpertArtistData().catch((error) => {
      const errorResponseData: RequestErrorResponse | undefined = error.response?.data

      if (errorResponseData?.errors[0].code === 'Not found') {
        history.push(PATH_SELECTION_EXPERT_DASHBOARD)
      }
    })

    return (): void => {
      selectionExpertArtistGetStore.clearData()
    }
  }, [])

  const goBack = (): void => {
    history.push(PATH_SELECTION_EXPERT_DASHBOARD)
  }

  const {
    isLoading,
    artist
  } = selectionExpertArtistGetStore

  return (
    <SelectionExpertEvaluationComponent 
      isLoading={isLoading} 
      artistData={artist} 
      onBackClick={goBack}
    />
  )
}

export default observer(SelectionExpertEvaluationContainer)
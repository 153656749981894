import axios from "axios"
import { setAuthToken } from "./localStorage"

const localStorageAuthToken = localStorage.getItem("authToken")

function getApiUrl(): string {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "development":
      return "https://test.api.umn.2tapp.cc/api/v1/"
    case "production":
      return "https://api.uralmusicnight.ru/api/v1/"
    case "test":
      return "https://test.api.umn.2tapp.cc/api/v1/"
    default:
      return "https://api.uralmusicnight.ru/api/v1/"
  }
}

export const api = axios.create({
  baseURL: getApiUrl(),
  headers: {
    secret: "aNDYcoTOLZKjukb-iOag9Jm3DZ9uqUS0erYUqxfif80tp_UAzsFbgEK05AgLl91x",
    Authorization: localStorageAuthToken && `JWT ${localStorageAuthToken}`
  }
})

export const setToken = (token: string): void => {
  setAuthToken(token)

  api.defaults.headers['Authorization'] = `JWT ${token}`
}

export const deleteToken = (): void => {
  localStorage.removeItem('authToken')

  delete api.defaults.headers['Authorization']
}
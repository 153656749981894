import React from 'react'
import CircleButtonComponent from '../CircleButton/CircleButton.component'
import { ReactComponent as PlusIcon } from '../../../../assets/icons/icon-plus.svg'

import './AddElement.style.sass'

interface PropTypes {
  onClick: () => void;
  text: string;
  rightSideButton?: boolean;
}

const block = 'add-element'

export class AddElementComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { onClick, text } = this.props

    return (
      <div className={block}>
        <CircleButtonComponent onClick={onClick}>
          <PlusIcon fill="#FFFFFF" />
        </CircleButtonComponent>
        <div className={`${block}__add-block-text`}>
          {text}
        </div>
      </div>
    )
  }
}
import React from 'react'
import { observer } from 'mobx-react'
import UploadFileComponent from '../../../components/CRM/common/UploadFile/UploadFile.component'
import { uploadFile } from '../../../services/common/uploadFile.service'
import { fileUploadStore } from '../../../stores/common/fileUpload.store'
import { FileUploadResponse } from '../../../models/common/fileUpload.model'

interface PropTypes {
  nameForStore: string;
  title?: string;
  fileTypes?: string[];
  onFileUpload?: (response: FileUploadResponse) => void;
  onFileDelete?: () => void;
  uploadedFile?: FileUploadResponse;
  showFileName: boolean;
  minWidth?: number;
  minHeight?: number;
  disabled?: boolean;
  userName?: string;
  documentType?: string;
}

@observer
export default class UploadFileContainer extends React.Component<PropTypes> {
  constructor(props: PropTypes) {
    super(props)
    fileUploadStore.registration(this.props.nameForStore)
  }

  state = {
    isImage: false,
    error: ''
  }

  addImageProcess(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = (): void => resolve(img)
      img.onerror = reject
      img.src = src
    })
  }

  onDelete = (event: React.SyntheticEvent<HTMLElement>): void => {
    event.stopPropagation()

    if (!this.props.disabled) {
      fileUploadStore.removeUploadedFileData(this.props.nameForStore)
      this.props.onFileDelete && this.props.onFileDelete()
    }
  }

  onDrop = (file: File[]): void => {
    const fileName = this.props.userName && this.props.documentType && (
      `${this.props.userName}_${this.props.documentType}`
    )
    const hasFile = file.length > 0
    const isImage = hasFile && (file[0].type === 'image/jpeg' || file[0].type === 'image/png')

    // if (!isImage) {
    //   this.setState({
    //     error: 'Неверный формат файла'
    //   })

    //   return
    // }

    if (file[0].size > 30 * 1024 * 1024) {
      this.setState({
        error: 'Размер изображения превышает допустимый (30мб)'
      })

      return
    }

    if (isImage) {
      const url = URL.createObjectURL(file[0])
      this.addImageProcess(url).then((img) => {
        if (this.props.minHeight && this.props.minWidth) {
          if (img.width >= this.props.minWidth && img.height >= this.props.minHeight) {
            uploadFile(file, this.props.nameForStore, fileName).then(this.props.onFileUpload)
            this.setState({
              isImage,
              error: ''
            })
          } else {
            this.setState({
              error: 'Разрешение фотографии меньше, чем необходимо'
            })
          }
        }
      })
    } else {
      uploadFile(file, this.props.nameForStore, fileName).then(this.props.onFileUpload)
      this.setState({
        isImage,
        error: ''
      })
    }
  }

  componentWillUnmount(): void {
    fileUploadStore.unmount(this.props.nameForStore)
  }

  render(): JSX.Element {
    const { nameForStore, title, fileTypes, uploadedFile, showFileName, disabled } = this.props

    return (
      <UploadFileComponent
        onDrop={this.onDrop}
        uploadedFileState={fileUploadStore.getItem(nameForStore)}
        uploadedFile={uploadedFile}
        title={title}
        fileTypes={fileTypes || ['']}
        isImage={this.state.isImage}
        showFileName={showFileName}
        error={this.state.error}
        onDelete={this.onDelete}
        disabled={disabled}
      />
    )
  }
}

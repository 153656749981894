import React from 'react'
import PlatformExpertsListComponent from '../../../../../components/CRM/AdminCRM/PlatformModeration/PlatformExpertsList'
import { getSceneExperts, getPlatformExperts } from '../../../../../services/CRM/AdminCRM/scenes/experts.service'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { PATH_EXPERTS } from '../../../../../const/routes.const'
import { sceneExpertsStore, platformExpertsStore } from '../../../../../stores/CRM/AdminCRM/scenes/experts.store'
import { observer } from 'mobx-react'

interface PropTypes {
  isSceneExperts: boolean;
}

@observer
class PlatformExpertsListContainer 
extends React.Component<PropTypes & RouteComponentProps<{platformId: string; sceneId: string}>> {

  componentDidMount(): void {
    const platformId = parseInt(this.props.match.params.platformId)
    const sceneId = parseInt(this.props.match.params.sceneId)

    if (this.props.isSceneExperts && sceneId) {
      getSceneExperts(sceneId)
    } else if (platformId) {
      getPlatformExperts(platformId)
    }
  }

  componentWillUnmount(): void {
    if (this.props.isSceneExperts) {
      sceneExpertsStore.clearData()
    } else {
      platformExpertsStore.clearData()
    }
  }

  goToExpert = (id: number): void => {
    this.props.history.push(`${PATH_EXPERTS}/${id}`)
  }

  render(): JSX.Element {
    return (
      <PlatformExpertsListComponent 
        experts={
          this.props.isSceneExperts ? (
            sceneExpertsStore.experts
          ) : (
            platformExpertsStore.experts
          )
        } 
        isLoading={
          this.props.isSceneExperts ? (
            sceneExpertsStore.isLoading
          ) : (
            platformExpertsStore.isLoading
          )
        } 
        onExpertClick={this.goToExpert}
      />
    )
  }
}

export default withRouter(PlatformExpertsListContainer)
import {  deserialize, deserializeAs } from 'cerialize'

export class ListSceneModel {
  @deserialize id: number;
  @deserialize name: string;

  constructor(id: number, name: string) {
    this.id = id
    this.name = name
  }
}

export class ScenesListGetResponse {
  @deserializeAs('place_forms') placeForms?: ListSceneModel[];
}
import { api } from "../../../../utils/api"
import { RequestErrorResponse } from "../../../../models/common/error.model"
import { Serialize, Deserialize } from "cerialize"
import { FileGroupsGetResponse, FileGroupsGetRequest } from "../../../../models/FilesModels/TechRiders.model"
import { fileGroupsStore } from "../../../../stores/CRM/AdminCRM/files/fileGroups.store"
import { PATH_RIDERS_ARTISTS, PATH_RIDERS_SCENES, PATH_RIDERS_COMMON } from "../../../../const/routes.const"

const getFileGroupsRequestUrl = (pathname): string => {
  switch(pathname) {
    case PATH_RIDERS_ARTISTS:
      return '/crm/admin/artists_tech_riders/'
    case PATH_RIDERS_SCENES:
      return '/crm/admin/places_tech_riders/'
    case PATH_RIDERS_COMMON:
      return '/crm/admin/artists_hospitality_riders/'
    default:
      return ''
  }
}

export function getFilesGroupList(request: FileGroupsGetRequest, pathname): Promise<void> {
  const serializedData = Serialize(request, FileGroupsGetRequest)

  fileGroupsStore.setIsLoading(true)

  return api.get(
    getFileGroupsRequestUrl(pathname),
    {params: serializedData}
  ).then((res) => {
    const data = Deserialize(res.data, FileGroupsGetResponse)
    fileGroupsStore.setFileGroupData(data)
    fileGroupsStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    fileGroupsStore.setIsLoading(false)
    fileGroupsStore.setErrors(errorResponseData.errors)
    throw error
  })
}
import { action, observable } from 'mobx'
import { AdminExports } from '../../../../models/AdminExportsModels/adminExports.model'
import { ApiRequestStore } from '../../../common/apiRequest.store'

class AdminExportsStore extends ApiRequestStore {
  @observable exports?: AdminExports
  @observable export?: {url: string}
  @observable exportsLoadingSet: Set<string> = new Set()

  @action
  setExportsData(data: AdminExports): void {
    this.exports = data
  }

  @action
  setExportByNameData(data: {url: string}): void {
    this.export = data
  }

  @action
  setIsExportInLoading(name: string): void {
    
    if (this.exportsLoadingSet.has(name)) {
      this.exportsLoadingSet.delete(name)
    } else {
      this.exportsLoadingSet.add(name)
    }
  }

  
}

export const adminExportsStore: AdminExportsStore = new AdminExportsStore()
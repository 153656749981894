import { ReactComponent as RadioIcon } from '../../../../assets/icons/radio.svg'
import { ReactComponent as Disk } from '../../../../assets/icons/disk.svg'
import { ReactComponent as Mic } from '../../../../assets/icons/mic.svg'
import { ReactComponent as Piano } from '../../../../assets/icons/piano.svg'
import { ReactComponent as Drums } from '../../../../assets/icons/drums.svg'
import { ReactComponent as Guitar } from '../../../../assets/icons/guitar.svg'
import { ReactComponent as Sax } from '../../../../assets/icons/sax.svg'
import { ReactComponent as Metal } from '../../../../assets/icons/metal.svg'
import { ReactComponent as FB } from '../../../../assets/icons/fb.svg'
import { ReactComponent as Instagram } from '../../../../assets/icons/instagram.svg'
import { ReactComponent as VK } from '../../../../assets/icons/vk.svg'
import { ReactComponent as DJ } from '../../../../assets/icons/dj.svg'
import { ReactComponent as Twitter } from '../../../../assets/icons/twitter.svg'
import { ReactComponent as Tiktok } from '../../../../assets/icons/tiktok.svg'
import { ReactComponent as Spotify } from '../../../../assets/icons/spotify-icon.svg'
import { ReactComponent as Dance } from '../../../../assets/icons/dance.svg'

export interface GenreRadioButtonsData {
  value: string;
  caption: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export interface SocialNetworksField {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name: string;
  placeholder: string;
}

export interface FileUploadInputField {
  fieldName: string;
  title: string;
}

export const genreRadioButtonsData: GenreRadioButtonsData[] = [
  {
    value: 'hiphop',
    caption: 'Хип-Хоп',
    icon: RadioIcon
  },
  {
    value: 'electro',
    caption: 'Электроника',
    icon: Disk
  },
  {
    value: 'pop',
    caption: 'Поп',
    icon: Mic
  },
  {
    value: 'classic',
    caption: 'Классика',
    icon: Piano
  },
  {
    value: 'folk',
    caption: 'Фолк',
    icon: Drums
  },
  {
    value: 'rock',
    caption: 'Рок',
    icon: Guitar
  },
  {
    value: 'jazz',
    caption: 'Джаз',
    icon: Sax
  },
  {
    value: 'metal',
    caption: 'Метал',
    icon: Metal
  },
  {
    value: 'dance',
    caption: 'Танцы',
    icon: Dance
  },
  {
    value: 'dj',
    caption: 'DJ',
    icon: DJ
  }
]

export const projectInSocial: SocialNetworksField[] = [
  {
    name: 'facebookUrl',
    icon: FB,
    placeholder: 'Facebook'
  },
  {
    name: 'instagramUrl',
    icon: Instagram,
    placeholder: 'Instagram'
  },
  {
    name: 'vkontakteUrl',
    icon: VK,
    placeholder: 'Vkontakte'
  },
  {
    name: 'tiktokUrl',
    icon: Tiktok,
    placeholder: 'Tiktok'
  },
  {
    name: 'spotifyUrl',
    icon: Spotify,
    placeholder: 'Spotify'
  }
]

export const memberSocial: SocialNetworksField[] = [
  {
    name: 'facebookUrl',
    icon: FB,
    placeholder: 'Facebook'
  },
  {
    name: 'instagramUrl',
    icon: Instagram,
    placeholder: 'Instagram'
  },
  {
    name: 'vkontakteUrl',
    icon: VK,
    placeholder: 'Vkontakte'
  },
  {
    name: 'twitterUrl',
    icon: Twitter,
    placeholder: 'Twitter'
  }
]

export const photosUploadFields: FileUploadInputField[] = [
  {
    fieldName: 'photoSite',
    title: 'На сайт'
  },
  {
    fieldName: 'photoSiteHeader',
    title: 'В шапку сайта'
  },
  {
    fieldName: 'photoSocial',
    title: 'Для соц.сетей'
  },
  {
    fieldName: 'photoAffiche',
    title: 'Для афиши'
  }
]

import { deserialize, deserializeAs } from "cerialize"

export class FileUploadResponse {
  @deserialize name: string;
  @deserialize url: string;
  @deserialize id: number;
  @deserializeAs('url_s') urlSizeS?: string;
  @deserializeAs('url_m') urlSizeM?: string;
  @deserializeAs('url_l') urlSizeL?: string;

  constructor(name: string, url: string, id: number) {
    this.name = name
    this.url = url
    this.id = id
  }
}

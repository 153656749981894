import React from "react"
import { Link, RouteComponentProps } from "react-router-dom"
import classNames from "classnames"

import { ReactComponent as UmnLogo } from "../../../../assets/icons/logo-umn.svg"
import { ReactComponent as LiActiveCurve } from "../../../../assets/icons/curve.svg"

import { nav } from "../nav.const"
import "./SideBar.style.sass"

const block = "nav-sidebar"

class SideBar extends React.Component<RouteComponentProps> {
  render(): JSX.Element {
    const {
      location: { pathname }
    } = this.props

    const currentPathnameCategory = pathname.split("/")[1]
    const userRole = localStorage.getItem('userRole')

    return (
      <div className={block}>
        <div className={`${block}__logo`}>
          <UmnLogo />
        </div>
        <nav>
          {nav.map(item => (
            userRole && item.roles.indexOf(userRole) >= 0 && (
              <Link to={`/${item.pathnameCategory}`} key={item.pathnameCategory}>
                <div
                  className={classNames(
                    `${block}__item ${
                      currentPathnameCategory === item.pathnameCategory ? "nav-sidebar__item--active" : ""
                    }`
                  )}
                >
                  <div className={`${block}__item-icon`}>
                    {React.createElement(item.icon)}
                  </div>
                  <p>{item.title}</p>
                  {currentPathnameCategory === item.pathnameCategory && (
                    <LiActiveCurve className={`${block}__item--decorative`} />
                  )}
                </div>
              </Link>
            )
          ))}
        </nav>
      </div>
    )
  }
}

export default SideBar

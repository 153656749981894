import { autoserialize, autoserializeAs } from 'cerialize'
import { FileUploadResponse } from '../common/fileUpload.model'

export class ListExpertModel {
  @autoserialize id: number;
  @autoserialize email: string;
  @autoserializeAs(FileUploadResponse) photo?: FileUploadResponse;
  @autoserialize name: string | null;

  constructor(
    id: number,
    email: string,
    photo: FileUploadResponse,
    name: string | null
  ) {
    this.id = id
    this.email = email
    this.photo = photo
    this.name = name
  }
}

export class ExpertListGetResponse {
  @autoserializeAs(ListExpertModel) experts: ListExpertModel[]

  constructor(experts: ListExpertModel[]) {
    this.experts = experts
  }
}
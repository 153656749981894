import { action, observable } from "mobx"

class PerformanceUIStore {
  @observable activePerformanceId?: number;

  @action
  setActivePerformanceId(id?: number): void {
    this.activePerformanceId = id
  }
}

export const performanceUIStore: PerformanceUIStore = new PerformanceUIStore()
import { deserialize, deserializeAs } from 'cerialize'

export class UserModel {
  @deserialize email: string;
  @deserializeAs('first_name') firstName?: string;
  @deserializeAs('last_name') lastName?: string;
  @deserializeAs('profile_id') profileId: string;
  @deserializeAs('crm_role') crmRole: UserRole;
  @deserialize avatar?: string;
  @deserialize provider?: string;

  constructor(
    email: string,
    firstName: string,
    lastName: string,
    profileId: string,
    crmRole: UserRole,
    avatar?: string,
    provider?: string
  ) {
    this.email = email
    this.firstName = firstName
    this.lastName = lastName
    this.profileId = profileId
    this.crmRole = crmRole
    this.avatar = avatar
    this.provider = provider
  }
}

export type UserRole = 'ARTIST' | 'ADMIN' | 'EXPERT' | 'SELECTION_EXPERT'

import React from 'react'
import { ReactComponent as Arrow } from '../../../../../assets/icons/arrow.svg'
import { ReactComponent as Doc } from '../../../../../assets/icons/doc.svg'
import { FileGroupsPlace } from '../../../../../models/FilesModels/TechRiders.model'
import { FileUploadResponse } from '../../../../../models/common/fileUpload.model'

import './FilesGroup.style.sass'

interface PropTypes {
  filesGroup: FileGroupsPlace;
  onDownloadClick: (files: FileUploadResponse[]) => void;
}

const block = 'files-group'

export default class FilesGroupComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { filesGroup, onDownloadClick } = this.props
 
    return (
      <div className={block}>
        <div className="row row--space-between row--align-center">
          <div className={`${block}__title`}>
            {filesGroup.name}
          </div>
          <div onClick={(): void => onDownloadClick(filesGroup.files)} className={`${block}__download`}>
            <div className={`${block}__download-text`}>
              Скачать все
            </div>
            <div className={`${block}__download-icon`}>
              <Arrow width="15" height="15" fill="#ffffff" />
            </div>
          </div>
        </div>

        <div className={`${block}__file-list`}>
          {filesGroup.files.map(file => (
            <div 
              className={`${block}__file-list-item`} 
              key={file.id} 
            >
              <div className={`${block}__doc-icon`}>
                <Doc />
              </div>
              <a 
                href={file.url} 
                rel="noopener noreferrer" 
                target="_blank" 
                className={`${block}__file-name`}
                data-extension={file.name.substr(file.name.lastIndexOf('.') + 1)}
              >
                {file.name}
              </a>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
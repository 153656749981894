import { StyleSheet } from '@react-pdf/renderer'

export const pdfFormStyles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: 15,
    fontFamily: 'StolzlRegular'
  },
  section: {
    marginTop: 20
  },
  noMargin: {
    margin: 0
  },
  title: {
    fontFamily: 'StolzlMedium',
    color: '#933ec5',
    letterSpacing: 1,
    fontSize: 15,
    fontWeight: 500,
    paddingLeft: 26,
    textTransform: 'uppercase'
  },
  formBlock: {
    marginTop: 20,
    paddingVertical: 15,
    paddingHorizontal: 26,
    backgroundColor: '#f5f8f9',
    borderRadius: 20
  },
  inputTitle: {
    fontSize: 11,
    letterSpacing: 1
  },
  input: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
    minHeight: 35,
    borderRadius: 7,
    paddingVertical: 7,
    paddingHorizontal: 20,
    marginTop: 7,
    backgroundColor: '#ffffff'
  },
  inputWithImage: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap'
  },
  textarea: {
    height: 80,
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  inputText: {
    textAlign: 'left',
    fontSize: 11,
    flexWrap: 'wrap',
    flexShrink: 1
  },
  inputBlock: {
    marginTop: 20
  },
  inputImage: {
    width: 22,
    height: 22,
    marginRight: 10
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  genre: {
    marginTop: 7,
    display: 'flex',
    flexDirection: 'column'
  },
  genreCaption: {
    marginTop: 7,
    fontSize: 8
  },
  genreIcon: {
    width: 35,
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignSelf: 'center'
  },
  genreCaptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  rowInput: {
    width: '47%'
  },
  marginedInput: {
    marginTop: 20
  },
  separator: {
    height: 1,
    marginTop: 20,
    backgroundColor: '#ebebeb'
  },
  photoInput: {
    marginTop: 20
  },
  photoInputTitle: {
    fontSize: 8
  },
  photoInputImageWrapper: {
    width: 100,
    height: 73,
    marginTop: 15,
    borderRadius: 5,
    backgroundColor: '#ffffff'
  },
  photoInputImage: {
    width: '100%',
    height: '100%',
    borderRadius: 5
  },
  techRider: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 7
  },
  techRiderImage: {
    width: 9,
    marginRight: 7,
    flexShrink: 0
  },
  techRiderText: {
    fontSize: 8,
    color: '#933ec5'
  }
})
import { ReactComponent as Artist } from "../../../assets/icons/artist.svg"
import { ReactComponent as Loc } from "../../../assets/icons/loc.svg"
import { ReactComponent as Expert } from "../../../assets/icons/user2.svg"
import { ReactComponent as Folder } from "../../../assets/icons/folder.svg"
import { ReactComponent as Music } from "../../../assets/icons/music.svg"

export const nav = [
  { title: "Профиль", icon: Expert, pathnameCategory: "profile", roles: ['EXPERT']},
  { title: "Артисты", icon: Artist, pathnameCategory: "artists", btnText: "Новый артист", roles: ["ADMIN"] },
  { title: "Площадки", icon: Loc, pathnameCategory: "scenes", btnText: "Новая сцена", roles: ['ADMIN', 'EXPERT'] },
  { title: "Координаторы", icon: Expert, pathnameCategory: "experts", btnText: "Новый эксперт", roles: ['ADMIN'] },
  { title: "Файлы", icon: Folder, pathnameCategory: "files", roles: ['ADMIN']},
  { 
    title: "Пересечения по музыкантам", 
    icon: Music, 
    pathnameCategory: "artists-intersections", 
    roles: ['ADMIN']
  }
]

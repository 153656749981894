import { deserializeAs, deserialize, serialize, serializeAs, autoserializeAs } from 'cerialize'
import { PlatformStatus } from './platformList.model'
import { FileUploadResponse } from '../common/fileUpload.model'
import { CommentModel } from '../ArtistFormModels/artistForm.model'
import { SceneGenreModel } from './scene.model'

export interface MapCoordModel {
  longitude: number;
  latitude: number;
}

export class NumbersModel {
  @deserializeAs('posters_a4') @serializeAs('posters_a4') postersA4: string;
  @deserializeAs('posters_a3') @serializeAs('posters_a3') postersA3: string;
  @deserializeAs('posters_a2') @serializeAs('posters_a2') postersA2: string;
  @deserializeAs('posters_a1') @serializeAs('posters_a1') postersA1: string;
  @deserializeAs('posters_a0') @serializeAs('posters_a0') postersA0: string;
  @deserialize @serialize banners: string;
  @deserializeAs('flyers_a6') @serializeAs('flyers_a6') flyersA6: string;
  @deserializeAs('stretching_banners') @serializeAs('stretching_banners') stretchingBanners: string;
  @deserializeAs('table_tents_a5') @serializeAs('table_tents_a5') tableTentsA5: string;
  @deserializeAs('table_tents_a6') @serializeAs('table_tents_a6') tableTentsA6: string;

  constructor(
    postersA4: string,
    postersA3: string,
    postersA2: string,
    postersA1: string,
    postersA0: string,
    banners: string,
    flyersA6: string,
    stretchingBanners: string,
    tableTentsA5: string,
    tableTentsA6: string
  ) {
    this.postersA4 = postersA4
    this.postersA3 = postersA3
    this.postersA2 = postersA2
    this.postersA1 = postersA1
    this.postersA0 = postersA0
    this.banners = banners
    this.flyersA6 = flyersA6
    this.stretchingBanners = stretchingBanners
    this.tableTentsA5 = tableTentsA5
    this.tableTentsA6 = tableTentsA6
  }
}

export class PlatformGetResponse {
  @deserialize id: number;
  @deserialize status: PlatformStatus;
  @deserializeAs(CommentModel) comments: CommentModel[];
  @deserializeAs('sent_to_moderation_at') sentToModerationAt: string;
  @deserializeAs('moderated_at') moderatedAt: string | null;
  @deserializeAs('approved_at') approvedAt: string | null;
  @deserializeAs('checked_at') checkedAt: string | null;
  @deserializeAs('created_at') createdAt: string | null;
  @deserializeAs('updated_at') updatedAt: string | null;
  @deserialize name: string | null;
  @deserializeAs('contact_email') contactEmail: string | null;
  @deserialize address: string | null;
  @deserialize coordinates: MapCoordModel | null;
  @deserialize site: string | null;
  @deserialize phone: string | null;
  @deserializeAs('facebook_url') facebookUrl: string | null;
  @deserializeAs('instagram_url') instagramUrl: string | null;
  @deserializeAs('vkontakte_url') vkontakteUrl: string | null;
  @deserializeAs('telegram_url') telegramUrl: string | null;
  @deserializeAs('share_social') shareSocial: boolean;
  @deserialize description: string | null;
  @deserializeAs('contact_name') contactName: string | null;
  @deserializeAs('contact_phone') contactPhone: string | null;
  @deserializeAs('contact_telegram_url') contactTelegramUrl: string | null;
  @deserializeAs('contact_facebook_url') contactFacebookUrl: string | null;
  @deserializeAs('contact_instagram_url') contactInstagramUrl: string | null;
  @deserializeAs('contact_vkontakte_url') contactVkontakteUrl: string | null;
  @deserializeAs('contact_twitter_url') contactTwitterUrl: string | null;
  @deserializeAs('photo_site') photoSite: FileUploadResponse | null;
  @deserializeAs('photo_site_2') photoSite2: FileUploadResponse | null;
  @deserializeAs('photo_site_header') photoSiteHeader: FileUploadResponse | null;
  @deserialize logo: FileUploadResponse | null;
  @deserializeAs(NumbersModel) numbers: NumbersModel | null;
  @deserializeAs('additional_advertising_capabilities') additionalAdvertisingCapabilities: string | null;
  @deserializeAs('partner_logos') partnerLogos: Array<FileUploadResponse>;
  @deserializeAs('multimedia_screen_specification') multimediaScreenSpecification: string | null;
  @deserializeAs('equipment_capabilities') equipmentCapabilities: FileUploadResponse[];
  @deserializeAs('no_equipment') noEquipment: boolean;
  @deserialize security: string | null;
  @deserialize capacity: string | null;
  @deserializeAs('average_check') averageCheck: string | null;
  @deserialize volunteers: string[];
  @deserializeAs('partner_email_newsletter') partnerEmailNewsletter: string | null;
  @deserialize lottery: string | null;
  @autoserializeAs(SceneGenreModel, 'scene_genres') sceneGenres: SceneGenreModel[];
  @autoserializeAs('scene_name') sceneName: string;
  @autoserializeAs('scene_subgenre') sceneSubgenre: string;
  @autoserializeAs('scene_date') sceneDate: string;
  @autoserializeAs('scene_start_time') sceneStartTime: string;
  @autoserializeAs('scene_end_time') sceneEndTime: string;
  @autoserializeAs('scene_description') sceneDescription: string;
  @autoserializeAs('additional_equipment_requirements') additionalEquipmentRequirements: FileUploadResponse[];
  @autoserializeAs('no_additional_equipment') noAdditionalEquipment: boolean;

  constructor(
    id: number,
    status: PlatformStatus,
    comments: CommentModel[],
    sentToModerationAt: string,
    moderatedAt: string,
    approvedAt: string,
    checkedAt: string,
    createdAt: string,
    updatedAt: string,
    name: string,
    contactEmail: string,
    address: string,
    coordinates: MapCoordModel,
    site: string,
    phone: string,
    facebookUrl: string,
    instagramUrl: string,
    vkontakteUrl: string,
    telegramUrl: string,
    shareSocial: boolean,
    description: string,
    contactName: string,
    contactPhone: string,
    contactTelegramUrl: string,
    contactFacebookUrl: string,
    contactInstagramUrl: string,
    contactVkontakteUrl: string,
    contactTwitterUrl: string,
    photoSite: FileUploadResponse,
    photoSite2: FileUploadResponse,
    photoSiteHeader: FileUploadResponse,
    logo: FileUploadResponse,
    numbers: NumbersModel,
    additionalAdvertisingCapabilities: string,
    partnerLogos: FileUploadResponse[],
    multimediaScreenSpecification: string,
    equipmentCapabilities: FileUploadResponse[],
    noEquipment: boolean,
    security: string,
    capacity: string,
    averageCheck: string,
    volunteers: string[],
    partnerEmailNewsletter: string,
    lottery: string,
    sceneGenres: SceneGenreModel[],
    sceneName: string,
    sceneSubgenre: string,
    sceneDate: string,
    sceneStartTime: string,
    sceneEndTime: string,
    sceneDescription: string,
    additionalEquipmentRequirements: FileUploadResponse[],
    noAdditionalEquipment: boolean
  ) {
    this.id = id
    this.status = status
    this.comments = comments
    this.sentToModerationAt = sentToModerationAt
    this.moderatedAt = moderatedAt
    this.approvedAt = approvedAt
    this.checkedAt = checkedAt
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.name = name
    this.contactEmail = contactEmail
    this.address = address
    this.coordinates = coordinates
    this.site = site
    this.phone = phone
    this.facebookUrl = facebookUrl
    this.instagramUrl = instagramUrl
    this.vkontakteUrl = vkontakteUrl
    this.telegramUrl = telegramUrl
    this.shareSocial = shareSocial
    this.description = description
    this.contactName = contactName
    this.contactPhone = contactPhone
    this.contactTelegramUrl = contactTelegramUrl
    this.contactFacebookUrl = contactFacebookUrl
    this.contactInstagramUrl = contactInstagramUrl
    this.contactVkontakteUrl = contactVkontakteUrl
    this.contactTwitterUrl = contactTwitterUrl
    this.photoSite = photoSite
    this.photoSite2 = photoSite2
    this.photoSiteHeader = photoSiteHeader
    this.logo = logo
    this.numbers = numbers
    this.additionalAdvertisingCapabilities = additionalAdvertisingCapabilities
    this.partnerLogos = partnerLogos
    this.multimediaScreenSpecification = multimediaScreenSpecification
    this.equipmentCapabilities = equipmentCapabilities
    this.noEquipment = noEquipment
    this.security = security
    this.capacity = capacity
    this.averageCheck = averageCheck
    this.volunteers = volunteers
    this.partnerEmailNewsletter = partnerEmailNewsletter
    this.lottery = lottery
    this.sceneGenres = sceneGenres
    this.sceneName = sceneName
    this.sceneSubgenre = sceneSubgenre
    this.sceneDate = sceneDate
    this.sceneStartTime = sceneStartTime
    this.sceneEndTime = sceneEndTime
    this.sceneDescription = sceneDescription
    this.additionalEquipmentRequirements = additionalEquipmentRequirements
    this.noAdditionalEquipment = noAdditionalEquipment
  }
}


export class PlatformSaveRequest {
  @serialize id: number;
  @serialize status: PlatformStatus;
  @serialize name: string | null;
  @serializeAs('contact_email') contactEmail: string | null;
  @serialize address: string | null;
  @serialize coordinates: MapCoordModel | null;
  @serialize site: string | null;
  @serialize phone: string | null;
  @serializeAs('facebook_url') facebookUrl: string | null;
  @serializeAs('instagram_url') instagramUrl: string | null;
  @serializeAs('vkontakte_url') vkontakteUrl: string | null;
  @serializeAs('telegram_url') telegramUrl: string | null;
  @serializeAs('share_social') shareSocial: boolean;
  @serialize description: string | null;
  @serializeAs('contact_name') contactName: string | null;
  @serializeAs('contact_phone') contactPhone: string | null;
  @serializeAs('contact_telegram_url') contactTelegramUrl: string | null;
  @serializeAs('contact_facebook_url') contactFacebookUrl: string | null;
  @serializeAs('contact_instagram_url') contactInstagramUrl: string | null;
  @serializeAs('contact_vkontakte_url') contactVkontakteUrl: string | null;
  @serializeAs('contact_twitter_url') contactTwitterUrl: string | null;
  @serializeAs('photo_site') photoSite: FileUploadResponse | null;
  @serializeAs('photo_site_2') photoSite2: FileUploadResponse | null;
  @serializeAs('photo_site_header') photoSiteHeader: FileUploadResponse | null;
  @serialize logo: FileUploadResponse | null;
  @serializeAs(NumbersModel) numbers: NumbersModel | null;
  @serializeAs('additional_advertising_capabilities') additionalAdvertisingCapabilities: string | null;
  @serializeAs('partner_logos') partnerLogos: Array<FileUploadResponse | undefined> | [];
  @serializeAs('multimedia_screen_specification') multimediaScreenSpecification: string | null;
  @serializeAs('equipment_capabilities') equipmentCapabilities: FileUploadResponse[] | [];
  @serializeAs('no_equipment') noEquipment: boolean;
  @serialize security: string | null;
  @serialize capacity: string | null;
  @serializeAs('average_check') averageCheck: string | null;
  @serialize volunteers: string[] | [];
  @serializeAs('partner_email_newsletter') partnerEmailNewsletter: string | null;
  @serialize lottery: string | null;
  @autoserializeAs('scene_genres') sceneGenres: string[];
  @autoserializeAs('scene_name') sceneName: string;
  @autoserializeAs('scene_subgenre') sceneSubgenre: string;
  @autoserializeAs('scene_date') sceneDate: string;
  @autoserializeAs('scene_start_time') sceneStartTime: string;
  @autoserializeAs('scene_end_time') sceneEndTime: string;
  @autoserializeAs('scene_description') sceneDescription: string;
  @autoserializeAs('additional_equipment_requirements') additionalEquipmentRequirements: FileUploadResponse[] | [];
  @autoserializeAs('no_additional_equipment') noAdditionalEquipment: boolean;

  constructor(
    id: number,
    status: PlatformStatus,
    name: string,
    contactEmail: string,
    address: string,
    coordinates: MapCoordModel,
    site: string,
    phone: string,
    facebookUrl: string,
    instagramUrl: string,
    vkontakteUrl: string,
    telegramUrl: string,
    shareSocial: boolean,
    description: string,
    contactName: string,
    contactPhone: string,
    contactTelegramUrl: string,
    contactFacebookUrl: string,
    contactInstagramUrl: string,
    contactVkontakteUrl: string,
    contactTwitterUrl: string,
    photoSite: FileUploadResponse,
    photoSite2: FileUploadResponse,
    photoSiteHeader: FileUploadResponse,
    logo: FileUploadResponse,
    numbers: NumbersModel,
    additionalAdvertisingCapabilities: string,
    partnerLogos: FileUploadResponse[],
    multimediaScreenSpecification: string,
    equipmentCapabilities: FileUploadResponse[],
    noEquipment: boolean,
    security: string,
    capacity: string,
    averageCheck: string,
    volunteers: string[],
    partnerEmailNewsletter: string,
    lottery: string,
    sceneGenres: string[],
    sceneName: string,
    sceneSubgenre: string,
    sceneDate: string,
    sceneStartTime: string,
    sceneEndTime: string,
    sceneDescription: string,
    additionalEquipmentRequirements: FileUploadResponse[] | [],
    noAdditionalEquipment: boolean
  ) {
    this.id = id
    this.status = status
    this.name = name
    this.contactEmail = contactEmail
    this.address = address
    this.coordinates = coordinates
    this.site = site
    this.phone = phone
    this.facebookUrl = facebookUrl
    this.instagramUrl = instagramUrl
    this.vkontakteUrl = vkontakteUrl
    this.telegramUrl = telegramUrl
    this.shareSocial = shareSocial
    this.description = description
    this.contactName = contactName
    this.contactPhone = contactPhone
    this.contactTelegramUrl = contactTelegramUrl
    this.contactFacebookUrl = contactFacebookUrl
    this.contactInstagramUrl = contactInstagramUrl
    this.contactVkontakteUrl = contactVkontakteUrl
    this.contactTwitterUrl = contactTwitterUrl
    this.photoSite = photoSite
    this.photoSite2 = photoSite2
    this.photoSiteHeader = photoSiteHeader
    this.logo = logo
    this.numbers = numbers
    this.additionalAdvertisingCapabilities = additionalAdvertisingCapabilities
    this.partnerLogos = partnerLogos
    this.multimediaScreenSpecification = multimediaScreenSpecification
    this.equipmentCapabilities = equipmentCapabilities
    this.noEquipment = noEquipment
    this.security = security
    this.capacity = capacity
    this.averageCheck = averageCheck
    this.volunteers = volunteers
    this.partnerEmailNewsletter = partnerEmailNewsletter
    this.lottery = lottery
    this.sceneGenres = sceneGenres
    this.sceneName = sceneName
    this.sceneSubgenre = sceneSubgenre
    this.sceneDate = sceneDate
    this.sceneStartTime = sceneStartTime
    this.sceneEndTime = sceneEndTime
    this.sceneDescription = sceneDescription
    this.additionalEquipmentRequirements = additionalEquipmentRequirements
    this.noAdditionalEquipment = noAdditionalEquipment
  }
}
import { autoserialize, autoserializeAs } from 'cerialize'
import { FileUploadResponse } from '../common/fileUpload.model'

export class ExpertProfilePlatformModel {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserialize logo?: string;

  constructor(
    id: number,
    name: string
  ) {
    this.id = id
    this.name = name
  }
}

export class ExpertProfileGetResponse {
  @autoserialize id: number;
  @autoserialize email: string;
  @autoserialize name?: string;
  @autoserialize phone?: string;
  @autoserializeAs('facebook_url') facebookUrl?: string;
  @autoserializeAs('instagram_url') instagramUrl?: string;
  @autoserializeAs('vkontakte_url') vkontakteUrl?: string;
  @autoserializeAs('telegram_url') telegramUrl?: string;
  @autoserialize description?: string;
  @autoserializeAs(FileUploadResponse) photo?: FileUploadResponse;
  @autoserializeAs(ExpertProfilePlatformModel, 'area_forms') platforms: ExpertProfilePlatformModel[] | []

  constructor(
    id: number, 
    email: string,
    platforms: ExpertProfilePlatformModel[] | []
  ) {
    this.id = id
    this.email = email
    this.platforms = platforms
  }
}

export class ExpertProfileSaveRequest {
  @autoserialize email: string;
  @autoserialize name?: string;
  @autoserialize phone?: string;
  @autoserializeAs('facebook_url') facebookUrl?: string;
  @autoserializeAs('instagram_url') instagramUrl?: string;
  @autoserializeAs('vkontakte_url') vkontakteUrl?: string;
  @autoserializeAs('telegram_url') telegramUrl?: string;
  @autoserialize description?: string;
  @autoserializeAs(FileUploadResponse) photo?: FileUploadResponse;

  constructor(
    email: string,
    photo: FileUploadResponse,
    name?: string,
    phone?: string,
    facebookUrl?: string,
    instagramUrl?: string,
    vkontakteUrl?: string,
    telegramUrl?: string,
    description?: string
  ) {
    this.email = email
    this.photo = photo
    this.name = name
    this.phone = phone
    this.facebookUrl = facebookUrl
    this.instagramUrl = instagramUrl
    this.vkontakteUrl = vkontakteUrl
    this.telegramUrl = telegramUrl
    this.description = description
  }
  
}
import React from 'react'

import './TextArea.style.sass'
import classNames from 'classnames'

const block = 'textarea-component'

interface PropTypes {
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  name: string;
  value: string;
  placeholder: string;
  error?: string;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  disabled?: boolean;
}

export default class TextAreaComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { name, onChange, value, placeholder, error, onBlur, onFocus, disabled } = this.props

    return (
      <div 
        className={classNames(
          block, 
          error?.length && `${block}--error`,
          this.props.disabled && `${block}--disabled`
        )}
      >
        <textarea
          className={`${block}__textarea`}
          name={name}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          maxLength={1000}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
        />
        <div className={`${block}__error`}>
          {error}
        </div>
      </div>
    )
  }
}

import React from 'react'
import classNames from 'classnames'

import './AuthFormInput.style.sass'

interface PropTypes {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: string;
  placeholder: string;
  icon?: JSX.Element;
  type?: string;
  error?: string;
}

const block = 'auth-form-input'

export default class AuthFormInput extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { onChange, name, value, icon, type, placeholder, error } = this.props

    return(
      <div className={classNames(block, error && `${block}--error`)}>
        <div className={`${block}__icon`}>
          {icon}
        </div>
        <input
          placeholder={placeholder}
          className={`${block}__input`}
          type={type || 'text'}
          name={name}
          onChange={onChange}
          value={value} 
        />
      </div>
    )
  }
}
import React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { observer } from "mobx-react"
import { forceCheck } from "react-lazyload"
import { 
  PATH_CRM_SCENES_NEW,
  PATH_CRM_SCENES_REMODERATION,
  PATH_CRM_SCENES_APPROVED,
  PATH_CRM_SCENES_CHECKED,
  PATH_CRM_SCENES
} from "../../../../const/routes.const"
import { getPlatformList } from "../../../../services/CRM/AdminCRM/scenes/platforms.service"
import { 
  adminPlatformListStore, 
  expertPlatformListStore 
} from "../../../../stores/CRM/AdminCRM/scenes/platformList.store"
import PlatformListComponent from "../../../../components/CRM/AdminCRM/PlatformList/PlatformList.component"
import { createPlatform } from "../../../../services/CRM/AdminCRM/scenes/createPlatform.service"
import { createPlatformStore } from "../../../../stores/CRM/AdminCRM/scenes/createPlatform.store"
import { PlatformListGetResponse, ListPlatformModel } from "../../../../models/ScenesModels/platformList.model"

interface State {
  tabIndex: number;
  newPlatformName: string;
  isModalVisible: boolean;
}

const currentCategory = `${PATH_CRM_SCENES}/`
const tabPathnames = [
  PATH_CRM_SCENES_NEW.replace(currentCategory, ''),
  PATH_CRM_SCENES_REMODERATION.replace(currentCategory, ''),
  PATH_CRM_SCENES_APPROVED.replace(currentCategory, ''),
  PATH_CRM_SCENES_CHECKED.replace(currentCategory, '')
]

@observer
class PlatformListContainer extends React.Component<RouteComponentProps> {
  state = { 
    tabIndex: 0,
    newPlatformName: '',
    isModalVisible: false
  };

  componentDidMount(): void {
    const pathSuffix = this.props.location.pathname.split('/').pop()
    const userRole = localStorage.getItem('userRole')

    if (userRole === 'ADMIN') {
      if (tabPathnames.indexOf(pathSuffix || '') < 0) {
        this.props.history.replace(PATH_CRM_SCENES_NEW)
      }

      this.setState({ tabIndex: this.getActiveTab(pathSuffix || '') })
    } else if (userRole === 'EXPERT') {
      this.props.history.replace(PATH_CRM_SCENES)
    }
  
    getPlatformList()  
  }

  componentDidUpdate(prevProps: RouteComponentProps): void {
    if (prevProps.location.search !== this.props.location.search) {
      forceCheck()
    }
  }

  componentWillUnmount(): void {
    adminPlatformListStore.clearData()
    expertPlatformListStore.clearData()
  }

  getActiveTab = (path: string): number => {
    const pathnameIndex = tabPathnames.indexOf(path)

    return pathnameIndex !== -1 ? pathnameIndex : 0
  }

  onSelect = (tabIndex: number): void => {
    this.setState({ tabIndex })
    this.props.history.replace(`${currentCategory}${tabPathnames[tabIndex]}`)
  }

  setPlatformName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      newPlatformName: event.target.value
    })
  }

  createPlatform = (): void => {
    createPlatform({name: this.state.newPlatformName}).then(() => {
      this.toggleModal()
    })
  }

  toggleModal = (): void => {
    this.setState((prevState: State) => ({
      isModalVisible: !prevState.isModalVisible
    }))
  }

  render(): JSX.Element {
    const {
      location: { search }
    } = this.props
    const { tabIndex } = this.state
    const userRole = localStorage.getItem('userRole')

    const isAdminPlatformsLoading = adminPlatformListStore.isLoading
    const isExpertPlatformsLoading = expertPlatformListStore.isLoading
    
    const platformsData = userRole === 'ADMIN' ? (
      adminPlatformListStore.platformList
    ) : (
      expertPlatformListStore.platformList
    )

    const params = new URLSearchParams(search)
    const query = params.get("name")

    const platforms = userRole === 'ADMIN' ? (
      adminPlatformListStore.getAdminPlatformsFiltered(query, platformsData as PlatformListGetResponse)
    ) : (
      expertPlatformListStore.getExpertPlatformsFiltered(query, platformsData as ListPlatformModel[])
    )

    return (
      <PlatformListComponent
        tabIndex={tabIndex}
        platforms={platforms}
        isLoading={isAdminPlatformsLoading || isExpertPlatformsLoading}
        onSelect={this.onSelect}
        onPlusButtonClick={this.toggleModal}
        onPlatformNameChange={this.setPlatformName}
        newPlatformName={this.state.newPlatformName}
        onPlatformCreate={this.createPlatform}
        onModalToggle={this.toggleModal}
        isModalVisible={this.state.isModalVisible}
        isPlatformCreating={createPlatformStore.isLoading}
      />
    )
  }
}
export default withRouter(PlatformListContainer)

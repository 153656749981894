import React from 'react'

import './Label.style.sass'
import classNames from 'classnames'

interface PropTypes {
  items: {
    icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    text: string;
  }[];
  type: 'top' | 'bottom';
  className?: string;
}

const block = 'label'

export default class LabelComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { items, type, className } = this.props

    return (
      <div className={classNames(
        block,
        className,
        `${block}--${type}`
      )}>
        {items.map((item, index) => (
          <div className={`${block}__item`} key={`${index}`}>
            <div className={`${block}__icon`}>
              {item.icon && React.createElement(item.icon)}
            </div>
            {item.text}
          </div>
        ))}
      </div> 
    )
  }
}
import React from 'react'

import './AuthLayout.style.sass'
import classNames from 'classnames'

interface PropTypes {
  children: React.ReactNode;
  hasRegister?: boolean;
}

const block = 'auth-layout'

export default class AuthLayoutComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    return (
      <div className={classNames(
        block,
        !this.props.hasRegister && `${block}--no-register`
      )}>
        {this.props.children}
      </div>
    )
  }
}
import React, { FC } from 'react'
import SheduleManageContainer from '../../../../../containers/CRM/AdminCRM/PlatformModeration/SheduleManageContainer'
import ScheduleTimelineComponent from './ScheduleTimeline/ScheduleTimeline.component'
import { performanceListStore } from '../../../../../stores/CRM/AdminCRM/scenes/performanceList.store'
import { observer } from 'mobx-react'

import './Schedule.style.sass'


const block = 'scene-schedule'

const ScheduleComponent: FC = () => {
  const { timelineData } = performanceListStore

  return (
    <div className={block}>
      {!!timelineData?.length && (
        <div className={`${block}__timeline`}>
          <ScheduleTimelineComponent />
        </div>
      )}
      <div className={`${block}__performance-list`}>
        <SheduleManageContainer />
      </div>
    </div>
  )
}

export default observer(ScheduleComponent)
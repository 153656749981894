import React from 'react'
import PerformanceListContainer from '../PerfomanceList/PerformanceList.container'
import { observer } from 'mobx-react'
import { sheduleManageStore } from '../../../../../stores/CRM/AdminCRM/scenes/sheduleManage.store'
import PerformanceArtistsContainer from '../PerfomanceArtists'
import { sceneModerationStore } from '../../../../../stores/CRM/AdminCRM/scenes/sceneModeration.store'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { PATH_CRM_SCENES_NEW } from '../../../../../const/routes.const'
import { getSceneForm } from '../../../../../services/CRM/AdminCRM/scenes/sceneForm.service'

interface State {
  isAddArtistsScreenVisible: boolean;
}

@observer
class SheduleManageContainer 
extends React.Component<RouteComponentProps<{platformId: string; sceneId: string}>, State> {
  componentDidMount(): void {
    const sceneId = parseInt(this.props.match.params.sceneId)
    const platformId = parseInt(this.props.match.params.sceneId)

    if (isNaN(sceneId) || isNaN(platformId)) {
      this.props.history.replace(PATH_CRM_SCENES_NEW)
    }

    if (!sceneModerationStore.sceneName) {
      getSceneForm(sceneId)
    }
  }

  componentWillUnmount(): void {
    sheduleManageStore.setActiveScreen('LIST')
  }
  
  renderSheduleScreen = (): JSX.Element => {
    switch(sheduleManageStore.activeScreenName) {
      case 'LIST': 
        return <PerformanceListContainer />
      case 'ARTISTS':
        return <PerformanceArtistsContainer />
      default:
        return <PerformanceListContainer />
    }
  }

  render(): JSX.Element {
    return (
      this.renderSheduleScreen()
    )
  }
}

export default withRouter(SheduleManageContainer)
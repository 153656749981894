import React from 'react'
import { PlatformExpertModel } from '../../../../../models/ScenesModels/experts.model'
import LoaderComponent from '../../../../common/Loader/Loader.component'
import classNames from 'classnames'

import './PlatformExpertsList.style.sass'

const block = 'platform-experts-list'

interface PropTypes {
  experts?: PlatformExpertModel[];
  isLoading: boolean;
  onExpertClick: (id: number) => void;
}

export default class PlatformExpertsListComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { experts, isLoading, onExpertClick } = this.props
    
    return (
      <div className={classNames(
        block,
        isLoading && `${block}--loading`
      )}>
        {isLoading ? (
          <LoaderComponent width={50} height={50} />
        ) : (
          experts && !!experts.length && (
            <div className={`${block}__list`}>
              {experts.map(expert => (
                <div 
                  onClick={(): void => onExpertClick(expert.id) }
                  className={`${block}__list-item`}
                  key={expert.id} 
                  style={expert.photo ? {
                    backgroundImage: `url(${expert.photo.url})`,
                    backgroundSize: 'cover'
                  } : {}}
                />
              ))}
            </div>
          )
        )}
        
      </div>
    )
  }
}
import { PATH_SELECTION_EXPERT_DASHBOARD, PATH_SELECTION_EXPERT_EVALUATION } from "../../../const/routes.const"

export const routeTitles = [
  {
    route: PATH_SELECTION_EXPERT_DASHBOARD,
    title: 'ПРОФИЛЬ ЭКСПЕРТА'
  },
  {
    route: PATH_SELECTION_EXPERT_EVALUATION,
    title: 'ПРОФИЛЬ ЭКСПЕРТА'
  }
]
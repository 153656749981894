import { ApiRequestStore } from "../../../common/apiRequest.store"
import { action, observable } from "mobx"
import { ExpertProfileGetResponse } from "../../../../models/ExpertsModels/expertProfile.model"

class ExpertProfileStore extends ApiRequestStore {
  @observable expertData?: ExpertProfileGetResponse;

  @action
  setExpertData(expertData: ExpertProfileGetResponse): void {
    this.expertData = expertData
  }

  @action
  clearData = (): void => {
    this.expertData = undefined
  }
}

class ExpertSaveStore extends ApiRequestStore {}

export const expertSaveStore: ExpertSaveStore = new ExpertSaveStore() 
export const expertProfileStore: ExpertProfileStore = new ExpertProfileStore()

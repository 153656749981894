import React from 'react'
import SceneFormComponent from '../../../../../components/CRM/AdminCRM/PlatformModeration/SceneForm/SceneForm.component'
import { addSceneExpert } from '../../../../../services/CRM/AdminCRM/scenes/addExpert.service'
import { addExpertStore } from '../../../../../stores/CRM/AdminCRM/scenes/addExpert.store'
import { sceneExpertsStore } from '../../../../../stores/CRM/AdminCRM/scenes/experts.store'
import { SceneSaveRequest } from '../../../../../models/ScenesModels/scene.model'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { getSceneForm, saveSceneForm } from '../../../../../services/CRM/AdminCRM/scenes/sceneForm.service'
import { sceneFormGetStore, sceneFormSaveStore } from '../../../../../stores/CRM/AdminCRM/scenes/sceneForm.store'
import { observer } from 'mobx-react'
import { PATH_PLATFORM_MODERATE, PATH_CRM_SCENES_NEW } from '../../../../../const/routes.const'

interface State {
  newExpertEmail: string;
  isModalVisible: boolean;
  showValidationErrors: boolean;
  shouldShowSaveSucceeded: boolean;
}

@observer
class SceneFormContainer extends React.Component<RouteComponentProps<{platformId: string; sceneId: string}>, State> {
  state = {
    newExpertEmail: '',
    isModalVisible: false,
    showValidationErrors: false,
    shouldShowSaveSucceeded: false
  };

  private timeOut?: number;

  componentDidMount = (): void => {
    
    const sceneId = parseInt(this.props.match.params.sceneId)
    const platformId = parseInt(this.props.match.params.sceneId)

    if (isNaN(platformId)) {
      this.props.history.replace(PATH_CRM_SCENES_NEW)

      return
    }

    if (isNaN(sceneId)) {
      this.props.history.replace(`${PATH_PLATFORM_MODERATE}/${platformId}/scenes`)
      
      return
    }
    
    getSceneForm(sceneId)
  }

  componentWillUnmount(): void {
    sceneFormGetStore.clearData()
    sceneExpertsStore.clearData()
    sceneFormSaveStore.clearData()
    addExpertStore.clearErrors()
    clearTimeout(this.timeOut)
  }

  onSubmit = (values: SceneSaveRequest): void => {
    const sceneId = parseInt(this.props.match.params.sceneId)

    saveSceneForm(values, sceneId).then(() => {
      this.setState({
        shouldShowSaveSucceeded: true
      }, () => {
        this.timeOut = window.setTimeout(() => {
          this.setState({
            shouldShowSaveSucceeded: false
          })
        }, 2000)
      })
    })
  }

  toggleModal = (): void => {
    this.setState((prevState: State) => ({
      isModalVisible: !prevState.isModalVisible
    }))
  }

  setExpertEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    addExpertStore.clearErrors()
    
    this.setState({
      newExpertEmail: event.target.value
    })
  }

  addExpert = (): void => {
    const sceneId = parseInt(this.props.match.params.sceneId)

    addSceneExpert({email: this.state.newExpertEmail}, sceneId).then(() => {
      this.toggleModal()
    })
  }

  showErrors = (): void => {
    this.setState({
      showValidationErrors: true
    })
  }

  render(): JSX.Element {
    return (
      <SceneFormComponent
        onExpertAdd={this.addExpert}
        onModalToggle={this.toggleModal}
        newExpertEmail={this.state.newExpertEmail}
        onExpertEmailChange={this.setExpertEmail}
        isModalVisible={this.state.isModalVisible}
        isExpertAdding={addExpertStore.isLoading}
        isExpertsListLoading={sceneExpertsStore.isLoading}
        isSceneLoading={sceneFormGetStore.isLoading}
        onShowErrors={this.showErrors}
        showValidationErrors={this.state.showValidationErrors}
        onSubmit={this.onSubmit}
        formInitialValues={sceneFormGetStore.formData}
        expertAdditionError={addExpertStore.requestErrors && addExpertStore.requestErrors[0].text}
        isSaveLoading={sceneFormSaveStore.isLoading}
        shouldShowSaveSucceeded={this.state.shouldShowSaveSucceeded}
        isFirstEdit={sceneFormGetStore.sceneData?.areaForm.status === 'EMPTY'}
      />
    )
  }
}

export default withRouter(SceneFormContainer)
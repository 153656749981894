import React from 'react'
import ReactDOM from 'react-dom'

import './Modal.style.sass'
import classNames from 'classnames'

interface PropTypes {
  children: React.ReactNode;
  isVisible: boolean;
  title?: string;
  onModalClose: () => void;
  autoHeight?: boolean; 
}

const block = 'modal'
const modalRoot = document.getElementById('modal-root')

export default class ModalComponent extends React.Component<PropTypes> {
  private el: Element

  constructor(props: PropTypes) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount = (): void => {
    modalRoot && modalRoot.appendChild(this.el)
  }

  componentWillUnmount = (): void => {
    modalRoot && modalRoot.removeChild(this.el)
  }

  render(): JSX.Element {
    return (
      <>
      {this.props.isVisible && (
        ReactDOM.createPortal(
          <div 
            className={block}
            onClick={this.props.onModalClose}
          >
            <div 
              className={classNames(
                `${block}__body`,
                this.props.autoHeight && `${block}__body--auto-height`
              )} 
              onClick={(e: React.SyntheticEvent<HTMLElement>): void => e.stopPropagation()}
            >
              <div className={`${block}__title`}>
                {this.props.title}
              </div>
              {this.props.children}
            </div>
          </div>,
          this.el
        )
      )}
      </>
    )
  }
}
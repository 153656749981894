import React from 'react'

import './ExpertPlatform.style.sass'

interface PropTypes {
  image?: string;
  title: string;
}

const block = "expert-platform"

export default class ExpertPlatformComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { image, title } = this.props

    return (
      <div className={block}>
        <div 
          className={`${block}__image`}
          style={image ? {backgroundImage: `url: ${image}`} : {}} 
        />
        <div className={`${block}__title`}>
          {title}
        </div>
      </div>
    )
  }
}
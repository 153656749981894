import { ApiRequestStore } from '../../common/apiRequest.store'
import {  ArtistFormSaveRequest, CommentModel } from '../../../models/ArtistFormModels/artistForm.model'
import { action, observable } from 'mobx'

class ArtistFormSaveStore extends ApiRequestStore {
  @observable saveTime?: Date;

  @action
  setSaveTime(date: Date): void {
    this.saveTime = date
  } 
}

class ArtistFormGetStore extends ApiRequestStore {
  @observable formData?: ArtistFormSaveRequest;
  @observable comments?: CommentModel[];

  @action
  setFormData(data: ArtistFormSaveRequest): void {
    this.formData = data
  }

  @action
  setComments(comments: CommentModel[]): void {
    this.comments = comments
  }

  @action
  clearData = (): void => {
    this.formData = undefined
    this.comments = undefined
  }
}

class ArtistFormApplyStore extends ApiRequestStore {}

class ArtistFormModerateStore extends ApiRequestStore {}

class ArtistFormApproveStore extends ApiRequestStore {}

class ArtistFormReadStore extends ApiRequestStore {}

export const artistFormSaveStore: ArtistFormSaveStore = new ArtistFormSaveStore()

export const artistFormGetStore: ArtistFormGetStore = new ArtistFormGetStore()

export const artistFormApplyStore: ArtistFormApplyStore = new ArtistFormApplyStore()

export const artistFormModerateStore: ArtistFormModerateStore = new ArtistFormModerateStore()

export const artistFormApproveStore: ArtistFormApproveStore = new ArtistFormApproveStore()

export const artistFormReadStore: ArtistFormReadStore = new ArtistFormReadStore()

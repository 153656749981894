import React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { observer } from "mobx-react"
import { getAdminArtistForms } from "../../../../services/CRM/AdminCRM/artists/adminArtistForms.service"
import { adminArtistFormsGetStore } from "../../../../stores/CRM/AdminCRM/artists/artistList.store"
import ArtistListComponent from "../../../../components/CRM/AdminCRM/ArtistList/ArtistList.component"
import { ArtistListCategory } from "../../../../models/AdminArtistFormsModels/adminArtistForms.model"
import { 
  PATH_CRM_ARTISTS_NEW,
  PATH_CRM_ARTISTS_REMODERATION,
  PATH_CRM_ARTISTS_APPROVED,
  PATH_CRM_ARTISTS_CHECKED,
  PATH_CRM_ARTISTS
} from "../../../../const/routes.const"

const currentCategory = `${PATH_CRM_ARTISTS}/`
const tabPathnames = [
  PATH_CRM_ARTISTS_NEW.replace(currentCategory, ''),
  PATH_CRM_ARTISTS_REMODERATION.replace(currentCategory, ''),
  PATH_CRM_ARTISTS_APPROVED.replace(currentCategory, ''),
  PATH_CRM_ARTISTS_CHECKED.replace(currentCategory, '')
]

const routeCaregories: Map<string, ArtistListCategory> = new Map([
  [PATH_CRM_ARTISTS_NEW, 'new'],
  [PATH_CRM_ARTISTS_REMODERATION, 'remoderation'],
  [PATH_CRM_ARTISTS_APPROVED, 'approved'],
  [PATH_CRM_ARTISTS_CHECKED, 'checked']
])

@observer
class ArtistListContainer extends React.Component<RouteComponentProps> {
  state = { tabIndex: 0 };

  componentDidMount(): void {
    const pathSuffix = this.props.location.pathname.split('/').pop()

    if (tabPathnames.indexOf(pathSuffix || '') < 0) {
      this.props.history.replace(PATH_CRM_ARTISTS_NEW)
    }

    const searchString = new URLSearchParams(this.props.location.search).get("name") || undefined

    this.getArtists(
      this.props.location.pathname, 
      searchString
    )

    this.setState({ tabIndex: this.getActiveTab(pathSuffix || '') })
  }

  getArtists = (pathname: string, search?: string, limit?: number, offset?: number): void => {

    const requestParams = {
      type: routeCaregories.get(pathname) || 'new',
      limit,
      offset,
      search
    }

    getAdminArtistForms(requestParams)
  }

  componentWillUnmount(): void {
    adminArtistFormsGetStore.clearData()
  }

  getActiveTab = (path: string): number => {
    const pathnameIndex = tabPathnames.indexOf(path)

    return pathnameIndex !== -1 ? pathnameIndex : 0
  }

  onSelect = (tabIndex: number): void => {
    this.setState({ tabIndex })
    const newPathname = `${currentCategory}${tabPathnames[tabIndex]}${this.props.location.search}`
    const pureNewPathname = `${currentCategory}${tabPathnames[tabIndex]}`
    const searchString = new URLSearchParams(this.props.location.search).get("name") || undefined

    this.props.history.replace(newPathname)
    adminArtistFormsGetStore.clearData()

    this.getArtists(
      pureNewPathname,
      searchString
    )
  }

  getShouldLoadMore = (): boolean => {
    const { count, artistForms } = adminArtistFormsGetStore

    if (count && artistForms) {
      return count > artistForms.length
    } else return true
  }

  render(): JSX.Element {
    const { tabIndex } = this.state
    const {
      isLoading,
      artistForms,
      currentOffset,
      count
    } = adminArtistFormsGetStore

    return (
      <ArtistListComponent
        tabIndex={tabIndex}
        artistsData={artistForms}
        isLoading={isLoading}
        onSelect={this.onSelect}
        onSearchChange={(value: string): void => {
          adminArtistFormsGetStore.clearData()

          this.getArtists(
            this.props.location.pathname,
            value || ''
          )
        }}
        onLoadMore={(): void => {
          this.getArtists(
            this.props.location.pathname,
            new URLSearchParams(this.props.location.search).get("name") || undefined,
            20,
            currentOffset ? currentOffset + 20 : 20
          )

          adminArtistFormsGetStore.setOffset(currentOffset ? currentOffset + 20 : 20)
        }}
        shouldLoadMore={this.getShouldLoadMore()}
        artistsCount={count}
      />
    )
  }
}
export default withRouter(ArtistListContainer)

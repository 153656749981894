import { api } from "../../../../utils/api"
import { RequestErrorResponse } from "../../../../models/common/error.model"
import { crmExpertAddStore } from "../../../../stores/CRM/AdminCRM/experts/expertAdd.store"
import { CrmExpertAddRequest } from "../../../../models/ExpertsModels/expertAdd.model"
import { Serialize } from "cerialize"

export function addCrmExpert(request: CrmExpertAddRequest): Promise<void> {
  const serializedData = Serialize(request, CrmExpertAddRequest)

  crmExpertAddStore.setIsLoading(true)

  return api.post(
    `/crm/admin/experts/`,
    serializedData
  ).then(() => {
    crmExpertAddStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    crmExpertAddStore.setIsLoading(false)
    crmExpertAddStore.setErrors(errorResponseData.errors)
    throw error
  })
}
import React from 'react'
import classNames from 'classnames'

import './Select.style.sass'

interface PropTypes {
  onChange: (value: string) => void;
  name: string;
  value: string;
  placeholder: string;
  error?: string;
  options: Array<{
    value: string;
    label: string;
  }>;
  isSmall?: boolean;
}

const block = 'form-select'

export default class SelectComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { name, placeholder, error, options, value, isSmall } = this.props

    return (
      <div className={classNames(
        block, 
        error && `${block}--error`,
        !value && `${block}--select-default-option`,
        isSmall && `${block}--small`
      )}>
        <select 
          className={`${block}__select`}
          onChange={(event): void => this.props.onChange(event.target.value)} 
          value={value || 'select-default-option'}
          placeholder={placeholder}
          name={name}
        >
          <option 
            disabled 
            value="select-default-option"
            className={`${block}__select-default-option`}
          >
            {placeholder}
          </option>
          {options.map((option, index): JSX.Element => (
            <option 
              key={`option-${option.value}-${index}`}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    )
  }
}
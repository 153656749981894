import React from "react"
import ArtistCRM from "./ArtistCRM/ArtistCRM.component"
import AdminCRM from "./AdminCRM/AdminCRM.component"
import { Redirect } from "react-router-dom"
import { getUserRole } from "../../utils/localStorage"
import SelectionExpertCRM from "./SelectionExpertCRM/SelectionExpertCRM.components"

export default class CRM extends React.Component {
  switchRole(): JSX.Element {
    switch (getUserRole()) {
      case "ARTIST":
        return <ArtistCRM />
      case "ADMIN":
        return <AdminCRM />
      case "EXPERT":
        return <AdminCRM />
      case "SELECTION_EXPERT":
        return <SelectionExpertCRM />
      default:
        return <Redirect to="/" />
    }
  }

  render(): JSX.Element {
    return this.switchRole()
  }
}

import { api } from "../../../../utils/api"
import { Deserialize, Serialize } from "cerialize"
import { 
  AdminArtistFormsGetResponse, 
  AdminArtistFormsGetRequest 
} from "../../../../models/AdminArtistFormsModels/adminArtistForms.model"
import { adminArtistFormsGetStore } from "../../../../stores/CRM/AdminCRM/artists/artistList.store"
import { RequestErrorResponse } from "../../../../models/common/error.model"


export function getAdminArtistForms(request: AdminArtistFormsGetRequest): Promise<void> {
  const serializedData = Serialize(request, AdminArtistFormsGetRequest) 

  adminArtistFormsGetStore.setIsLoading(true)

  return api
    .get(
      "/crm/admin/artist_forms_2/",
      {
        params: serializedData
      }
    )
    .then((res) => {
      const data: AdminArtistFormsGetResponse = Deserialize(
        res.data,
        AdminArtistFormsGetResponse
      )

      adminArtistFormsGetStore.setArtistFormsData({
        ...data
      })
      adminArtistFormsGetStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response.data
      adminArtistFormsGetStore.setIsLoading(false)
      adminArtistFormsGetStore.setErrors(errorResponseData.errors)
    })
}
import React from 'react'
import AuthFormLayoutComponent from '../../AuthFormLayout/AuthFormLayout.component'
import Button from '../../Button/Button.component'

import './PassRecoverySuccess.style.sass'

interface Props {
  onLoginClick: () => void;
}

const block = 'pass-recovery-success'

const PassRecoverySuccessComponent = (props: Props): JSX.Element => {
  const { onLoginClick } = props

  return (
    <AuthFormLayoutComponent 
      title="ВОССТАНОВЛЕНИЕ ПАРОЛЯ" 
      className={block}
    >
      <div className={`${block}__text`}>
        Письмо с ссылкой на восстановление пароля было отправлено на указанный email.
      </div>

      <div className={`row row--centered margin margin--top-m`}>
        <Button 
          background="purple" 
          onClick={onLoginClick}
          text={'Войти'}
        />
      </div>
    </AuthFormLayoutComponent>
  )
}

export default PassRecoverySuccessComponent
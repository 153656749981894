import React from 'react'
import { AddElementComponent } from '../../../common/AddElement/AddElement.component'
import { 
  PerformanceGetModel, 
  TimelineGetResponse, 
  PerfomanceSaveRequest
} from '../../../../../models/ScenesModels/performances.model'
import LoaderComponent from '../../../../common/Loader/Loader.component'
import PerformanceComponent from '../Performance/Performance.component'
import ModalComponent from '../../../../common/Modal/Modal.component'
import Button from '../../../../Button/Button.component'
import { RequestError } from '../../../../../models/common/error.model'

import './PerformanceList.style.sass'
import RequestErrorModalComponent from '../../../common/RequestErrorModal/RequestErrorModal.component'

const block = 'perfomance-list'

interface PropTypes {
  onArtistAddClick: () => void;
  performances?: PerformanceGetModel[];
  isPerformancesLoading: boolean;
  onPerformanceOpen: (performanceId: number, artistId: number) => void;
  onPerformanceClose: () => void;
  onPerformanceSave: (values: PerfomanceSaveRequest, performanceId: number) => void;
  timelineData?: TimelineGetResponse;
  activePerformanceId?: number;
  isTimelineLoading: boolean;
  isSaveLoading: boolean;
  onApprove: (performanceId: number, values: PerfomanceSaveRequest) => void;
  onDelete: (performanceId: number) => void;
  isApproveLoading: boolean;
  isDeleteLoading: boolean;
  isDeleteModalVisible: boolean;
  onDeleteModalClose: () => void;
  onDeleteModalApply: () => void;
  requestErrors?: RequestError[];
  onErrorsModalClose: () => void;
  onEyeClick: (artistFormId: number | null, artistId: number) => void;
}

export default class PerformanceListComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { 
      performances,
      isPerformancesLoading,
      timelineData,
      activePerformanceId,
      isTimelineLoading,
      onPerformanceOpen,
      onPerformanceClose,
      onPerformanceSave,
      isSaveLoading,
      onApprove,
      onDelete,
      isApproveLoading,
      isDeleteLoading,
      isDeleteModalVisible,
      onDeleteModalClose,
      onDeleteModalApply,
      requestErrors,
      onErrorsModalClose,
      onEyeClick
    } = this.props

    return (
      <div className={block}>
        <AddElementComponent onClick={this.props.onArtistAddClick} text="Добавить артиста в расписание" />

        {isPerformancesLoading ? (
          <div className={`${block}__loader`}>
            <LoaderComponent width={100} height={100} />
          </div>
        ) : (
          performances?.length ? (
            <div className={`${block}__list margin--top-s`}>
              <div className={`${block}__list-line`} />

              {performances.map(performance => (
                <div className={`${block}__list-item`} key={performance.id}>
                  <PerformanceComponent
                    hasReset={true}
                    onSubmit={(values: PerfomanceSaveRequest): void => onPerformanceSave(values, performance.id)}
                    isSubmitLoading={isSaveLoading}
                    name={performance.artist.name}
                    formInitialValues={{
                      date: performance.date,
                      startTime: performance.startTime,
                      endTime: performance.endTime,
                      soundcheckDate: performance.soundcheckDate,
                      soundcheckStartTime: performance.soundcheckStartTime,
                      soundcheckEndTime: performance.soundcheckEndTime
                    }}
                    image={performance.artist.avatar || ''}
                    isTimelineLoading={isTimelineLoading}
                    isTimelineVisible={activePerformanceId === performance.id}
                    type="perfomance"
                    timelineData={timelineData}
                    onApprove={(values): void => onApprove(performance.id, values)}
                    onDelete={(): void => onDelete(performance.id)}
                    isApproveLoading={isApproveLoading}
                    isDeleteLoading={isDeleteLoading}
                    status={performance.status}
                    onEditClick={(): void => {
                      if (activePerformanceId === performance.id) {
                        onPerformanceClose()
                      } else {
                        onPerformanceOpen(performance.id, performance.artist.id)
                      }
                    }}
                    onEyeClick={(): void => onEyeClick(performance.artist.formId, performance.artist.id)}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="margin--top-l">
              Список выступлений пуст
            </div>
          )
        )}

        <ModalComponent
          isVisible={isDeleteModalVisible}
          title="Удалить артиста из расписания?"
          onModalClose={onDeleteModalClose}
        >
          <div className="margin--top-l">
            <div className="row row--space-evenly margin--bottom-xxs">
              <Button
                text="Да"
                background="purple"
                onClick={onDeleteModalApply}
                isLoading={isDeleteLoading}
              />
              <Button
                text="Нет"
                background="pale-blue"
                onClick={onDeleteModalClose}
                isDisabled={isDeleteLoading}
              />
            </div>
          </div>
        </ModalComponent>

        <RequestErrorModalComponent 
          requestErrors={requestErrors}
          onModalClose={onErrorsModalClose}
        />
      </div>
    )
  }
}
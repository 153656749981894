import { Deserialize, Serialize } from "cerialize"
import { RequestErrorResponse } from "../../../models/common/error.model"
import {
  SelectionExpertArtistEvaluateRequest,
  SelectionExpertArtistGetResponse,
  SelectionExpertArtistWithRatingGetResponse
} from "../../../models/SelectionExpertsModels/selectionExpertEvaluation.model"
import {
  selectionExpertArtistGetStore,
  selectionExpertEvaluateStore,
  selectionExpertArtistIdGetStore
} from "../../../stores/CRM/SelectionExpertCRM/selectionExpertEvaluation.store"
import { api } from "../../../utils/api"

export function getSelectionExpertArtistData(): Promise<void> {
  selectionExpertArtistGetStore.setIsLoading(true)

  return api
    .get("/crm/selection_expert/artist_forms/next/")
    .then((res) => {
      const data: SelectionExpertArtistGetResponse = Deserialize(
        res.data,
        SelectionExpertArtistGetResponse
      )

      selectionExpertArtistGetStore.setArtistFormData(data)
      selectionExpertArtistGetStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response.data
      selectionExpertArtistGetStore.setIsLoading(false)
      selectionExpertArtistGetStore.setErrors(errorResponseData.errors)
      throw error
    })
}

export function evaluateArtist(
  request: SelectionExpertArtistEvaluateRequest
): Promise<void> {
  selectionExpertEvaluateStore.setIsLoading(true)

  const serializedData = Serialize(
    request,
    SelectionExpertArtistEvaluateRequest
  )

  return api
    .post(`/crm/selection_expert/artist_forms/rating/`, serializedData)
    .then(() => {
      selectionExpertEvaluateStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response.data
      selectionExpertEvaluateStore.setIsLoading(false)
      selectionExpertEvaluateStore.setErrors(errorResponseData.errors)
      throw error
    })
}

export function getSelectionExpertArtistIdData(
  artistId: string
): Promise<void> {
  selectionExpertArtistIdGetStore.setIsLoading(true)

  return api
    .get(`/crm/selection_expert/artist_forms/${artistId}/`)
    .then((res) => {
      const data: SelectionExpertArtistWithRatingGetResponse = Deserialize(
        res.data,
        SelectionExpertArtistWithRatingGetResponse
      )

      selectionExpertArtistIdGetStore.setArtistFormData(data)
      selectionExpertArtistIdGetStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response.data
      selectionExpertArtistIdGetStore.setIsLoading(false)
      selectionExpertArtistIdGetStore.setErrors(errorResponseData.errors)
      throw error
    })
}

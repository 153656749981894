import React from "react"
import Footer from "../common/Footer/Footer.component"

import "./PageLayout.style.sass"

interface PropTypes {
  children: React.ReactNode;
}

const block = "page-layout"

export default class PageLayoutComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    return (
      <div className={block}>
        <div className={`${block}__body`}>
          {this.props.children}
        </div>
        
        <Footer />
      </div>
    )
  }
}

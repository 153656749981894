import React from "react"
import Sticky from "react-sticky-el"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import moment from "moment"
import CommentInputContainer from "../../../../containers/CRM/AdminCRM/AdminApproveSidebar/CommentInput.container"
import Button from "../../../Button/Button.component"
import { CommentModel } from "../../../../models/ArtistFormModels/artistForm.model"
import { RequestError } from "../../../../models/common/error.model"
import ErrorLabelComponent from "../../common/ErrorLabel/ErrorLabel.component"
import { UserRole } from "../../../../models/common/user.model"

import "./ArtistSidebar.style.sass"

interface PropTypes {
  onComment: () => void;
  isModerateActive: boolean;
  isModerateLoading: boolean;
  comments?: CommentModel[];
  submittionErrors?: RequestError[];
  commentInputTitle: string;
  onTabChange: (tabIndex: number) => void;
  activeTabIndex: number;
}

export const sidebarTabs = {
  comments: {
    index: 0,
    title: "КОММЕНТАРИИ"
  },
  hints: {
    index: 1,
    title: "ПОДСКАЗКИ"
  }
}

const authorNames: {
  [key in UserRole]: string;
} = {
  ADMIN: "Модератор",
  ARTIST: "Артист",
  EXPERT: "Координатор",
  SELECTION_EXPERT: "Эксперт"
}

const block = "artist-sidebar"

export default class ArtistSidebarComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const {
      onComment,
      isModerateActive,
      comments,
      submittionErrors,
      commentInputTitle,
      isModerateLoading,
      onTabChange,
      activeTabIndex
    } = this.props

    return (
      <Tabs
        className={block}
        selectedTabClassName={`${block}__tabs-item--selected`}
        selectedIndex={activeTabIndex || 0}
        onSelect={onTabChange}
      >
        <TabList className={`${block}__tabs`}>
          <Tab className={`${block}__tabs-item`}>
            {sidebarTabs.comments.title}
          </Tab>
          <Tab className={`${block}__tabs-item`}>{sidebarTabs.hints.title}</Tab>
        </TabList>
        <TabPanel>
          <>
            <div className={`${block}__comment-input-section`}>
              <div className={`${block}__comment-input`}>
                <div className={`${block}__comment-input-title`}>
                  {commentInputTitle}
                </div>
                <div className="margin margin--top-xs">
                  <CommentInputContainer />
                </div>
                <div className="margin margin--top-xs">
                  <Button
                    onClick={onComment}
                    text="Отправить комментарий"
                    background="purple"
                    isDisabled={!isModerateActive}
                    isLoading={isModerateLoading}
                  />
                </div>
              </div>

              {submittionErrors &&
                submittionErrors.length &&
                submittionErrors.map(
                  (error, index): JSX.Element => (
                    <ErrorLabelComponent
                      text={error.text}
                      key={`${error.code}-${index}`}
                    />
                  )
                )}
            </div>
          </>
          <div className={`${block}__comments-section`}>
            <div className={`${block}__comments-title`}>
              Последние комментарии:
            </div>
            <Sticky
              boundaryElement=".artist-form__sidebar"
              stickyStyle={{
                top: 120,
                zIndex: 10
              }}
              topOffset={-120}
              style={{ minHeight: 400 }}
            >
              <div className={`${block}__comments`}>
                {comments && !!comments.length ? (
                  comments.map((comment: CommentModel, index: number) => (
                    <div
                      className={`${block}__comments-item`}
                      key={`admin-comment-${index}`}
                    >
                      <div className={`${block}__comments-item-author`}>
                        {authorNames[comment.author.role]}
                      </div>
                      <div className={`${block}__comments-item-date`}>
                        {moment(comment.createdAt).format("DD.MM.YYYY")}
                      </div>
                      <div className={`${block}__comments-item-text`}>
                        {comment.text}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={`${block}__no-comments`}>
                    Комментариев нет
                  </div>
                )}
              </div>
            </Sticky>
          </div>
        </TabPanel>
        <TabPanel />
      </Tabs>
    )
  }
}

import React, { FC } from 'react'
import { useHistory, useLocation } from 'react-router'
import 
  SelectionExpertHeaderComponent 
from 
  '../../../components/CRM/SelectionExpertCRM/SelectionExpertHeader/SelectionExpertHeader.component'
import { PATH_ROOT } from '../../../const/routes.const'
import { clearLocalStorage } from '../../../utils/localStorage'
import { routeTitles } from './data.const'

const SelectionExpertHeaderContainer: FC = (): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const headerTitle = routeTitles.find(item => item.route === location.pathname)?.title || 'ПРОФИЛЬ ЭКСПЕРТА'

  const logout = (): void => {
    clearLocalStorage()
    history.push(PATH_ROOT)
  }

  return (
    <SelectionExpertHeaderComponent onLogout={logout} title={headerTitle} />
  )
}

export default SelectionExpertHeaderContainer
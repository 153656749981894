import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

import './index.sass'

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: "https://969974ccb40f49069bad4f8f67abdb91@o464965.ingest.sentry.io/5675784",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

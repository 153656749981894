import React from 'react'
import { observer } from 'mobx-react'
import { uploadFile } from '../../../../services/common/uploadFile.service'
import { fileUploadStore } from '../../../../stores/common/fileUpload.store'
import { FileUploadResponse } from '../../../../models/common/fileUpload.model'
import DocumentUploadComponent from '../../../../components/CRM/common/DocumentUpload/DocumentUpload.component'

interface PropTypes {
  nameForStore: string;
  fileTypes?: string[];
  onFileUpload?: (response: FileUploadResponse) => void;
  uploadedFile?: FileUploadResponse;
  disabled?: boolean;
}

@observer
export default class DocumentUploadContainer extends React.Component<PropTypes> {
  constructor(props: PropTypes) {
    super(props)
    fileUploadStore.registration(this.props.nameForStore)
  }

  state = {
    isImage: false,
    error: ''
  }

  onDrop = (file: File[]): void => {
    this.setState({
      error: ''
    })

    const hasFile = file.length > 0
    const isDoc = hasFile && (
      file[0].type === 'image/jpeg' || file[0].type === 'image/png'
        || file[0].type === 'application/msword' || file[0].type === 'text/plain'
          || file[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            || file[0].type === 'application/pdf'
    )

    if (!isDoc) {
      const fileTypes = this.props.fileTypes?.join(', ')
      this.setState({
        error: `Недопустимый формат файла. Допустимые форматы: ${fileTypes}`
      })

      return
    }

    uploadFile(file, this.props.nameForStore).then(this.props.onFileUpload)
  }

  componentWillUnmount(): void {
    fileUploadStore.unmount(this.props.nameForStore)
  }

  render(): JSX.Element {
    const { fileTypes, disabled } = this.props

    return (
      <DocumentUploadComponent
        onDrop={this.onDrop}
        fileTypes={fileTypes || ['']}
        error={this.state.error}
        disabled={disabled}
      />
    )
  }
}

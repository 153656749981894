import classNames from 'classnames'
import React from 'react'

import './GenreRadioButton.style.sass'

interface PropTypes {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  checked?: boolean;
  value: string;
  caption: string;
  label?: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  type: 'checkbox' | 'radio';
  disabled?: boolean;
}

const block = 'genre-radio-button'

export default class GenreRadioButtonComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { name, checked, value, caption, label, onChange, icon, type, disabled } = this.props

    return (
      <label 
        className={classNames(
          block,
          disabled && `${block}--disabled`
        )}
      >
        <input
          name={name}
          type={type}
          value={value}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <div className={`${block}__icon`}>
          {React.createElement(icon)}
        </div>
        <div className={`${block}__caption`}>
          {caption}
        </div>
      </label>
    )
  }
}
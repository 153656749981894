import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import FilesCategoriesComponent from '../../../../components/CRM/AdminCRM/FilesCategories/FilesCategories.component'

class FilesCategoriesContainer extends React.Component<RouteComponentProps> {
  goToRoute = (route: string): void => {
    this.props.history.push(route)
  }

  render(): JSX.Element {
    return (
      <FilesCategoriesComponent 
        onCardClick={this.goToRoute}
      />
    )
  }
}

export default withRouter(FilesCategoriesContainer)
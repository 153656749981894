import { ApiRequestStore } from "../../../common/apiRequest.store"
import { action, observable } from "mobx"
import { ScenesListGetResponse, ListSceneModel } from "../../../../models/ScenesModels/scenesList.model"
import { SceneGetResponse } from "../../../../models/ScenesModels/scene.model"

class ScenesListStore extends ApiRequestStore {
  @observable scenesList?: ListSceneModel[];

  @action
  setScenesListData(data: ScenesListGetResponse): void {
    this.scenesList = data.placeForms
  }

  @action
  clearData = (): void => {
    this.scenesList = undefined
  }

  @action
  addScene = (sceneData: SceneGetResponse): void => {
    if (this.scenesList && sceneData.name) {
      this.scenesList = [
        {
          id: sceneData.id,
          name: sceneData.name
        },
        ...this.scenesList
      ]
    }
    
  }
  @action
  deleteScene = (id: number): void => {
    this.scenesList = this.scenesList?.filter(scene => scene.id !== id)
  }
}

export const scenesListStore: ScenesListStore = new ScenesListStore()

import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import ExpertProfileComponent from '../../../../components/CRM/AdminCRM/ExpertProfile/ExpertProfile.component'
import { PATH_EXPERTS, PATH_CRM_SCENES } from '../../../../const/routes.const'
import { expertProfileStore, expertSaveStore } from '../../../../stores/CRM/AdminCRM/experts/expertProfile.store'
import { observer } from 'mobx-react'
import { getExpertProfile, saveExpertProfile } from '../../../../services/CRM/AdminCRM/experts/expertProfile.service'
import { ExpertProfileSaveRequest } from '../../../../models/ExpertsModels/expertProfile.model'
import { userStore } from '../../../../stores/common/user.store'

@observer
class ExpertProfileContainer extends React.Component<RouteComponentProps<{id: string}>> {
  state = {
    showValidationErrors: false
  }

  componentDidMount = (): void => {
    const userRole = localStorage.getItem('userRole')

    if (this.props.match.params.id && userRole === 'ADMIN') {
      getExpertProfile(parseInt(this.props.match.params.id))
    } else if (userRole === 'EXPERT') {
      getExpertProfile()
    }
  }

  componentWillUnmount = (): void => {
    expertSaveStore.clearErrors()
    expertProfileStore.clearData()
    expertProfileStore.clearErrors()
  }

  saveExpert = (values: ExpertProfileSaveRequest): void => {
    const userRole = localStorage.getItem('userRole')

    if (this.props.match.params.id && userRole === 'ADMIN') {
      saveExpertProfile(values, parseInt(this.props.match.params.id))
    } else if (userRole === 'EXPERT') {
      saveExpertProfile(values)
    }
  }

  goBack = (): void => {
    const userRole = localStorage.getItem('userRole')
    
    if (this.props.history.length > 1) {
      this.props.history.goBack()
    } else {
      if (userRole === 'ADMIN') {
        this.props.history.push(PATH_EXPERTS)
      } else if (userRole === 'EXPERT') {
        this.props.history.push(PATH_CRM_SCENES)
      }
    }
  }

  showErrors = (): void => {
    this.setState({
      showValidationErrors: true
    })
  }

  render(): JSX.Element {
    const expert = expertProfileStore.expertData

    return (
      <ExpertProfileComponent
        onSave={this.saveExpert}
        onBack={this.goBack}
        formInitialValues={expert && {
          name: expert?.name,
          email: expert?.email,
          phone: expert?.phone,
          facebookUrl: expert.facebookUrl,
          instagramUrl: expert.instagramUrl,
          vkontakteUrl: expert.vkontakteUrl,
          telegramUrl: expert.telegramUrl,
          description: expert.description,
          photo: expert.photo
        }}
        platforms={expertProfileStore.expertData && expertProfileStore.expertData.platforms}
        onShowErrors={this.showErrors}
        showValidationErrors={this.state.showValidationErrors}
        isSaving={expertSaveStore.isLoading}
        isLoading={expertProfileStore.isLoading}
        userEmail={userStore.userData?.email as string}
      />
    )
  }
}

export default withRouter(ExpertProfileContainer)
/* eslint-disable max-len */
export function isEmailValid(email: string): boolean {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}
export function isDateValid(date: string): boolean {
  const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
  return regex.test(date)
}

export function isPhoneValid(phone: string): boolean {
  const regex = /^[+]\d{1,2}\s[(]{0,1}[0-9]{1,4}[)]{0,1}[ \s\./0-9]*$/g
  return regex.test(phone)
}
// TODO
export const fieldRequired = (value: string): string | undefined => (value ? undefined : 'Это обязательное поле')
export const incorrectEmail = (value: string): string | undefined => (
  isEmailValid(value) ? undefined : 'Введите корректный email'
)
export const incorrectPhone = (value: string): string | undefined => (
 isPhoneValid(value) ? undefined : 'Введите корректный телефон' 
)

export const incorrectBirthdate = (value: string): string | undefined => (
  isDateValid(value) ? undefined : 'Введите корректную дату'
)

export const composeValidators = (...validators: Array<(val: string) => string | undefined>) => (
  (value: string): string | undefined => (
    validators.reduce((error: string | undefined, validator) => error || validator(value), undefined)
  )
)


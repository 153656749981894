import React from 'react'
import classNames from 'classnames'

import './AuthFormLayout.style.sass'

interface PropTypes {
  title: string;
  children: React.ReactNode;
  className?: string;
  error?: string;
}

const block = 'auth-form-layout'

export default class AuthFormLayoutComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { title, children, className, error } = this.props
    return(
      <div className={classNames(`${block}`, className)}>
        <div className={`${block}__title`}>
          {title}
        </div>
        <div className={`${block}__form`}>
          {children}
        </div>
        {error && (
          <div className={`${block}__error`}>
            {error}
          </div>
        )}
      </div>
    )
  }
}
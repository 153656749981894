import { serialize, serializeAs, deserializeAs, deserialize, autoserialize, autoserializeAs } from 'cerialize'
import { FileUploadResponse } from '../common/fileUpload.model'
import { UserRole } from '../common/user.model'

export type artistFormStatuses = 'NEW' | 'EDITING' | 'MODERATION' | 'APPROVED' | 'CHECKED'

export class MemberModel {
  @serialize @deserialize name: string;
  @serialize @deserialize birthday: string;
  @serializeAs('facebook_url') @deserializeAs('facebook_url') facebookUrl: string;
  @serializeAs('instagram_url') @deserializeAs('instagram_url') instagramUrl: string;
  @serializeAs('vkontakte_url') @deserializeAs('vkontakte_url') vkontakteUrl: string;
  @serializeAs('twitter_url') @deserializeAs('twitter_url') twitterUrl: string;
  @serializeAs('t_shirt_size') @deserializeAs('t_shirt_size') tShirtSize: string;

  constructor(
    name: string,
    birthday: string,
    facebookUrl: string,
    instagramUrl: string,
    vkontakteUrl: string,
    twitterUrl: string,
    tShirtSize: string
  ) {
    this.name = name
    this.birthday = birthday
    this.facebookUrl = facebookUrl
    this.instagramUrl = instagramUrl
    this.vkontakteUrl = vkontakteUrl
    this.twitterUrl = twitterUrl
    this.tShirtSize = tShirtSize
  }
}

export class CommentAuthor {
  @autoserialize role: UserRole;

  constructor(role: UserRole) {
    this.role = role
  }
}

export class CommentModel {
  @serialize @deserialize text: string;
  @serializeAs('created_at') @deserializeAs('created_at') createdAt: string;
  @autoserializeAs(CommentAuthor) author: CommentAuthor;

  constructor(
    text: string,
    createdAt: string,
    author: CommentAuthor
  ) {
    this.text = text
    this.createdAt = createdAt
    this.author = author
  }
}

export class ArtistFormSaveRequest {
  @serialize name: string | null;
  @serialize genre: string | null;
  @serialize subgenre: string | null;
  @serialize country: string | null;
  @serialize city: string | null;
  @serializeAs('contact_email') contactEmail: string | null;
  @serializeAs('contact_phone') contactPhone: string | null;
  @serializeAs('contact_name') contactName: string | null;
  @serializeAs('contact_main_social') contactMainSocial: string | null;
  @serialize description: string | null;
  @serializeAs('facebook_url') facebookUrl: string | null;
  @serializeAs('instagram_url') instagramUrl: string | null;
  @serializeAs('vkontakte_url') vkontakteUrl: string | null;
  @serializeAs('tiktok_url') tiktokUrl: string | null;
  @serializeAs('spotify_url') spotifyUrl: string | null;
  @serializeAs('members_count') membersCount: string | null;
  @serializeAs(MemberModel) members: MemberModel[] | null;
  @serializeAs('photo_site') photoSite: FileUploadResponse | null;
  @serializeAs('photo_site_header') photoSiteHeader: FileUploadResponse | null;
  @serializeAs('photo_social') photoSocial: FileUploadResponse | null;
  // @serializeAs('photo_affiche') photoAffiche: FileUploadResponse | null;
  @serializeAs('video_urls') videoUrls: Array<string | undefined>;
  @serializeAs('audio_urls') audioUrls: Array<string | undefined>;
  @serializeAs('tech_riders') techRiders: Array<FileUploadResponse | undefined> | null;
  @serializeAs('local_media_url') localMediaUrl: string | null;
  @serializeAs('promo_post_url') promoPostUrl: string | null;

  constructor(
    name: string,
    genre: string,
    subgenre: string,
    country: string,
    city: string,
    contactEmail: string,
    contactPhone: string,
    contactName: string,
    contactMainSocial: string,
    description: string,
    facebookUrl: string,
    instagramUrl: string,
    vkontakteUrl: string,
    tiktokUrl: string,
    spotifyUrl: string,
    membersCount: string,
    members: MemberModel[],
    photoSite: FileUploadResponse,
    photoSiteHeader: FileUploadResponse,
    photoSocial: FileUploadResponse,
    // photoAffiche: FileUploadResponse,
    videoUrls: string[],
    audioUrls: string[],
    techRiders: Array<FileUploadResponse | undefined>,
    localMediaUrl: string,
    promoPostUrl: string
  ) {
    this.name = name
    this.genre = genre
    this.subgenre = subgenre
    this.country = country
    this.city = city
    this.contactEmail = contactEmail
    this.contactPhone = contactPhone
    this.contactName = contactName
    this.contactMainSocial = contactMainSocial
    this.description = description
    this.facebookUrl = facebookUrl
    this.instagramUrl = instagramUrl
    this.vkontakteUrl = vkontakteUrl
    this.tiktokUrl = tiktokUrl
    this.spotifyUrl = spotifyUrl
    this.membersCount = membersCount
    this.members = members
    this.photoSite = photoSite
    this.photoSiteHeader = photoSiteHeader
    this.photoSocial = photoSocial
    // this.photoAffiche = photoAffiche
    this.videoUrls = videoUrls
    this.audioUrls = audioUrls
    this.techRiders = techRiders
    this.localMediaUrl = localMediaUrl
    this.promoPostUrl = promoPostUrl
  }
}

export class ArtistFormGetResponse {
  @deserialize id: number;
  @deserialize status: artistFormStatuses;
  @deserializeAs("sent_to_moderation_at") sentToModerationAt: string;
  @deserializeAs("moderated_at") moderatedAt: string;
  @deserializeAs("approved_at") approvedAt: string;
  @deserializeAs("created_at") createdAt: string;
  @deserializeAs("updated_at") updatedAt: string;
  @deserializeAs(CommentModel) comments: CommentModel[];
  @deserialize name: string | null;
  @deserialize genre: {
    label: string;
    name: string;
  } | null;
  @deserialize subgenre: string | null;
  @deserialize country: string | null;
  @deserialize city: string | null;
  @deserializeAs('contact_email') contactEmail: string | null;
  @deserializeAs('contact_phone') contactPhone: string | null;
  @deserializeAs('contact_name') contactName: string | null;
  @deserializeAs('contact_main_social') contactMainSocial: string | null;
  @deserialize description: string | null;
  @deserializeAs('facebook_url') facebookUrl: string | null;
  @deserializeAs('instagram_url') instagramUrl: string | null;
  @deserializeAs('vkontakte_url') vkontakteUrl: string | null;
  @deserializeAs('tiktok_url') tiktokUrl: string | null;
  @deserializeAs('spotify_url') spotifyUrl: string | null;
  @deserializeAs('members_count') membersCount: string | null;
  @deserializeAs(MemberModel) members: MemberModel[] | [];
  @deserializeAs(FileUploadResponse, 'photo_site') photoSite: FileUploadResponse | null;
  @deserializeAs(FileUploadResponse, 'photo_site_header') photoSiteHeader: FileUploadResponse | null;
  @deserializeAs(FileUploadResponse, 'photo_social') photoSocial: FileUploadResponse | null;
  @deserializeAs(FileUploadResponse, 'photo_affiche') photoAffiche: FileUploadResponse | null;
  @deserializeAs('video_urls') videoUrls: string[] | null;
  @deserializeAs('audio_urls') audioUrls: string[] | null;
  @deserializeAs('tech_riders') techRiders: Array<FileUploadResponse> | [];
  @deserializeAs('local_media_url') localMediaUrl: string | null;
  @deserializeAs('promo_post_url') promoPostUrl: string | null;

  constructor(
    id: number,
    status: artistFormStatuses,
    sentToModerationAt: string,
    moderatedAt: string,
    approvedAt: string,
    createdAt: string,
    updatedAt: string,
    comments: CommentModel[],
    name: string,
    genre: {
      label: string;
      name: string;
    } | null,
    subgenre: string,
    country: string,
    city: string,
    contactEmail: string,
    contactPhone: string,
    contactName: string,
    contactMainSocial: string,
    description: string,
    facebookUrl: string,
    instagramUrl: string,
    vkontakteUrl: string,
    tiktokUrl: string,
    spotifyUrl: string,
    membersCount: string,
    members: MemberModel[],
    photoSite: FileUploadResponse,
    photoSiteHeader: FileUploadResponse,
    photoSocial: FileUploadResponse,
    photoAffiche: FileUploadResponse,
    videoUrls: string[],
    audioUrls: string[],
    techRiders: Array<FileUploadResponse>,
    localMediaUrl: string,
    promoPostUrl: string
  ) {
    this.id = id
    this.status = status
    this.sentToModerationAt = sentToModerationAt
    this.moderatedAt = moderatedAt
    this.approvedAt = approvedAt
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.comments = comments
    this.name = name
    this.genre = genre
    this.subgenre = subgenre
    this.country = country
    this.city = city
    this.contactEmail = contactEmail
    this.contactPhone = contactPhone
    this.contactName = contactName
    this.contactMainSocial = contactMainSocial
    this.description = description
    this.facebookUrl = facebookUrl
    this.instagramUrl = instagramUrl
    this.vkontakteUrl = vkontakteUrl
    this.tiktokUrl = tiktokUrl
    this.spotifyUrl = spotifyUrl
    this.membersCount = membersCount
    this.members = members
    this.photoSite = photoSite
    this.photoSiteHeader = photoSiteHeader
    this.photoSocial = photoSocial
    this.photoAffiche = photoAffiche
    this.videoUrls = videoUrls
    this.audioUrls = audioUrls
    this.techRiders = techRiders
    this.localMediaUrl = localMediaUrl
    this.promoPostUrl = promoPostUrl
  }
}


export class ArtistFormApplyRequest {
  @autoserialize comment?: string;

  constructor(comment?: string) {
    this.comment = comment
  }
}

export interface ArtistFormFields extends ArtistFormSaveRequest {
  comment?: string;
}
import React from 'react'
import {
  PATH_RIDERS_ARTISTS,
  PATH_RIDERS_SCENES,
  PATH_RIDERS_COMMON
} from '../../../../const/routes.const'
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow.svg'

import './FilesCategories.style.sass'
import {
  getExportByName,
  getExports
} from '../../../../services/CRM/AdminCRM/exports/adminExports.service'
import { adminExportsStore } from '../../../../stores/CRM/AdminCRM/exports/exports.store'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'
import LoaderComponent from '../../../common/Loader/Loader.component'

interface PropTypes {
  onCardClick: (route: string) => void;
}

const block = 'files-categories'

const cardsData = [
  {
    title: 'Тех. райдеры артистов',
    route: PATH_RIDERS_ARTISTS
  },
  {
    title: 'Тех. райдеры сцен',
    route: PATH_RIDERS_SCENES
  }
  // {
  //   title: 'Бытовые райдеры',
  //   route: PATH_RIDERS_COMMON
  // }
]

export const downloadFileByLink = (url: string): void => {
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const getExportDataByClick = (name: string): void => {
  adminExportsStore.setIsExportInLoading(name)
  getExportByName(name)
    .then(() => {
      if (adminExportsStore.export) {
        downloadFileByLink(adminExportsStore.export.url)
      }
    })
    .then(() => {
      adminExportsStore.setIsExportInLoading(name)
    })
}

@observer
export default class FilesCategoriesComponent extends React.Component<PropTypes> {
  componentDidMount(): void {
    getExports()
  }

  render(): JSX.Element {
    const { onCardClick } = this.props

    return (
      <div className={block}>
        {adminExportsStore.isLoading ? (
          <LoaderComponent width={100} height={100} />
        ) : (
          <div className={'exports'}>
            {cardsData.map(card => (
              <div
                className={`${block}__card`}
                onClick={(): void => onCardClick(card.route)}
                key={`${card.title}-${card.route}`}
              >
                <div className={`${block}__card-title`}>{card.title}</div>
              </div>
            ))}
            {adminExportsStore.exports &&
              adminExportsStore.exports.exports.length > 0 &&
              adminExportsStore.exports.exports.map(exportItem => (
                <div
                  className={`${block}__card`}
                  key={`${exportItem.name}`}
                  data-tip
                  data-for={`${exportItem.name}`}
                >
                  <ReactTooltip
                    id={`${exportItem.name}`}
                    place="bottom"
                    effect="float"
                    textColor={'#000000'}
                    backgroundColor={'#ffffff'}
                    delayShow={300}
                    className={'toolTip'}
                  >
                    {exportItem.description}
                  </ReactTooltip>
                  <div className={`${block}__card-title`}>
                    {exportItem.title}
                  </div>

                  <button
                    onClick={(): void => {
                      getExportDataByClick(exportItem.name)
                    }}
                    className={classNames(
                      `${block}__download`,
                      adminExportsStore.exportsLoadingSet.has(
                        exportItem.name
                      ) && `${block}__download--disabled`
                    )}
                  >
                    {adminExportsStore.exportsLoadingSet.has(
                      exportItem.name
                    ) ? (
                      <LoaderComponent width={30} height={30} />
                    ) : (
                      <>
                        <div className={`${block}__download-icon`}>
                          <Arrow width="15" height="15" fill="#ffffff" />
                        </div>
                        <div className={`${block}__download-text`}>Скачать</div>
                      </>
                    )}
                  </button>
                </div>
              ))}
          </div>
        )}
      </div>
    )
  }
}

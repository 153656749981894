import { deserializeAs, deserialize } from 'cerialize'

export type PlatformStatus = 'EMPTY' | 'EDITING' | 'MODERATION' | 'APPROVED' | 'CHECKED'

export type AdminPlatformListCategories = 'newCategory' | 'remoderation' | 'approved' |'checked'

export class ListPlatformModel {
  @deserialize id: number;
  @deserializeAs('admin_status') platformStatus: PlatformStatus;
  @deserialize name: string;
  @deserialize avatar?: string;
  @deserializeAs('updated_at') updatedAt?:	string;

  constructor(
    id: number,
    platformStatus: PlatformStatus,
    name: string,
    avatar?: string,
    updatedAt?: string
  ) {
    this.id = id
    this.platformStatus = platformStatus
    this.name = name
    this.avatar = avatar
    this.updatedAt = updatedAt
  }
}

export class PlatformListGetResponse {
  @deserializeAs(ListPlatformModel, "new") newCategory: ListPlatformModel[] | [];
  @deserializeAs(ListPlatformModel) remoderation: ListPlatformModel[] | [];
  @deserializeAs(ListPlatformModel) approved: ListPlatformModel[] | [];
  @deserializeAs(ListPlatformModel) checked: ListPlatformModel[] | [];

  constructor(
    newCategory: ListPlatformModel[] | [],
    remoderation: ListPlatformModel[] | [],
    approved: ListPlatformModel[] | [],
    checked: ListPlatformModel[] | []
  ) {
    this.newCategory = newCategory
    this.remoderation = remoderation
    this.approved = approved
    this.checked = checked
  }
}

export class ExpertListPlatformModel {
  @deserialize id: number;
  @deserializeAs('status') platformStatus: PlatformStatus;
  @deserialize name: string;
  @deserialize avatar?: string;
  @deserializeAs('updated_at') updatedAt?:	string;

  constructor(
    id: number,
    platformStatus: PlatformStatus,
    name: string,
    avatar?: string,
    updatedAt?: string
  ) {
    this.id = id
    this.platformStatus = platformStatus
    this.name = name
    this.avatar = avatar
    this.updatedAt = updatedAt
  }
}

export class ExpertPlatformListGetResponse {
  @deserializeAs(ExpertListPlatformModel, 'area_forms') platforms?: ExpertListPlatformModel[]
}
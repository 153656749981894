import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import ArtistNotificationsComponent from '../../../../components/CRM/ArtistCRM/ArtistNotifications'
import { artistFormNotificationsStore } from '../../../../stores/CRM/ArtistCRM/artistFormNotifications.store'
import { PATH_ARTIST_FORM } from '../../../../const/routes.const'

@observer
class ArtistNotificationsContainer extends React.Component<RouteComponentProps> {
  onFormEditClick = (): void => {
    this.props.history.push(PATH_ARTIST_FORM)
  }

  render(): JSX.Element {
    return (
      <div>
        <ArtistNotificationsComponent 
          notificationsData={artistFormNotificationsStore.notificationsData} 
          onFormEditClick={this.onFormEditClick}
        />
      </div>
    )
  }
}

export default withRouter(ArtistNotificationsContainer)
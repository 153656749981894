import classNames from 'classnames'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router'
import { getTimeline } from '../../../../../../services/CRM/AdminCRM/scenes/performance.service'
import { performanceListStore } from '../../../../../../stores/CRM/AdminCRM/scenes/performanceList.store'
import { performanceUIStore } from '../../../../../../stores/CRM/AdminCRM/scenes/performanceUI.store'
import { groupBy } from '../../../../../../utils/groupBy'
import LoaderComponent from '../../../../../common/Loader/Loader.component'

import './ScheduleTimeline.style.sass'

interface RouteMatchParams {
  sceneId: string;
}

const block = 'schedule-timeline'

const formatPerformanceDate = (date: string): string => moment(date).format('DD.MM')

const ScheduleTimelineComponent: FC = () => {
  const { timelineData, isLoading, performances } = performanceListStore
  const sceneId = parseInt(useParams<RouteMatchParams>().sceneId)

  const onTimelineLabelClick = (id: number): void => {
    performanceUIStore.setActivePerformanceId(id)
    const performanceArtistId = performances?.find(performance => performance.id === id)?.artist.id

    performanceArtistId && getTimeline({sceneId, artistId: performanceArtistId})
  }

  const getTypeLabel = (type: "performance" | "soundcheck"): string => {
    switch(type) {
      case "performance":
        return 'Выступление'
      case "soundcheck":
        return 'Саундчек'
    }
  }

  const timelineDates = useMemo(() => {
    if (timelineData) {
      return timelineData.map((timelineDataItem, index, timelineDataArray) => (
          timelineDataArray.findIndex(item => (
            moment(item.start).date() === moment(timelineDataItem.start).date()
          )) === index ? (
            formatPerformanceDate(timelineDataItem.start)
          ) : (
            undefined
          )
        )).filter(Boolean)
    }
    return []
  }, [timelineData])

  const timelineDataGroupedByDate = useMemo(() => {
    if (timelineData) {
      return groupBy(timelineData, timelineData => formatPerformanceDate(timelineData.start))
    }
  }, [timelineData])
  
  return (
    <div className={block}>
      {isLoading ? (
        <LoaderComponent width={100} height={100} />
      ) : (
        <>
          {timelineData ? (
            timelineDates.map(timelineDate => (
              <React.Fragment key={timelineDate}>
              <div className={`${block}__date`}>
                {timelineDate}
              </div>
              <div className={`${block}__list`} key={timelineDate}>
                <div className={`${block}__list-line`} />
                <div className={`${block}__list-items`}>
                  {timelineDataGroupedByDate?.get(timelineDate).map(timelineDataItem => (
                    <div 
                      className={classNames(
                        `${block}__list-item`,
                        timelineDataItem.performanceId === performanceUIStore.activePerformanceId && (
                          `${block}__list-item--active`
                        )
                      )} 
                      key={`{${timelineDataItem.type}-${timelineDataItem.performanceId}`}
                    >
                      <div className={`${block}__list-item-circle`} />
                      <div className={`${block}__list-item-time`}>
                        {moment(timelineDataItem.start).format('HH:mm')}
                        {' '} - {' '}
                        {moment(timelineDataItem.end).format('HH:mm')}
                      </div>
                      <div 
                        className={`${block}__list-item-label`}
                        onClick={(): void => onTimelineLabelClick(timelineDataItem.performanceId)}
                      >
                        {`• ${getTypeLabel(timelineDataItem.type)} ${timelineDataItem.name}`} 
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              </React.Fragment>
            ))
          ) : (
            <div className={`${block}__no-schedule`}>Нет запланированных выступлений</div>
          )}
        </>
      )}
    </div>
  )
  
}

export default observer(ScheduleTimelineComponent)
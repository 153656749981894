import { autoserialize, autoserializeAs } from "cerialize"

export class ArtistsIntersectionsGetRequest {
  @autoserialize limit?: number;
  @autoserialize offset?: number;
  @autoserialize search?: string;

  constructor(
    limit?: number,
    offset?: number,
    search?: string
  ) {
    this.limit = limit
    this.offset = offset
    this.search = search
  }
}

export class ArtistIntersectionPlace {
  @autoserialize id: number;
  @autoserialize name: string;

  constructor(id: number, name: string) {
    this.id = id
    this.name = name
  }
}

export class ArtistIntersectionPerformance {
  @autoserialize id: number;
  @autoserializeAs('start_datetime') startDatetime: string;
  @autoserializeAs('end_datetime') endDatetime: string;
  @autoserializeAs(ArtistIntersectionPlace) place: ArtistIntersectionPlace;

  constructor(
    id: number, 
    startDatetime: string,
    endDatetime: string,
    place: ArtistIntersectionPlace
  ) {
    this.id = id
    this.startDatetime = startDatetime
    this.endDatetime = endDatetime
    this.place = place
  }
}

export class ArtistIntersectionArtist {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserializeAs(ArtistIntersectionPerformance) performances: ArtistIntersectionPerformance[];

  constructor(id: number, name: string, performances: ArtistIntersectionPerformance[]) {
    this.id = id
    this.name = name
    this.performances = performances
  }
}

export class ArtistIntersectionModel {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserializeAs(ArtistIntersectionArtist) artists: ArtistIntersectionArtist[];

  constructor(id: number, name: string, artists: ArtistIntersectionArtist[]) {
    this.id = id
    this.name = name
    this.artists = artists
  }
}

export class ArtistsIntersectionsGetResponse {
  @autoserializeAs(ArtistIntersectionModel) musicians?: ArtistIntersectionModel[];
  @autoserialize count?: number;

  constructor(musicians: ArtistIntersectionModel[], count: number) {
    this.musicians = musicians
    this.count = count
  }
}
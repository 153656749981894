import React from 'react'
import { RequestError } from '../../../../models/common/error.model'
import { ReactComponent as Remove } from '../../../../assets/icons/remove.svg'
import ModalComponent from '../../../common/Modal/Modal.component'
import Button from '../../../Button/Button.component'

import './RequestErrorModal.style.sass'

const block = 'request-error-modal'

interface PropTypes {
  requestErrors?: RequestError[];
  onModalClose: () => void;
}

export default class RequestErrorModalComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { requestErrors, onModalClose } = this.props

    return (
      <ModalComponent 
        isVisible={!!requestErrors && !!requestErrors.length} 
        onModalClose={onModalClose}
        autoHeight
      >
        <div className={block}>
          <div className={`${block}__icon`}>
            <Remove />
          </div>
          <div className={`${block}__errors`}>
            {requestErrors && requestErrors.map(error => (
              <div className={`${block}__error-text`} key={error.code}>
                {error.text || 'test text'}
              </div>
            ))}
          </div>
          <div className="margin--top-s row row--centered">
            <Button background="purple" text="OK" onClick={onModalClose} />
          </div>
        </div>
      </ModalComponent>
    )
  }
}
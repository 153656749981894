/* eslint-disable max-len */
import { observer } from "mobx-react"
import React, { FC, useEffect } from "react"
import { useHistory, useParams } from "react-router"
import SelectionExpertEvaluationComponent from "../../../components/CRM/SelectionExpertCRM/SelectionExpertEvaluation/SelectionExpertEvaluation.component"
import { PATH_SELECTION_EXPERT_DASHBOARD } from "../../../const/routes.const"
import { RequestErrorResponse } from "../../../models/common/error.model"
import { getSelectionExpertArtistIdData } from "../../../services/CRM/SelectionExpertCRM/evaluation.service"
import { selectionExpertArtistIdGetStore } from "../../../stores/CRM/SelectionExpertCRM/selectionExpertEvaluation.store"

const SelectionExpertEvaluationRead: FC = () => {
  const history = useHistory()
  const { artistId } = useParams()

  useEffect(() => {
    if (artistId) {
      getSelectionExpertArtistIdData(artistId).catch((error) => {
        const errorResponseData: RequestErrorResponse | undefined =
          error.response?.data

        if (errorResponseData?.errors[0].code) {
          history.push(PATH_SELECTION_EXPERT_DASHBOARD)
        }
      })
    } else {
      history.push(PATH_SELECTION_EXPERT_DASHBOARD)
    }

    return (): void => {
      selectionExpertArtistIdGetStore.clearData()
    }
  }, [])

  const goBack = (): void => {
    history.push(PATH_SELECTION_EXPERT_DASHBOARD)
  }

  const { isLoading, artist } = selectionExpertArtistIdGetStore

  return (
    <SelectionExpertEvaluationComponent
      isLoading={isLoading}
      artistData={artist}
      onBackClick={goBack}
    />
  )
}

export default observer(SelectionExpertEvaluationRead)

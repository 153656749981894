import React from 'react'
import ArtistHeaderContainer from '../../../../containers/CRM/ArtistCRM/ArtistHeader/ArtistHeader.container'
import Footer from '../../../common/Footer/Footer.component'

import './ArtistCRMLayout.style.sass'

interface PropTypes {
  children: React.ReactNode;
}

const block = 'artist-crm-layout'

export default class ArtistCRMLayout extends React.Component<PropTypes> {
  render(): JSX.Element {
    return (
      <div className={block}>
        <ArtistHeaderContainer />
        <div className={`${block}__body`}>
          <div className="container">
            {this.props.children}
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

import React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow.svg'

import "./AdminHeader.style.sass"
import classNames from "classnames"

interface PropTypes {
  title?: string;
  backRoute?: string;
  isStatic?: boolean;
}

const block = "admin-header"

export default class AdminHeader extends React.Component<
  PropTypes & RouteComponentProps
> {
  render(): JSX.Element {
    const { title, backRoute, isStatic } = this.props
    return (
      <div className={classNames(block, isStatic && `${block}--static`)}>
        <h1 className={`${block}__title`}>
          {backRoute && (
            <div
              onClick={(): void => this.props.history.push(backRoute)}
              className={`${block}__title-back`}
            >
              <Arrow fill="#000" />
            </div>
          )}
          {title}
        </h1>
      </div>
    )
  }
}

withRouter(AdminHeader)
import { action, observable } from 'mobx'

export type SheduleScreenName = 'LIST' | 'ARTISTS'

class SheduleManageStore {
  @observable activeScreenName: SheduleScreenName;

  @action
  setActiveScreen(screenName: SheduleScreenName): void {
    this.activeScreenName = screenName
  }

  constructor(screenName: SheduleScreenName) {
    this.activeScreenName = screenName
  }
}

export const sheduleManageStore: SheduleManageStore = new SheduleManageStore('LIST')

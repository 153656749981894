import React from 'react'
import RegisterFormComponent from '../../components/RegisterForm/RegisterForm.component'
import { RegisterFormFields } from '../../models/AuthModels/register.model'
import { registerUser } from '../../services/MainPage/auth.service'
import { observer } from 'mobx-react'
import { registrationStore } from '../../stores/MainPage/auth.store'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { PATH_ARTIST_NOTIFICATIONS } from '../../const/routes.const'

@observer
class RegisterFormContainer extends React.Component<RouteComponentProps> {
  onSubmit = (values: RegisterFormFields): void => {
    registerUser(values).then(() => this.props.history.push(PATH_ARTIST_NOTIFICATIONS))
  }

  onInputChange(): void {
    if (registrationStore.requestErrors) {
      registrationStore.clearErrors()
    }
  }

  render(): JSX.Element {
    return(
      <RegisterFormComponent
        onSubmit={this.onSubmit}
        onInputChange={this.onInputChange}
        isLoading={registrationStore.isLoading}
        submittionErrors={registrationStore.requestErrors}
      />
    )
  }
}

export default withRouter(RegisterFormContainer)

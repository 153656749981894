import React from 'react'
import Button from '../Button/Button.component'
import classNames from 'classnames'

import './AuthInfo.style.sass'

interface PropTypes {
  title: string;
  text: string;
  buttonText: string;
  onClick: () => void;
}

const block = "auth-info"

export default class AuthInfoComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { title, text, buttonText, onClick } = this.props

    return(
      <div className={block}>
        <div className={`${block}__title`}>
          {title}
        </div>
        <div className={classNames(`${block}__text`, 'margin', 'margin--top-m')}>
          {text}
        </div>
        <div className="margin margin--top-m">
          <Button text={buttonText} background="transparent" onClick={onClick} />
        </div>
      </div>
    )
  }
}
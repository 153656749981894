import { autoserialize, autoserializeAs } from "cerialize"
import { ScenePlatformInfo } from "./scene.model"

export type PerformanceStatuses = 'PENDING' | 'APPROVED' | 'DECLINED' 

export class IntervalModel {
  @autoserialize start: string;
  @autoserialize end: string;

  constructor(start: string, end: string) {
    this.start = start
    this.end = end
  }
}

export class PerformanceArtistGenre {
  @autoserialize label: string;
  @autoserialize name: string;

  constructor(label: string, name: string) {
    this.label = label
    this.name = name
  }
}

export interface PerformanceArtistFilterModel {
  genre?: string;
  maxMembersCount?: number;
  freeDate?: string;
  freeStartTime?: string;
  freeEndTime?: string;
}

export class PerformanceArtistModel {
  @autoserialize id: number;
  @autoserialize avatar: string | null;
  @autoserialize name: string;
  @autoserializeAs(PerformanceArtistGenre) genre: PerformanceArtistGenre;
  @autoserializeAs('form_id') formId: number | null;

  constructor(id: number, avatar: string | null, name: string, genre: PerformanceArtistGenre, formId: number | null) {
    this.id = id
    this.avatar = avatar
    this.name = name
    this.genre = genre
    this.formId = formId
  }
}

export class PerformanceArtistGetRequest {
  @autoserializeAs('place_form') sceneId: number;
  @autoserialize genre?: string;
  @autoserializeAs('max_members_count') maxMembersCount?: number;
  @autoserializeAs('free_date') freeDate?: string;
  @autoserializeAs('free_time_start') freeStartTime?: string;
  @autoserializeAs('free_time_end') freeEndTime?: string;
  @autoserialize search?: string;
  @autoserialize limit?: number;
  @autoserialize offset?: number;

  constructor(sceneId: number) {
    this.sceneId = sceneId
  }
}

export class PerformanceArtistGetResponse {
  @autoserializeAs(PerformanceArtistModel) artists: PerformanceArtistModel[];
  @autoserialize count: number;

  constructor(artists: PerformanceArtistModel[], count: number) {
    this.artists = artists
    this.count = count
  }
}

export class PerformanceGetModel {
  @autoserialize id: number;
  @autoserializeAs('area_form') areaForm: ScenePlatformInfo;
  @autoserializeAs('place_form') placeForm: number;
  @autoserializeAs(PerformanceArtistModel) artist: PerformanceArtistModel;
  @autoserialize date: string;
  @autoserializeAs('start_time') startTime: string;
  @autoserializeAs('end_time') endTime: string;
  @autoserializeAs('soundcheck_date') soundcheckDate: string;
  @autoserializeAs('soundcheck_start_time') soundcheckStartTime: string;
  @autoserializeAs('soundcheck_end_time') soundcheckEndTime: string;
  @autoserialize status: PerformanceStatuses;

  constructor(
    id: number,
    areaForm: ScenePlatformInfo,
    placeForm: number,
    artist: PerformanceArtistModel,
    date: string,
    startTime: string,
    endTime: string,
    soundcheckDate: string,
    soundcheckStartTime: string,
    soundcheckEndTime: string,
    status: PerformanceStatuses
  ) {
    this.id = id
    this.areaForm = areaForm
    this.placeForm = placeForm
    this.artist = artist
    this.date = date
    this.startTime = startTime
    this.endTime = endTime
    this.soundcheckDate = soundcheckDate
    this.soundcheckStartTime = soundcheckStartTime
    this.soundcheckEndTime = soundcheckEndTime
    this.status = status
  }
}

export class ScheduleTimelineItem {
  @autoserialize start: string;
  @autoserialize end: string;
  @autoserialize type: 'performance' | 'soundcheck';
  @autoserialize name: string;
  @autoserializeAs('performance_id') performanceId: number;

  constructor(
    start: string,
    end: string,
    type: 'performance' | 'soundcheck',
    name: string,
    performanceId: number
  ) {
    this.start = start
    this.end = end
    this.type = type
    this.name = name
    this.performanceId = performanceId
  }
}

export class PerformanceListResponse {
  @autoserializeAs(PerformanceGetModel) performances?: PerformanceGetModel[];
  @autoserializeAs(ScheduleTimelineItem) timeline?: ScheduleTimelineItem[];
}

export class PerfomanceSaveRequest {
  @autoserialize date: string;
  @autoserializeAs('start_time') startTime: string;
  @autoserializeAs('end_time') endTime: string;
  @autoserializeAs('soundcheck_date') soundcheckDate: string;
  @autoserializeAs('soundcheck_start_time') soundcheckStartTime: string;
  @autoserializeAs('soundcheck_end_time') soundcheckEndTime: string;

  constructor(
    date: string,
    startTime: string,
    endTime: string,
    soundcheckDate: string,
    soundcheckStartTime: string,
    soundcheckEndTime: string
  ) {
    this.date = date
    this.startTime = startTime
    this.endTime = endTime
    this.soundcheckDate = soundcheckDate
    this.soundcheckStartTime = soundcheckStartTime
    this.soundcheckEndTime = soundcheckEndTime
  }
}

export class TimelineGetResponse {
  @autoserializeAs(IntervalModel, 'place_busy_intervals') placeBusyIntervals: IntervalModel[];
  @autoserializeAs(IntervalModel, 'artist_busy_intervals') artistBusyIntervals: IntervalModel[];
  @autoserializeAs(ScheduleTimelineItem, 'artist_timeline') artistTimeline: ScheduleTimelineItem[];

  constructor(
    placeBusyIntervals: IntervalModel[], 
    artistBusyIntervals: IntervalModel[],
    artistTimeline: ScheduleTimelineItem[]
  ) {
    this.placeBusyIntervals = placeBusyIntervals
    this.artistBusyIntervals = artistBusyIntervals
    this.artistTimeline = artistTimeline
  }
}

export class TimelineGetRequest {
  @autoserializeAs('place_form') sceneId: number;
  @autoserializeAs('artist') artistId: number;

  constructor(sceneId: number, artistId: number) {
    this.sceneId = sceneId
    this.artistId = artistId
  }
}

export class PerformanceCreateRequest {
  @autoserializeAs('place_form') sceneId: number;
  @autoserializeAs('artist') artistId: number;
  @autoserialize date: string;
  @autoserializeAs('start_time') startTime: string;
  @autoserializeAs('end_time') endTime: string;
  @autoserializeAs('soundcheck_date') soundcheckDate: string;
  @autoserializeAs('soundcheck_start_time') soundcheckStartTime: string;
  @autoserializeAs('soundcheck_end_time') soundcheckEndTime: string;
  
  constructor(
    sceneId: number,
    artistId: number,
    date: string,
    startTime: string,
    endTime: string,
    soundcheckDate: string,
    soundcheckStartTime: string,
    soundcheckEndTime: string
  ) {
    this.sceneId = sceneId
    this.artistId = artistId
    this.date = date
    this.startTime = startTime
    this.endTime = endTime
    this.soundcheckDate = soundcheckDate
    this.soundcheckStartTime = soundcheckStartTime
    this.soundcheckEndTime = soundcheckEndTime
  }
}
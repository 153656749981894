import { ApiRequestStore } from "../../../common/apiRequest.store"
import { action, observable } from "mobx"
import { ListExpertModel, ExpertListGetResponse } from "../../../../models/ExpertsModels/expertList.model"

class ExpertListStore extends ApiRequestStore {
  @observable experts?: ListExpertModel[]

  @action
  setExpertsData(expertsData: ExpertListGetResponse): void {
    this.experts = expertsData.experts
  }

  @action
  clearData = (): void => {
    this.experts = undefined
  }

  getFilteredExperts = (value: string): ListExpertModel[] | [] => {
    if (this.experts) {
      return this.experts.filter(expert => (
        expert.email.toLowerCase().indexOf(value) >= 0 || 
          (expert.name && expert.name.toLowerCase().indexOf(value) >= 0)
      ))
    } else {
      return []
    }
  }
}

export const expertListStore: ExpertListStore = new ExpertListStore()

import React from 'react'
import { Switch, withRouter, RouteComponentProps, Route } from 'react-router-dom'
import { PATH_ARTIST_FORM, PATH_ARTIST_NOTIFICATIONS } from '../../../const/routes.const'
import ArtistCRMLayout from './ArtistCRMLayout/ArtistCRMLayout.component'
import ArtistFormContainer from '../../../containers/CRM/ArtistCRM/ArtistForm/ArtistForm.container'
import ArtistNotificationsContainer from '../../../containers/CRM/ArtistCRM/ArtistNotifications/'
import { observer } from 'mobx-react'
import { getArtistFormNotifications } from '../../../services/CRM/ArtistCRM/artistFormNotifications.services'
import { artistFormNotificationsStore } from '../../../stores/CRM/ArtistCRM/artistFormNotifications.store'
import { ReactComponent as Loader } from '../../../assets/icons/loading.svg'

import './ArtistCRM.style.sass'

const block = 'artist-crm'

@observer
class ArtistCRM extends React.Component<RouteComponentProps> {
  componentDidMount(): void {
    getArtistFormNotifications().then(() => {
      artistFormNotificationsStore.notificationsData &&
        artistFormNotificationsStore.notificationsData.notifications.length ? (
          this.props.history.replace(PATH_ARTIST_NOTIFICATIONS)
        ) : (
          this.props.history.replace(PATH_ARTIST_FORM)
        )
    })
  }

  render(): JSX.Element {
    return (
      artistFormNotificationsStore.notificationsData ? (
        <Switch>
          <Route
            path={PATH_ARTIST_NOTIFICATIONS}
          >
            <ArtistCRMLayout>
              <ArtistNotificationsContainer />
            </ArtistCRMLayout>
          </Route>
          <Route
            path={PATH_ARTIST_FORM}
          >
            <ArtistCRMLayout>
              <ArtistFormContainer />
            </ArtistCRMLayout>
          </Route>
        </Switch>
      ) : (
        <div className={`${block}__loading`}>
          <Loader fill="#933ec5" width="100" height="100" />
        </div>
      )
    )
  }
}

export default withRouter(ArtistCRM)
import React from 'react'
import FilesGroupListComponent from '../../../../components/CRM/AdminCRM/FilesGroupList/FilesGroupList.component'
import { getFilesGroupList } from '../../../../services/CRM/AdminCRM/files/fileGroups.service'
import { fileGroupsStore } from '../../../../stores/CRM/AdminCRM/files/fileGroups.store'
import { observer } from 'mobx-react'
import { FileUploadResponse } from '../../../../models/common/fileUpload.model'
import JSZip from 'jszip'
import { RouteComponentProps, withRouter } from 'react-router-dom'

const fileGroupsToLoad = 10

@observer
class FilesGroupListContainer extends React.Component<RouteComponentProps> {
  componentDidMount(): void {
    const searchString = new URLSearchParams(this.props.location.search).get("name") || undefined

    this.getFilesGroupList(searchString, fileGroupsToLoad)
  }

  componentWillUnmount(): void {
    fileGroupsStore.clearErrors()
    fileGroupsStore.clearData()
  }

  getFilesGroupList = (search?: string, limit?: number, offset?: number): void => {
    const requestParams = {
      limit,
      offset,
      search
    }
    const pathname = this.props.location.pathname

    getFilesGroupList(requestParams, pathname)
  }

  downloadAllFiles = (files: FileUploadResponse[]): void => {
    const zip = new JSZip
    const zipFilename = "zipFilename.zip"
    const urls: {
      url: string; 
      name: string;
    }[] = files.map(file => ({
      url: file.url, 
      name: file.name
    }))

    urls.forEach(async (file, index) => {
      const filename = file.name

      const blob = await fetch(file.url).then((response) => {
        if (response.status < 400) {
          return response.blob()
        } else {
          return null
        }
      })
      
      if (blob) {
        zip.file(filename, blob, {binary:true})
      }
      
      if (index === urls.length - 1) {
        zip.generateAsync({type:'blob'}).then(function(content) {
          saveAs(content, zipFilename)
        })
      }
    })
  }

  getShouldLoadMore = (): boolean => {
    const { count, places } = fileGroupsStore

    if (count && places) {
      return count > places.length
    } else return true
  }

  loadMore = (): void => {
    const { currentOffset } = fileGroupsStore

    if (!fileGroupsStore.isLoading) {
      this.getFilesGroupList(
        new URLSearchParams(this.props.location.search).get("name") || undefined,
        fileGroupsToLoad,
        currentOffset ? (
          currentOffset + fileGroupsToLoad
        ) : fileGroupsToLoad
      )
  
      fileGroupsStore.setOffset(currentOffset ? currentOffset + fileGroupsToLoad : fileGroupsToLoad)
    }
  }

  render(): JSX.Element {
    return (
      <FilesGroupListComponent 
        items={fileGroupsStore.places} 
        isLoading={fileGroupsStore.isLoading}
        onDownloadAllFiles={this.downloadAllFiles}
        shouldLoadMore={this.getShouldLoadMore()}
        onLoadMore={this.loadMore}
        onSearchChange={(value: string): void => {
          fileGroupsStore.clearData()

          this.getFilesGroupList(value)
        }}
      />
    )
  }
}

export default withRouter(FilesGroupListContainer)
import { ApiRequestStore } from "../../../common/apiRequest.store"
import { action, observable } from "mobx"
import { PlatformExpertModel } from "../../../../models/ScenesModels/experts.model"

class SceneExpertsStore extends ApiRequestStore {
  @observable experts?: PlatformExpertModel[];

  @action
  setSceneExpertsData(data: PlatformExpertModel[]): void {
    this.experts = data
  }

  @action
  clearData = (): void => {
    this.experts = undefined
  }

  @action
  addExpert = (expertData: PlatformExpertModel): void => {
    if (this.experts) {
      this.experts = [
        expertData,
        ...this.experts
      ]
    }
  }
}

class PlatformExpertsStore extends ApiRequestStore {
  @observable experts?: PlatformExpertModel[];

  @action
  setSceneExpertsData(data: PlatformExpertModel[]): void {
    this.experts = data
  }

  @action
  clearData = (): void => {
    this.experts = undefined
  }

  @action
  addExpert = (expertData: PlatformExpertModel): void => {
    if (this.experts) {
      this.experts = [
        expertData,
        ...this.experts
      ]
    }
  }
}

export const sceneExpertsStore: SceneExpertsStore = new SceneExpertsStore()
export const platformExpertsStore: PlatformExpertsStore = new PlatformExpertsStore()

import { action, observable } from 'mobx'
import { TimelineGetResponse } from '../../../../models/ScenesModels/performances.model'
import { ApiRequestStore } from '../../../common/apiRequest.store'

class PerformanceTimelineStore extends ApiRequestStore {
  @observable timelineData?: TimelineGetResponse;

  @action
  setTimelineData(timelineData: TimelineGetResponse): void {
    this.timelineData = timelineData
  }

  @action
  clearData = (): void => {
    this.timelineData = undefined
  }
}

export const performanceTimelineStore: PerformanceTimelineStore = new PerformanceTimelineStore()

import React from 'react'
import {
  Route,
  Redirect,
  RouteProps
} from 'react-router-dom'

export class PrivateRoute extends Route<RouteProps> {
  render(): JSX.Element {
      const authPath = '/'
      let redirectPath = ''

      if (!localStorage.getItem('authToken')?.length) {
        redirectPath = authPath
      }

      if (redirectPath) {
          const redirectComponent = (): JSX.Element => (<Redirect to={{pathname: redirectPath}}/>)
          return <Route {...this.props} component={redirectComponent} render={undefined}/>
      } else {
          return <Route {...this.props}/>
      }
  }
}
import React from "react"
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg"
import { ReactComponent as ClearIcon } from "../../assets/icons/search-clear.svg"

import "./Search.style.sass"

interface PropTypes {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  value: string;
}

const block = "search-component"

export default class SearchComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { value, onChange, onClear } = this.props
    return (
      <div className={block}>
        <SearchIcon className={`${block}__icon-search`} />
        <input
          className={`${block}__input`}
          onChange={onChange}
          value={value}
          placeholder="Искать"
        />
        {value.length > 0 && (
          <ClearIcon className={`${block}__icon-clear`} onClick={onClear} />
        )}
      </div>
    )
  }
}

import { observable, action } from "mobx"

export class FormCommentStore {
  @observable comment = "";

  @action
  setComment = (comment: string): void => {
    this.comment = comment
  };

  @action
  clearComment = (): void => {
    this.comment = ""
  };
}
export const formCommentStore: FormCommentStore = new FormCommentStore()

import { api } from "../../../../utils/api"
import { PlatformExpertsResponse } from "../../../../models/ScenesModels/experts.model"
import { Deserialize } from "cerialize"
import { sceneExpertsStore, platformExpertsStore } from "../../../../stores/CRM/AdminCRM/scenes/experts.store"
import { RequestErrorResponse } from "../../../../models/common/error.model"


export function getSceneExperts(sceneId: number): Promise<void> {
  sceneExpertsStore.setIsLoading(true)
  
  return api
    .get(`/crm/admin/place_forms/${sceneId}/experts/`)
    .then((res) => {

      const expertsData = Deserialize(
        res.data,
        PlatformExpertsResponse
      )
      
      sceneExpertsStore.setSceneExpertsData(expertsData.experts)
      sceneExpertsStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response.data
      sceneExpertsStore.setIsLoading(false)
      sceneExpertsStore.setErrors(errorResponseData.errors)
      throw error
    })
}

export function getPlatformExperts(platformId: number): Promise<void> {
  platformExpertsStore.setIsLoading(true)

  return api
    .get(`/crm/admin/area_forms/${platformId}/experts/`)
    .then((res) => {
      const expertsData = Deserialize(
        res.data,
        PlatformExpertsResponse
      )
      
      platformExpertsStore.setSceneExpertsData(expertsData.experts)
      platformExpertsStore.setIsLoading(false)
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response.data
      platformExpertsStore.setErrors(errorResponseData.errors)
      platformExpertsStore.setIsLoading(false)
      throw error
    })
}
import { api } from "../../../../utils/api"
import { RequestErrorResponse } from "../../../../models/common/error.model"
import { Serialize, Deserialize } from "cerialize"
import { SceneCreateRequest } from "../../../../models/ScenesModels/createScene.models"
import { createSceneStore } from "../../../../stores/CRM/AdminCRM/scenes/createScene.store"
import { SceneGetResponse } from "../../../../models/ScenesModels/scene.model"
import { scenesListStore } from "../../../../stores/CRM/AdminCRM/scenes/scenesList.store"

export function createScene(request: SceneCreateRequest, platformId: number): Promise<void> {
  const serializedData = Serialize(request, SceneCreateRequest)

  createSceneStore.setIsLoading(true)

  return api.post(
    `/crm/admin/area_forms/${platformId}/place_forms/`,
    serializedData
  ).then((res) => {
    const data: SceneGetResponse = Deserialize(
      res.data,
      SceneGetResponse
    )
    scenesListStore.addScene(data)
    createSceneStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    createSceneStore.setIsLoading(false)
    createSceneStore.setErrors(errorResponseData.errors)
    throw error
  })
}

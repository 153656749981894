import { ReactComponent as FB } from '../../../../assets/icons/fb.svg'
import { ReactComponent as Instagram } from '../../../../assets/icons/instagram.svg'
import { ReactComponent as VK } from '../../../../assets/icons/vk.svg'
import { ReactComponent as Telegram } from '../../../../assets/icons/tg-circle.svg'

interface ExpertSocialNetworksField {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name: string;
  placeholder: string;
}

export const expertSocial: ExpertSocialNetworksField[] = [
  {
    name: 'facebookUrl',
    icon: FB,
    placeholder: 'Facebook'
  },
  {
    name: 'instagramUrl',
    icon: Instagram,
    placeholder: 'Instagram'
  },
  {
    name: 'vkontakteUrl',
    icon: VK,
    placeholder: 'Vkontakte'
  },
  {
    name: 'telegramUrl',
    icon: Telegram,
    placeholder: 'Telegram'
  }
]
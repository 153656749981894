import { action, observable } from 'mobx'

class SceneModerationStore {
  @observable sceneName?: string;

  @action
  setSceneName(sceneName: string): void {
    this.sceneName = sceneName
  }
}

export const sceneModerationStore: SceneModerationStore = new SceneModerationStore()

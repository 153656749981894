import { ApiRequestStore } from "../../../common/apiRequest.store"
import { action, observable } from "mobx"
import { 
  ArtistIntersectionModel, 
  ArtistsIntersectionsGetResponse 
} from "../../../../models/ArtistsIntersectionsModels/ArtistsIntersections.model"

class ArtistsIntersectionsStore extends ApiRequestStore {
  @observable artistIntersections?: ArtistIntersectionModel[];
  @observable count?: number;
  @observable currentOffset?: number;

  @action
  setArtistFormsData(data: ArtistsIntersectionsGetResponse): void {
    if (this.artistIntersections && data.musicians) {
      this.artistIntersections = [...this.artistIntersections, ...data.musicians]
    } else {
      this.artistIntersections = data.musicians
    }
    this.count = data.count
  }

  @action
  setOffset = (newOffset: number): void => {
    this.currentOffset = newOffset
  }

  @action
  clearData = (): void => {
    this.artistIntersections = undefined
    this.count = undefined
    this.currentOffset = undefined
  }
}

export const artistsIntersectionsStore: ArtistsIntersectionsStore = new ArtistsIntersectionsStore()

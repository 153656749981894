import React from 'react'
import SceneInfoComponent from '../../../../../components/CRM/AdminCRM/PlatformModeration/SceneInfo/SceneInfo.component'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { sceneModerationStore } from '../../../../../stores/CRM/AdminCRM/scenes/sceneModeration.store'
import { observer } from 'mobx-react'
import { PATH_CRM_SCENES, PATH_PLATFORM_MODERATE } from '../../../../../const/routes.const'

@observer
class SceneInfoContainer extends React.Component<RouteComponentProps<{platformId: string; sceneId: string}>> {
  goToPlatformRoute = (subRoute: string): void => {
    const platformId = this.props.match.params.platformId
    const sceneId = this.props.match.params.sceneId

    this.props.history.push(`${PATH_PLATFORM_MODERATE}/${platformId}/scenes/${sceneId}${subRoute}`)
  }

  render(): JSX.Element {
    const platformId = parseInt(this.props.match.params.platformId)
    const sceneId = parseInt(this.props.match.params.sceneId)

    const isIdInvalid = isNaN(platformId) || isNaN(sceneId)

    return (
      <SceneInfoComponent 
        onBackClick={(): void => isIdInvalid ? (
          this.props.history.push(PATH_CRM_SCENES)
        ) : (
          this.props.history.push(`${PATH_PLATFORM_MODERATE}/${platformId}/scenes`)
        )}
        sceneName={sceneModerationStore.sceneName}
        onTabClick={this.goToPlatformRoute}
      />
    )
  }
}

export default withRouter(SceneInfoContainer)
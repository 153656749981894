import { ApiRequestStore } from "../../../common/apiRequest.store"
import { action, observable } from "mobx"
import { 
  PlatformListGetResponse, 
  ListPlatformModel, 
  AdminPlatformListCategories, 
  ExpertPlatformListGetResponse,
  ExpertListPlatformModel
} from "../../../../models/ScenesModels/platformList.model"
import { PlatformGetResponse } from "../../../../models/ScenesModels/platform.model"

class AdminPlatformListStore extends ApiRequestStore {
  @observable platformList?: PlatformListGetResponse;

  @action
  setPlatformListData(data: PlatformListGetResponse): void {
    this.platformList = data
  }

  @action
  getAdminPlatformsFiltered(
    query: string | null,
    data: PlatformListGetResponse | undefined
  ): PlatformListGetResponse | null {
    if (data) {
      if (query) {
        const res: {[key in AdminPlatformListCategories]} = {
          newCategory: [],
          remoderation: [],
          approved: [],
          checked: []
        }
        for (const key in data) {
          const arrFiltered = data[key].filter(
            item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
          )
          res[key] = arrFiltered
        }
        return res
      } else {
        return data
      }
    } else {
      return null
    }
  }

  @action
  clearData = (): void => {
    this.platformList = undefined
  }

  @action
  addPlatform = (platformData: PlatformGetResponse): void => {
    if (this.platformList) {
      this.platformList = {
        newCategory: [
          new ListPlatformModel(
            platformData.id,
            "EMPTY",
            platformData.name || ''
          ),
          ...this.platformList.newCategory
        ],
        remoderation: this.platformList?.remoderation,
        approved: this.platformList?.approved,
        checked: this.platformList?.checked
      }
    }
  }
}

class ExpertPlatformListStore extends ApiRequestStore {
  @observable platformList?: ExpertListPlatformModel[];

  @action
  setPlatformListData(data: ExpertPlatformListGetResponse): void {
    this.platformList = data.platforms
  }

  @action
  getExpertPlatformsFiltered(
    query: string | null,
    data?: ExpertListPlatformModel[]
  ): ExpertListPlatformModel[] | null {
    if (data) {
      if (query) {
        return (
          data.filter((platform: ExpertListPlatformModel) => (
            platform.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
          )
        )
      } else {
        return data
      }
    } else {
      return null
    }
  }

  @action
  clearData = (): void => {
    this.platformList = undefined
  }
}

export const adminPlatformListStore: AdminPlatformListStore = new AdminPlatformListStore()
export const expertPlatformListStore: ExpertPlatformListStore = new ExpertPlatformListStore()

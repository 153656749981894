import React from 'react'
import PerformanceListComponent from '../../../../../components/CRM/AdminCRM/PlatformModeration/PerformanceList'
import { sheduleManageStore } from '../../../../../stores/CRM/AdminCRM/scenes/sheduleManage.store'
import { 
  fetchPerformances, 
  getTimeline, 
  savePerformance,
  approvePerformance,
  deletePerformance
} from '../../../../../services/CRM/AdminCRM/scenes/performance.service'
import { performanceListStore } from '../../../../../stores/CRM/AdminCRM/scenes/performanceList.store'
import { observer } from 'mobx-react'
import { performanceTimelineStore } from '../../../../../stores/CRM/AdminCRM/scenes/timelineStore'
import { PerfomanceSaveRequest } from '../../../../../models/ScenesModels/performances.model'
import { performanceSaveStore } from '../../../../../stores/CRM/AdminCRM/scenes/performanceSave.store'
import { 
  performanceApproveStore, 
  performanceDeleteStore 
} from '../../../../../stores/CRM/AdminCRM/scenes/performanceModeration.store'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { PATH_ADMIN_ARTIST_MODERATE } from '../../../../../const/routes.const'
import { performanceUIStore } from '../../../../../stores/CRM/AdminCRM/scenes/performanceUI.store'

interface State {
  isDeleteModalVisible: boolean;
  deletingPerformanceId?: number;
}

@observer
class PerformanceListContainer extends React.Component<RouteComponentProps<{sceneId: string}>, State> {
  state: State = {
    isDeleteModalVisible: false
  }

  onArtistAddClick = (): void => {
    sheduleManageStore.setActiveScreen('ARTISTS')
  }
  
  componentDidMount = (): void => {
    const sceneId = parseInt(this.props.match.params.sceneId)
    
    fetchPerformances(sceneId)
  }

  componentWillUnmount = (): void => {
    performanceListStore.clearErrors()
    performanceListStore.clearData()
    performanceTimelineStore.clearData()
    performanceUIStore.setActivePerformanceId(undefined)
  }

  getTimeline = (sceneId: number, artistId: number): void => {
    getTimeline({sceneId, artistId})
  }

  onPerformanceOpen = (performanceid: number, artistId: number): void => {
    const sceneId = parseInt(this.props.match.params.sceneId)

    performanceTimelineStore.clearData()
    this.getTimeline(sceneId, artistId)
    this.setVisibleTimelineId(performanceid)
  }

  setVisibleTimelineId = (id?: number): void => {
    performanceUIStore.setActivePerformanceId(id)
  }

  savePerformance = (values: PerfomanceSaveRequest, performanceId: number): void => {
    savePerformance(values, performanceId)
  }

  approvePerformance = (performanceId: number, values: PerfomanceSaveRequest): void => {
    savePerformance(values, performanceId).then(() => approvePerformance(performanceId))
  }

  deletePerformance = (): void => {
    if (this.state.deletingPerformanceId) {
      deletePerformance(this.state.deletingPerformanceId)
        .then(() => this.hideDeleteModal())
    }
  }

  hideDeleteModal = (): void => {
    this.setState({
      isDeleteModalVisible: false,
      deletingPerformanceId: undefined
    })
  }

  showDeleteModal = (performanceId: number): void => {
    this.setState({
      deletingPerformanceId: performanceId,
      isDeleteModalVisible: true
    })
  }

  closeErrorsModal = (): void => {
    const sceneId = parseInt(this.props.match.params.sceneId)
    
    performanceSaveStore.clearErrors()
    performanceDeleteStore.clearErrors()
    performanceApproveStore.clearErrors()

    if (performanceUIStore.activePerformanceId) {
      this.getTimeline(sceneId, performanceUIStore.activePerformanceId)
    }
  }

  goToArtist = (artistFormId: number | null, artistId: number): void => {
    const url = !!artistFormId ? (
      `${document.location.origin}${PATH_ADMIN_ARTIST_MODERATE}/${artistFormId}`
    ) : (
      `https://uralmusicnight.ru/artist/${artistId}`
    )

    const win = window.open(url, '_blank')
    win && win.focus()
  }

  render(): JSX.Element {
    return (
      <PerformanceListComponent 
        onArtistAddClick={this.onArtistAddClick}
        performances={performanceListStore.performances}
        isPerformancesLoading={performanceListStore.isLoading} 
        onPerformanceOpen={this.onPerformanceOpen}
        onPerformanceClose={this.setVisibleTimelineId}
        activePerformanceId={performanceUIStore.activePerformanceId}
        isTimelineLoading={performanceTimelineStore.isLoading}
        onPerformanceSave={this.savePerformance}
        isSaveLoading={performanceSaveStore.isLoading}
        timelineData={performanceTimelineStore.timelineData}
        onApprove={this.approvePerformance}
        onDelete={this.showDeleteModal}
        isApproveLoading={performanceApproveStore.isLoading}
        isDeleteLoading={performanceDeleteStore.isLoading}
        isDeleteModalVisible={this.state.isDeleteModalVisible}
        onDeleteModalClose={this.hideDeleteModal}
        onDeleteModalApply={this.deletePerformance}
        requestErrors={
          performanceSaveStore.requestErrors 
            || performanceApproveStore.requestErrors 
              || performanceDeleteStore.requestErrors
        }
        onErrorsModalClose={this.closeErrorsModal}
        onEyeClick={this.goToArtist}
      />
    )
  }
}

export default withRouter(PerformanceListContainer)
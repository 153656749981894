import React from 'react'
import FormHeaderComponent from '../../ArtistCRM/FormHeader/FormHeader.component'
import { platformFormSaveStore } from '../../../../stores/CRM/AdminCRM/scenes/platformGetStore'
import { sceneFormSaveStore } from '../../../../stores/CRM/AdminCRM/scenes/sceneForm.store'
import { 
  ExpertProfileSaveRequest, 
  ExpertProfilePlatformModel 
} from '../../../../models/ExpertsModels/expertProfile.model'
import ExpertPlatformComponent from './ExpertPlatform/ExpertPlatform.component'
import { withTypes, Field } from 'react-final-form'
import InputComponent from '../../common/Input/Input.component'
import { Element, scroller } from 'react-scroll'
import InputLabelComponent from '../../ArtistCRM/ArtistForm/InputLabel/InputLabel.component'
import { fieldRequired } from '../../../../const/validators.const'
import { expertSocial } from './data.const'
import TextAreaComponent from '../../common/TextArea/TextArea.component'
import classNames from 'classnames'
import UploadFileContainer from '../../../../containers/CRM/common/UploadFile.container'
import { ValidationErrors } from 'final-form'

import './ExpertProfile.style.sass'
import Button from '../../../Button/Button.component'
import LoaderComponent from '../../../common/Loader/Loader.component'
import { Link } from 'react-router-dom'
import { PATH_PLATFORM_MODERATE } from '../../../../const/routes.const'

interface PropTypes {
  formInitialValues?: ExpertProfileSaveRequest;
  onSave: (values: ExpertProfileSaveRequest) => void;
  onBack: () => void;
  platforms?: ExpertProfilePlatformModel[];
  showValidationErrors: boolean;
  onShowErrors: () => void;
  isSaving: boolean;
  isLoading: boolean;
  userEmail: string;
}

const { Form } = withTypes<ExpertProfileSaveRequest>()
const block = "expert-profile-page"

export default class ExpertProfileComponent extends React.Component<PropTypes> {
  private formValues?: ExpertProfileSaveRequest;
  private handleSubmit?: () => void;
  private errors?: ValidationErrors;

  onSave = (): void => {
    this.scrollToFirstError(this.errors || {})
    this.props.onShowErrors()
    this.handleSubmit && this.handleSubmit()
  }

  scrollToFirstError = (errors: ValidationErrors): void => {
    const errorFields = Object.keys(errors)

    scroller.scrollTo(errorFields[0], {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -230
    })
  }

  render(): JSX.Element {
    const { formInitialValues, onSave, onBack, platforms, isSaving, isLoading, userEmail } = this.props

    return (
      <div className={block}>
        <FormHeaderComponent
          onSave={this.onSave}
          isStatic={true}
          isHeaderFading={false}
          onBack={onBack}
          title="ПРОФИЛЬ КООРДИНАТОРА"
          hasExitButton={true}
          hasBackButton={true}
          hasSaveButton={true}
          saveTime={platformFormSaveStore.saveTime || sceneFormSaveStore.saveTime}
        >
          {this.props.children}
        </FormHeaderComponent>

        <div className={`${block}__body`}>
          {isLoading ? (
            <LoaderComponent width={100} height={100} />
          ) : (
            <div className="container">
              {platforms && (
                <div className={`${block}__platforms`}>
                  <div className="form__title form__title--black">
                    Площадки
                  </div>
                  
                  {platforms.length ? (
                    <div className={`${block}__platforms-list margin--top-l`}>
                      {platforms.map(platform => (
                        <Link 
                          to={`${PATH_PLATFORM_MODERATE}/${platform.id}`} 
                          key={platform.id}
                          className={`${block}__platform-link`}
                        >
                          <ExpertPlatformComponent 
                            image={platform.logo}
                            title={platform.name}
                          />
                        </Link>
                      ))} 
                    </div>
                  ) : (
                    <div className={`${block}__no-platforms-label margin--top-l`}>Нет площадок</div>
                  )}
                </div>
              )}

              <Form
                onSubmit={onSave}
                initialValues={formInitialValues}
                render={({
                  values,
                  handleSubmit,
                  errors
                }): JSX.Element => {
                  this.formValues = values
                  this.handleSubmit = handleSubmit
                  this.errors = errors

                  return (
                    <form 
                      className={`${block}__form form margin--top-l`}
                      onSubmit={handleSubmit}
                      id="scene-form"
                    >
                      <div>
                        <div className="form__title">
                          Информация об эксперте
                        </div>

                        <div className="form__form-block">

                          <div className="form__input-block">
                            <div className="form__input-title">
                              * ФИО
                            </div>
                            <div className="form__input margin margin--top-xxs">
                              <Field name="name" validate={fieldRequired}>
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <InputComponent
                                      placeholder="Введите текст"
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      error={meta.touched && meta.error}
                                      onBlur={input.onBlur}
                                      onFocus={input.onFocus}
                                    />
                                  </Element>
                                )}
                              </Field>
                            </div>
                          </div>
                          
                          <div className="form__input-row margin--top-s">
                            <div className="form__input-block">
                              <div className="form__input-title">
                                * Email
                              </div>
                              <div className="form__input margin margin--top-xxs">
                                <Field name="email">
                                  {({ input, meta }): JSX.Element => (
                                    <Element name={input.name}>
                                      <InputComponent
                                        placeholder="Введите email"
                                        value={input.value}
                                        name={input.name}
                                        onChange={input.onChange}
                                        error={meta.touched && meta.error}
                                        onBlur={input.onBlur}
                                        onFocus={input.onFocus}
                                        disabled={true}
                                      />
                                    </Element>
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className="form__input-block">
                              <div className="form__input-title">
                                Телефон
                              </div>
                              <div className="form__input margin margin--top-xxs">
                                <Field 
                                  name="phone"
                                  parse={(value): string => {
                                    if (value === '') {
                                      return value
                                    } else {
                                      const newValue = value.replace(/\D/g, '')
                                      return `+${newValue}`
                                    }
                                  }}
                                >
                                  {({ input, meta }): JSX.Element => (
                                    <Element name={input.name}>
                                      <InputComponent
                                        placeholder="Введите телефон"
                                        value={input.value}
                                        name={input.name}
                                        onChange={input.onChange}
                                        error={meta.touched && meta.error}
                                        onBlur={input.onBlur}
                                        onFocus={input.onFocus}
                                      />
                                    </Element>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div className="form__input-block margin--top-s">
                            <div className="form__input-title">
                              * Социальные сети
                            </div>
                            <div className="form__input-row">
                              {expertSocial.map((inputData): JSX.Element => (
                                <div className="form__input margin margin--top-s" key={inputData.name}>
                                  <Field
                                    name={inputData.name}
                                  >
                                    {({ input, meta }): JSX.Element => (
                                      <Element name={input.name}>
                                        <InputComponent
                                          placeholder={inputData.placeholder}
                                          value={input.value}
                                          name={input.name}
                                          onChange={input.onChange}
                                          icon={inputData.icon}
                                          error={meta.error}
                                          onBlur={input.onBlur}
                                          onFocus={input.onFocus}
                                        />               
                                      </Element>
                                    )}
                                  </Field>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="form__input-block margin--top-s">
                            <div className="form__input-title">
                              Краткая информация об эксперте
                            </div>
                            <div className="form__input margin margin--top-xxs">
                              <Field
                                name="description"
                              >
                                {({ input, meta }): JSX.Element => (
                                  <Element name={input.name}>
                                    <TextAreaComponent
                                      placeholder="Введите текст"
                                      value={input.value}
                                      name={input.name}
                                      onChange={input.onChange}
                                      error={meta.touched && meta.error}
                                      onBlur={input.onBlur}
                                      onFocus={input.onFocus}
                                    />
                                    <div className="form__input-label">
                                      <InputLabelComponent isFocused={meta.active}>
                                        Опишите вкратце для буклета вашу биографию и опыт организации событий.
                                      </InputLabelComponent>
                                    </div>
                                  </Element>
                                )}
                              </Field>
                            </div>
                          </div>

                          <div className="form__input-block margin--top-s">
                            <div className={classNames(
                              "form__input-title",
                              (this.props.showValidationErrors && errors.photo) && "form__input-title--error"
                            )}>
                              * Фотография эксперта
                            </div>
                            <div className="form__input margin margin--top-xs">
                              <Field name="photo" validate={fieldRequired}>
                                {({ input }): JSX.Element => (
                                  <Element name={input.name}>
                                    <UploadFileContainer
                                      nameForStore={input.name}
                                      fileTypes={["image/jpeg", "image/png"]}
                                      onFileUpload={(uploadedFile): void => input.onChange(uploadedFile)}
                                      uploadedFile={values.photo ? values.photo : undefined}
                                      onFileDelete={(): void => {
                                        input.onChange(null)
                                      }}
                                      showFileName={false}
                                      minWidth={1000}
                                      minHeight={1000}
                                      userName={`expert_${userEmail}`}
                                      documentType={'avatar'}
                                    />
                                    <div className={classNames(
                                      "form__input-label margin--top-xs"
                                    )}>
                                      <InputLabelComponent>
                                        Квадратная фотография: 1000 х 1000 px.
                                      </InputLabelComponent>
                                    </div>
                                  </Element>
                                )}
                              </Field>
                            </div>
                          </div>

                        </div>
                                    
                        <div className="margin margin--top-m">
                          <Button
                            text="Сохранить"
                            background="purple"
                            onClick={this.onSave}
                            isLoading={isSaving}
                          />
                        </div>
                      </div>
                    </form>
                  )
                }}
              />
            </div>
          )}
        </div>

      </div>
    )
  }
}
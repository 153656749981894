import React from 'react'
import Dropzone, { DropEvent } from 'react-dropzone'
import { ReactComponent as Add } from '../../../../assets/icons/add.svg'
import { ReactComponent as Remove } from '../../../../assets/icons/remove.svg'
import { ReactComponent as Loader } from '../../../../assets/icons/loading.svg'
import { FileUploadLocalState } from '../../../../stores/common/fileUpload.store'
import { FileUploadResponse } from '../../../../models/common/fileUpload.model'
import { ReactComponent as Doc } from '../../../../assets/icons/doc.svg'

import './UploadFile.style.sass'

const block = 'upload-file'

interface PropTypes {
  onDrop: <T extends File>(acceptedFiles: T[], rejectedFiles: T[], event: DropEvent) => void;
  uploadedFileState: FileUploadLocalState;
  title?: string;
  fileTypes: string[];
  isImage: boolean;
  uploadedFile?: FileUploadResponse;
  showFileName: boolean;
  error: string;
  onDelete: (event: React.SyntheticEvent<HTMLElement>) => void;
  disabled?: boolean;
}

export default class UploadFileComponent extends React.Component<PropTypes> {
  onDropzoneClick = (): void => {
    if (this.props.uploadedFileState.uploadedFileData?.url) {
      window.open(this.props.uploadedFileState.uploadedFileData.url, "_blank")
    }

    if (this.props.uploadedFile?.url) {
      window.open(this.props.uploadedFile.url, "_blank")
    }
  }

  render(): JSX.Element {
    const { uploadedFileState, title, fileTypes, isImage, uploadedFile, error, onDelete } = this.props
    const disabled = 
      this.props.disabled || !!this.props.uploadedFileState.uploadedFileData?.url || !!this.props.uploadedFile?.url

    return (
      <Dropzone onDrop={this.props.onDrop} accept={fileTypes} disabled={disabled}>
        {({getRootProps, getInputProps}): JSX.Element => (
          <div className={block} onClick={this.onDropzoneClick}>
            {title && (
              <div className={`${block}__title`}>{title}</div>
            )}
            <div
              className={`${block}__dropzone`}
              style={
                uploadedFileState.uploadedFileData?.url && isImage ? ({
                  backgroundImage: 
                    `url(${uploadedFileState.uploadedFileData.urlSizeM || uploadedFileState.uploadedFileData.url})`
                }) : uploadedFile?.url && !uploadedFileState.isLoading ? ({
                  backgroundImage: `url(${uploadedFile.urlSizeM || uploadedFile.url})`
                }) : {}
              }
            >
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className={`${block}__control`}>
                  {(uploadedFileState.uploadedFileData?.url || uploadedFile?.url) && (
                    <>
                    <div className={`${block}__delete`} onClick={onDelete}>
                      <Remove width="20" height="20" />
                    </div>
                    <div className={`${block}__download-overlay`}>

                    </div>
                    </>
                  )}
                  {!uploadedFileState.isLoading && !uploadedFileState.uploadedFileData?.url && !uploadedFile?.url && (
                    <Add />
                  )}
                  {uploadedFileState.isLoading && (
                    <div className={`${block}__loader`}>
                      <Loader fill="#933ec5" />
                    </div>
                  )}
                  {(uploadedFileState.uploadedFileData?.url || uploadedFile?.url) && !isImage && uploadedFile?.url
                    && uploadedFile?.url.indexOf('.png') < 0 && uploadedFile?.url.indexOf('.jpg') < 0
                      && uploadedFile?.url.indexOf('.jpeg') < 0 && (
                    <div className={`${block}__file`}>
                      <Doc fill="#000" width="15" height="15" />
                      <div className={`${block}__file-name`}>
                        {uploadedFileState.uploadedFileData?.name || uploadedFile?.name}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={`${block}__error`}>{error}</div>
          </div>
        )}
      </Dropzone>
    )
  }
}

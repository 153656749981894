import React from 'react'
import classNames from 'classnames'
import { ReactComponent as SaveIcon } from '../../../../assets/icons/save.svg'
import { ReactComponent as ExitIcon } from '../../../../assets/icons/exit.svg'
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow.svg'
import CircleButtonComponent from '../../common/CircleButton/CircleButton.component'
import ButtonWithIconComponent from '../../common/ButtonWithIcon/ButtonWithIcon.component'
import moment from 'moment'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { clearLocalStorage } from '../../../../utils/localStorage'

import './FormHeader.style.sass'

interface PropTypes {
  onSave: () => void;
  requestStatus?: string;
  saveTime?: Date;
  isStatic: boolean;
  isHeaderFading: boolean;
  onBack?: () => void;
  hasSaveButton: boolean;
  title: string;
  children?: React.ReactNode;
  hasExitButton?: boolean;
  hasBackButton?: boolean;
  requestStatusTitle?: string;
}

const block = 'form-header'

class FormHeaderComponent extends React.Component<PropTypes & RouteComponentProps> {

  logout = (): void => {
    clearLocalStorage()
    this.props.history.push('/')
  }

  render(): JSX.Element {
    const { 
      onSave, 
      saveTime, 
      requestStatus, 
      isStatic, 
      isHeaderFading, 
      onBack, 
      hasSaveButton,
      title,
      hasExitButton,
      hasBackButton,
      requestStatusTitle
    } = this.props

    return (
      <div className={classNames(
        block, 
        !isStatic && `${block}--fixed`,
        isHeaderFading && `${block}--fading`
      )}>
        <div className="container row row--space-between">
          <div 
            className={classNames(
              `${block}__description`
            )}
          >
            <div className={classNames(`${block}__title`, 'page-title')}>
              {hasBackButton && (
                <div className={classNames(`${block}__back`)} onClick={onBack}>
                  <Arrow fill="#fff" />
                </div>
              )} 
              {title}
            </div>
            {isStatic && this.props.children}
          </div>
          <div className={`${block}__right`}>
            <div className={`${block}__controls`}>
              {hasSaveButton && (
                <ButtonWithIconComponent
                  icon={<SaveIcon fill="#933ec5" />}
                  text="Сохранить"
                  onClick={onSave}
                />
              )}
              {hasExitButton && (
                <CircleButtonComponent onClick={this.logout}>
                  <ExitIcon />
                </CircleButtonComponent>
              )}
            </div>
            <div 
              className={classNames(
                `${block}__info`,
                !hasSaveButton && !hasExitButton && `${block}__info--no-margin`
              )}
            >
              {saveTime && (
                <div className={`${block}__info-save`}>
                  Последнее сохранение: {moment(saveTime).format('HH:mm:ss')}
                </div>
              )}
              {isStatic && (
                <>
                {requestStatusTitle && (
                  <div className={`${block}__info-title`}>
                    {requestStatusTitle}
                  </div>
                )}
                {requestStatus && (
                  <div className={`${block}__info-status`}>
                    {requestStatus}
                  </div>
                )}
                
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(FormHeaderComponent)
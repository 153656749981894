import { ApiRequestStore } from "../../../common/apiRequest.store"
import {
  AdminArtistFormsGetResponse,
  ListArtistModel
} from "../../../../models/AdminArtistFormsModels/adminArtistForms.model"
import { action, observable } from "mobx"

class AdminArtistFormsGetStore extends ApiRequestStore {
  @observable artistForms?: ListArtistModel[];
  @observable count?: number;
  @observable currentOffset?: number;

  @action
  setArtistFormsData(data: AdminArtistFormsGetResponse): void {
    if (this.artistForms && data.artistForms) {
      this.artistForms = [...this.artistForms, ...data.artistForms]
    } else {
      this.artistForms = data.artistForms
    }
    this.count = data.count
  }

  @action
  setOffset = (newOffset: number): void => {
    this.currentOffset = newOffset
  }

  @action
  clearData = (): void => {
    this.artistForms = undefined
    this.count = undefined
    this.currentOffset = undefined
  }
}

export const adminArtistFormsGetStore: AdminArtistFormsGetStore = new AdminArtistFormsGetStore()

import { UserModel } from "../common/user.model"
import { serialize, deserialize, deserializeAs } from 'cerialize'

export class RegisterRequest {
  @serialize name: string;
  @serialize email: string;
  @serialize password: string;

  constructor(name: string, email: string, password: string) {
    this.name = name
    this.email = email
    this.password = password
  }
}

export class RegisterResponse {
  @deserialize token: string;
  @deserializeAs(UserModel) user: UserModel;

  constructor(token: string, user: UserModel) {
    this.token = token
    this.user = user
  }
}

export interface RegisterFormFields extends RegisterRequest {
  repeatPassword: string;
}

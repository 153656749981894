import { api } from "../../../utils/api"
import { artistFormNotificationsStore } from "../../../stores/CRM/ArtistCRM/artistFormNotifications.store"
import { 
  ArtistFormNotificationsResponse
} from "../../../models/ArtistFormNotificationsModels/ArtistFormNotifications.model"
import { Deserialize } from "cerialize"
import { RequestErrorResponse } from "../../../models/common/error.model"
import { artistModerationStore } from "../../../stores/CRM/AdminCRM/artists/artistModeration.store"

export function getArtistFormNotifications(): Promise<void> {
  artistFormNotificationsStore.setIsLoading(true)

  return api.get(
    '/crm/artist/form/notifications/'
  ).then((res) => {
    const data: ArtistFormNotificationsResponse = Deserialize(res.data, ArtistFormNotificationsResponse)

    artistModerationStore.setFormStatus(data.status)
    artistFormNotificationsStore.setNotificationsData(data)
    artistFormNotificationsStore.setIsLoading(false)
    
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse =  error.response.data
    artistFormNotificationsStore.setIsLoading(false)
    artistFormNotificationsStore.setErrors(errorResponseData.errors)
    throw error
  })
}
import { Deserialize } from "cerialize"
import { RequestErrorResponse } from "../../../models/common/error.model"
import { 
  SelectionExpertDashboardGetResponse
} from "../../../models/SelectionExpertsModels/selectionExpertDashboard.model"
import { selectionExpertDashboardStore } from "../../../stores/CRM/SelectionExpertCRM/selectionExpertDashboard.store"
import { api } from "../../../utils/api"

export function getSelectionExpertDashboardData(): Promise<void> {
  selectionExpertDashboardStore.setIsLoading(true)

  return api.get(
    '/crm/selection_expert/artist_forms/'
  ).then((res) => {
    const data: SelectionExpertDashboardGetResponse = Deserialize(res.data, SelectionExpertDashboardGetResponse)
    selectionExpertDashboardStore.setDashboardData(data)
    selectionExpertDashboardStore.setIsLoading(false)
    
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse =  error.response.data
    selectionExpertDashboardStore.setIsLoading(false)
    selectionExpertDashboardStore.setErrors(errorResponseData.errors)
    throw error
  })
}
import { ApiRequestStore } from '../../../common/apiRequest.store'
import { FileGroupsPlace, FileGroupsGetResponse } from '../../../../models/FilesModels/TechRiders.model'
import { action, observable } from 'mobx'

class FileGroupsStore extends ApiRequestStore {
  @observable places?: FileGroupsPlace[]
  @observable count?: number;
  @observable currentOffset?: number;

  @action
  setFileGroupData(data: FileGroupsGetResponse): void {
    if (this.places && data.places) {
      this.places = [...this.places, ...data.places]
    } else {
      this.places = data.places
    }
    this.count = data.count
  }

  @action
  setOffset = (newOffset: number): void => {
    this.currentOffset = newOffset
  }

  @action
  clearData = (): void => {
    this.places = undefined
    this.count = undefined
    this.currentOffset = undefined
  }
}

export const fileGroupsStore: FileGroupsStore = new FileGroupsStore()

import React from 'react'

import './ButtonWithIcon.style.sass'

interface PropTypes {
  onClick: (event: React.SyntheticEvent<HTMLElement>) => void;
  icon: JSX.Element;
  text: string;
}

const block = 'button-with-icon'

export default class ButtonWithIconComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { onClick, icon, text } = this.props

    return (
      <div className={block} onClick={onClick}>
        <div className={`${block}__icon`}>
          {icon}
        </div>
        {text}
      </div>
    )
  }
}

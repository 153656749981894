import { api } from "../../../../utils/api"
import { RequestErrorResponse } from "../../../../models/common/error.model"
import { createPlatformStore } from "../../../../stores/CRM/AdminCRM/scenes/createPlatform.store"
import { Serialize, Deserialize } from "cerialize"
import { PlatformCreateRequest } from "../../../../models/ScenesModels/createPlatform.models"
import { PlatformGetResponse } from "../../../../models/ScenesModels/platform.model"
import { adminPlatformListStore } from "../../../../stores/CRM/AdminCRM/scenes/platformList.store"

export function createPlatform(request: PlatformCreateRequest): Promise<void> {
  const serializedData = Serialize(request, PlatformCreateRequest)
  createPlatformStore.setIsLoading(true)

  return api.post(
    "/crm/admin/area_forms/",
    serializedData
  ).then((res) => {
    const data: PlatformGetResponse = Deserialize(
      res.data,
      PlatformGetResponse
    )
    adminPlatformListStore.addPlatform(data)
    createPlatformStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    createPlatformStore.setIsLoading(false)
    createPlatformStore.setErrors(errorResponseData.errors)
    throw error
  })
}

import React from 'react'
import Router from './Router'

const App: React.FC = () => (
    <div className="app-root">
      <Router />
    </div>
  )

export default App

import { deserializeAs, deserialize, serialize, serializeAs, autoserialize, autoserializeAs } from 'cerialize'
import { FileUploadResponse } from '../common/fileUpload.model'
import { PlatformStatus } from './platformList.model'

export class SceneGenreModel {
  @autoserialize label: string;
  @autoserialize name: string;

  constructor(label: string, name: string) {
    this.label = label
    this.name = name
  }
}

export class SceneExpertPhotoModel {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserialize url: string;

  constructor(id: number, name: string, url: string) {
    this.id = id
    this.name = name
    this.url = url
  }
}

export class SceneExpertsModel {
  @autoserialize id: number | null;
  @autoserialize email: string | null;
  @autoserializeAs(SceneExpertPhotoModel) photo: SceneExpertPhotoModel | null;

  constructor(id: number, email: string, photo: SceneExpertPhotoModel) {
    this.id = id
    this.email = email
    this.photo = photo
  }
}

export class SceneNumbersModel {
  @deserializeAs('posters_a4') @serializeAs('posters_a4') postersA4: string;
  @deserializeAs('posters_a3') @serializeAs('posters_a3') postersA3: string;
  @deserializeAs('posters_a2') @serializeAs('posters_a2') postersA2: string;
  @deserializeAs('posters_a1') @serializeAs('posters_a1') postersA1: string;
  @deserializeAs('posters_a0') @serializeAs('posters_a0') postersA0: string;
  @deserialize @serialize banners: string;
  @deserializeAs('flyers_a6') @serializeAs('flyers_a6') flyersA6: string;
  @deserializeAs('stretching_banners') @serializeAs('stretching_banners') stretchingBanners: string;
  @deserializeAs('table_tents_a5') @serializeAs('table_tents_a5') tableTentsA5: string;
  @deserializeAs('table_tents_a6') @serializeAs('table_tents_a6') tableTentsA6: string;

  constructor(
    postersA4: string,
    postersA3: string,
    postersA2: string,
    postersA1: string,
    postersA0: string,
    banners: string,
    flyersA6: string,
    stretchingBanners: string,
    tableTentsA5: string,
    tableTentsA6: string
  ) {
    this.postersA4 = postersA4
    this.postersA3 = postersA3
    this.postersA2 = postersA2
    this.postersA1 = postersA1
    this.postersA0 = postersA0
    this.banners = banners
    this.flyersA6 = flyersA6
    this.stretchingBanners = stretchingBanners
    this.tableTentsA5 = tableTentsA5
    this.tableTentsA6 = tableTentsA6
  }
}

export class ScenePlatformInfo {
  @autoserializeAs('approved_at') approvedAt: string | null;
  @autoserializeAs('checked_at') checkedAt: string | null;
  @autoserializeAs('created_at') createdAt: string;
  @autoserialize id: number;
  @autoserializeAs('moderated_at') moderatedAt: string | null;
  @autoserializeAs('sent_to_moderation_at') sentToModerationAt: string | null;
  @autoserialize status: PlatformStatus;
  @autoserializeAs('updated_at') updatedAt: string | null;

  constructor(
    approvedAt: string | null,
    checkedAt: string | null,
    createdAt: string,
    id: number,
    moderatedAt: string | null,
    sentToModerationAt: string | null,
    status: PlatformStatus,
    updatedAt: string | null
  ) {
    this.approvedAt = approvedAt
    this.checkedAt = checkedAt
    this.createdAt = createdAt
    this.id = id
    this.moderatedAt = moderatedAt
    this.sentToModerationAt = sentToModerationAt
    this.status = status
    this.updatedAt = updatedAt
  }
}

export class SceneGetResponse {
  @deserialize id: number;
  @deserializeAs('area_form') areaForm: ScenePlatformInfo;
  @deserializeAs('created_at') createdAt: string | null;
  @deserializeAs('updated_at') updatedAt: string | null;
  @deserialize name: string;
  @deserializeAs(SceneGenreModel) genres: SceneGenreModel[];
  @deserialize subgenre: string;
  @deserialize date: string;
  @deserializeAs('start_time') startTime: string;
  @deserializeAs('end_time') endTime: string;
  @deserialize description: string | null;
  @autoserializeAs('photo_site') photoSite: FileUploadResponse | null;
  @autoserializeAs('photo_site_2') photoSite2: FileUploadResponse | null;
  @autoserializeAs('photo_site_header') photoSiteHeader: FileUploadResponse | null;
  @deserializeAs('partner_logos') partnerLogos: Array<FileUploadResponse>;
  @deserializeAs(SceneNumbersModel) numbers: SceneNumbersModel | null;

  constructor(
    id: number,
    areaForm: ScenePlatformInfo,
    createdAt: string,
    updatedAt: string,
    name: string,
    genres: SceneGenreModel[],
    subgenre: string,
    date: string,
    startTime: string,
    endTime: string,
    description: string,
    photoSite: FileUploadResponse,
    photoSite2: FileUploadResponse,
    photoSiteHeader: FileUploadResponse,
    partnerLogos: Array<FileUploadResponse>,
    numbers: SceneNumbersModel
  ) {
    this.id = id
    this.areaForm = areaForm
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.name = name
    this.genres = genres
    this.subgenre = subgenre
    this.date = date
    this.startTime = startTime
    this.endTime = endTime
    this.description = description
    this.photoSite = photoSite
    this.photoSite2 = photoSite2
    this.photoSiteHeader = photoSiteHeader
    this.partnerLogos = partnerLogos
    this.numbers = numbers
  }
}

export class SceneSaveRequest {
  @autoserialize name: string | null;
  @autoserialize genres: string[];
  @autoserialize subgenre: string | null;
  @autoserialize description: string | null;
  @autoserialize date: string;
  @autoserializeAs('start_time') startTime: string;
  @autoserializeAs('end_time') endTime: string;
  @autoserializeAs('photo_site') photoSite: FileUploadResponse | null;
  @autoserializeAs('photo_site_2') photoSite2: FileUploadResponse | null;
  @autoserializeAs('photo_site_header') photoSiteHeader: FileUploadResponse | null;
  @autoserializeAs('partner_logos') partnerLogos: Array<FileUploadResponse | undefined> | [];
  @autoserializeAs(SceneNumbersModel) numbers: SceneNumbersModel | null;

  constructor(
    name: string,
    genres: string[],
    subgenre: string,
    description: string,
    date: string,
    startTime: string,
    endTime: string,
    photoSite: FileUploadResponse,
    photoSite2: FileUploadResponse,
    photoSiteHeader: FileUploadResponse,
    partnerLogos: Array<FileUploadResponse | undefined>,
    numbers: SceneNumbersModel
  ) {
    this.name = name
    this.genres = genres
    this.subgenre = subgenre
    this.description = description
    this.date = date
    this.startTime = startTime
    this.endTime = endTime
    this.photoSite = photoSite
    this.photoSite2 = photoSite2
    this.photoSiteHeader = photoSiteHeader
    this.partnerLogos = partnerLogos
    this.numbers = numbers
  }
}
import React from 'react'
import classNames from 'classnames'
import MaskedInput from 'react-text-mask'

import './Input.style.sass'

interface PropTypes {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: string;
  placeholder: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  type?: string;
  error?: string;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  mask?: boolean | (string | RegExp)[] | ((value: string) => Array<string | RegExp> | boolean) | undefined;
  guide?: boolean;
  disabled?: boolean;
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  isLabelCentered?: boolean;
  shouldHideErrorMessage?: boolean;
  autoWidth?: boolean;
}

const block = 'input-component'

export default class InputComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { error, icon, isLabelCentered, shouldHideErrorMessage, autoWidth, ...restProps } = this.props
    const inputProps = {
      ...restProps,
      type: this.props.type || 'text',
      className: `${block}__input`,
      maxLength: 255
    }
    const inputMaskProps = {
      ...restProps,
      mask: this.props.mask,
      guide: this.props.guide || false,
      type: this.props.type || 'text',
      className: `${block}__input`,
      maxLength: 255
    }
    const propsToPass = this.props.mask ? {...inputMaskProps} : {...inputProps}

    return(
      <div className={classNames(
        block,
        error?.length && `${block}--error`,
        icon && `${block}--with-icon`,
        this.props.disabled && `${block}--disabled`,
        isLabelCentered && `${block}--label-centered`
      )}>
        {icon && (
          <div className={`${block}__icon`}>
            {React.createElement(icon)}
          </div>
        )}
        {React.createElement(this.props.mask ? MaskedInput : 'input', {...propsToPass})}
        {error && !shouldHideErrorMessage && (
          <div className={`${block}__error`}>
            {error}
          </div>
        )}
        
      </div>
    )
  }
}

import React from 'react'
import PerformanceArtistsComponent from '../../../../../components/CRM/AdminCRM/PlatformModeration/PerformanceArtists'
import { sheduleManageStore } from '../../../../../stores/CRM/AdminCRM/scenes/sheduleManage.store'
import { 
  getTimeline, 
  getPerformanceArtists, 
  createPerformance 
} from '../../../../../services/CRM/AdminCRM/scenes/performance.service'
import { observer } from 'mobx-react'
import { performanceArtistsStore } from '../../../../../stores/CRM/AdminCRM/scenes/performanceArtists.store'
import { 
  PerformanceArtistFilterModel, 
  PerfomanceSaveRequest 
} from '../../../../../models/ScenesModels/performances.model'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { performanceTimelineStore } from '../../../../../stores/CRM/AdminCRM/scenes/timelineStore'
import { performanceCreateStore } from '../../../../../stores/CRM/AdminCRM/scenes/performanceCreate.store'
import shallowequal from 'shallowequal'
import { PATH_ADMIN_ARTIST_MODERATE } from '../../../../../const/routes.const'
import { performanceListStore } from '../../../../../stores/CRM/AdminCRM/scenes/performanceList.store'
import { toJS } from 'mobx'

interface State {
  isFilterVisible: boolean;
  filterValues?: PerformanceArtistFilterModel;
  visibleTimelineId?: number;
}
const artistsToLoad = 10

@observer
class PerformanceArtistsContainer 
extends React.Component<RouteComponentProps<{platformId: string; sceneId: string}>, State> {
  state: State = {
    isFilterVisible: false
  }

  componentDidMount(): void {
    const searchString = new URLSearchParams(this.props.location.search).get("name") || undefined
    
    this.getArtists(searchString, artistsToLoad)
  }

  componentWillUnmount(): void {
    performanceArtistsStore.clearErrors()
    performanceArtistsStore.clearData()
    performanceTimelineStore.clearData()
  }

  goBack = (): void => {
    sheduleManageStore.setActiveScreen('LIST')
  }

  getArtists = (search?: string, limit?: number, offset?: number): void => {
    const sceneId = parseInt(this.props.match.params.sceneId)

    if (sceneId) {
      const requestParams = {
        sceneId,
        ...this.state.filterValues,
        search,
        limit,
        offset
      }

      getPerformanceArtists(requestParams)
    }
  }

  loadMoreArtists = (): void => {
    const currentOffset = performanceArtistsStore.currentOffset
    
    this.getArtists(
      new URLSearchParams(this.props.location.search).get("name") || undefined,
      artistsToLoad,
      currentOffset ? (
        currentOffset + artistsToLoad
      ) : artistsToLoad
    )

    performanceArtistsStore.setOffset(currentOffset ? currentOffset + artistsToLoad : artistsToLoad)
  }

  toggleFilter = (isVisible?: boolean): void => {
    if (isVisible !== undefined) {
      this.setState({
        isFilterVisible: isVisible
      })
    } else {
      this.setState(prevState => ({
        isFilterVisible: !prevState.isFilterVisible
      }))
    }
  }

  getTimeline = (sceneId: number, artistId: number): void => {
    getTimeline({sceneId, artistId})
  }

  onArtistOpen = (id: number): void => {
    const sceneId = parseInt(this.props.match.params.sceneId)

    performanceTimelineStore.clearData()
    this.getTimeline(sceneId, id)
    this.setVisibleTimelineId(id)
  }

  setVisibleTimelineId = (id?: number): void => {
    this.setState({
      visibleTimelineId: id
    })
  }

  createPerformance = (performanceData: PerfomanceSaveRequest, artistId: number): void => {
    const sceneId = parseInt(this.props.match.params.sceneId)
    
    createPerformance({
      ...performanceData,
      artistId,
      sceneId
    })
  }

  applyFilter = (values: PerformanceArtistFilterModel): void => {
    const searchString = new URLSearchParams(this.props.location.search).get("name") || undefined
    const hasFilterChanged = !shallowequal(values, {})

    hasFilterChanged && performanceArtistsStore.clearData()

    this.setState({
      filterValues: values,
      isFilterVisible: false
    }, () => hasFilterChanged && this.getArtists(searchString, artistsToLoad, 0))
  }

  resetFilter = (): void => {
    const searchString = new URLSearchParams(this.props.location.search).get("name") || undefined
    const hasFilterChanged = !shallowequal(this.state.filterValues, {})

    hasFilterChanged && performanceArtistsStore.clearData()

    this.setState({
      filterValues: {}
    }, () => hasFilterChanged && this.getArtists(searchString))
  }

  getShouldLoadMore = (): boolean => {
    const { count, artists } = performanceArtistsStore

    if (count && artists) {
      return count > artists.length
    } else return true
  }

  closeErrorsModal = (): void => {
    const sceneId = parseInt(this.props.match.params.sceneId)
    performanceCreateStore.clearErrors()

    if (this.state.visibleTimelineId) {
      this.getTimeline(sceneId, this.state.visibleTimelineId)
    }
  }

  goToArtist = (artistFormId: number | null, artistId: number): void => {
    const url = !!artistFormId ? (
      `${document.location.origin}${PATH_ADMIN_ARTIST_MODERATE}/${artistFormId}`
    ) : (
      `https://uralmusicnight.ru/artist/${artistId}`
    )

    const win = window.open(url, '_blank')
    win && win.focus()
  }
  

  render(): JSX.Element {
    return (
      <PerformanceArtistsComponent 
        onBackClick={this.goBack}
        onSearchChange={(value: string): void => {
          performanceArtistsStore.clearData()

          this.getArtists(value)
        }}
        onFilterToggle={this.toggleFilter}
        isArtistsLoading={performanceArtistsStore.isLoading}
        artistsData={performanceArtistsStore.artists}
        onFilterReset={this.resetFilter}
        onFilterApply={this.applyFilter}
        filterValues={this.state.filterValues}
        isFilterVisible={this.state.isFilterVisible}
        onLoadMore={this.loadMoreArtists}
        shouldLoadMore={this.getShouldLoadMore()}
        visibleTimelineId={this.state.visibleTimelineId}
        isTimelineLoading={performanceTimelineStore.isLoading}
        onArtistOpen={this.onArtistOpen}
        onArtistClose={this.setVisibleTimelineId}
        timelineData={performanceTimelineStore.timelineData}
        onPerformanceCreate={this.createPerformance}
        isPerformanceCreating={performanceCreateStore.isLoading}
        requestErrors={performanceCreateStore.requestErrors}
        onErrorsModalClose={this.closeErrorsModal}
        onEyeClick={this.goToArtist}
        currentScheduleData={toJS(performanceListStore.timelineData)}
      />
    )
  }
}

export default withRouter(PerformanceArtistsContainer)
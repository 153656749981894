import { SceneExpertAddRequest, PlatformExpertAddRequest } from "../../../../models/ScenesModels/addExpert.model"
import { Serialize, Deserialize } from "cerialize"
import { addExpertStore } from "../../../../stores/CRM/AdminCRM/scenes/addExpert.store"
import { api } from "../../../../utils/api"
import { RequestErrorResponse } from "../../../../models/common/error.model"
import { sceneExpertsStore, platformExpertsStore } from "../../../../stores/CRM/AdminCRM/scenes/experts.store"
import { PlatformExpertModel } from "../../../../models/ScenesModels/experts.model"


export function addSceneExpert(request: SceneExpertAddRequest, sceneId: number): Promise<void> {
  const serializedData = Serialize(request, SceneExpertAddRequest)

  addExpertStore.setIsLoading(true)

  return api.post(
    `/crm/admin/place_forms/${sceneId}/experts/`,
    serializedData
  ).then((res) => {
    const data = Deserialize(res.data, PlatformExpertModel)
    sceneExpertsStore.addExpert(data)
    addExpertStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    addExpertStore.setIsLoading(false)
    addExpertStore.setErrors(errorResponseData.errors)
    throw error
  })
}

export function addPlatformExpert(request: PlatformExpertAddRequest, platformId: number): Promise<void> {
  const serializedData = Serialize(request, PlatformExpertAddRequest)

  addExpertStore.setIsLoading(true)

  return api.post(
    `/crm/admin/area_forms/${platformId}/experts/`,
    serializedData
  ).then((res) => {
    const data = Deserialize(res.data, PlatformExpertModel)
    platformExpertsStore.addExpert(data)
    addExpertStore.setIsLoading(false)
  }).catch((error) => {
    const errorResponseData: RequestErrorResponse = error.response.data
    addExpertStore.setIsLoading(false)
    addExpertStore.setErrors(errorResponseData.errors)
    throw error
  })
}
import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getExpertList } from '../../../../services/CRM/AdminCRM/experts/expertList.service'
import { observer } from 'mobx-react'
import { expertListStore } from '../../../../stores/CRM/AdminCRM/experts/expertsList.store'
import ExpertListComponent from '../../../../components/CRM/AdminCRM/ExpertList/ExpertList.component'
import { PATH_CRM_EXPERT_ADD, PATH_EXPERTS } from '../../../../const/routes.const'
import { forceCheck } from 'react-lazyload'


@observer
class ExpertListContainer extends React.Component<RouteComponentProps> {
  componentDidMount(): void {
    getExpertList()
  }

  componentWillUnmount(): void {
    expertListStore.clearData()
    expertListStore.clearErrors()
  }

  componentDidUpdate(prevProps: RouteComponentProps): void {
    if (prevProps.location.search !== this.props.location.search) {
      forceCheck()
    }
  }

  addNewExpert = (): void => {
    this.props.history.push(PATH_CRM_EXPERT_ADD)
  }

  goToExpert = (id: number): void => {
    this.props.history.push(`${PATH_EXPERTS}/${id}`)
  }

  render(): JSX.Element {
    const {
      location: { search }
    } = this.props
    const params = new URLSearchParams(search)
    const query = params.get("name")

    return (
      <ExpertListComponent 
        isLoading={expertListStore.isLoading}
        onPlusButtonClick={this.addNewExpert}
        onExpertClick={this.goToExpert}
        experts={expertListStore.getFilteredExperts(query || '')}
      />
    )
  }
}

export default withRouter(ExpertListContainer)
import classNames from "classnames"
import React, { FC } from "react"
import { Controller, useForm } from "react-hook-form"
import MaskedInput from "react-text-mask"
import { createNumberMask } from "text-mask-addons"
import { EvaluateForm, SelectionExpertArtistGenre } from "../../../../../models/SelectionExpertsModels/selectionExpertEvaluation.model"
import Button from "../../../../Button/Button.component"

import "./style.sass"

interface Props {
  onSubmit: (requestData: EvaluateForm) => void;
  isLoading: boolean;
  error?: string;
  data?: EvaluateForm;
  genre?: SelectionExpertArtistGenre
}

const block = "selection-expert-evaluate-form"

const EvaluateFormComponent: FC<Props> = (props) => {
  const { error, isLoading, onSubmit, data, genre } = props
  const { handleSubmit, errors, control, watch, formState } =
    useForm<EvaluateForm>({ defaultValues: data })

  const musicRatingField = watch("musicRating")
  const emotionalRatingField = watch("emotionalRating")
  const concertActivitiesRatingField = watch("concertActivitiesRating")

  const getSummary = (): number => {
    const musicRating = !!musicRatingField ? parseInt(musicRatingField) : 0
    const emotionalRating = !!emotionalRatingField
      ? parseInt(emotionalRatingField)
      : 0
    const concertActivitiesRating = !!concertActivitiesRatingField
      ? parseInt(concertActivitiesRatingField)
      : 0

    return musicRating + emotionalRating + concertActivitiesRating
  }

  const getError = (): string | boolean => {
    const errorFieldType =
      errors.musicRating?.type ||
      errors.emotionalRating?.type ||
      errors.concertActivitiesRating?.type

    if (errorFieldType === "inRange") {
      return "В некоторых полях оценка не вписывается в допустимый диапазон."
    }

    if (errorFieldType === "required") {
      return "Заполнены не все обязательные поля."
    }

    return false
  }

  return (
    <div className={block}>
      <div className={`${block}__title`}>ОЦЕНИТЬ КОЛЛЕКТИВ</div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-labels-block`}>
          <div className={`${block}__input-title`}>
            От 1 до 10 баллов, где 10 баллов – это высокий уровень качества, 1 балл – это низкий уровень качества
          </div>
          <div className={`${block}__input-label`}>
            {genre?.label === 'dance' ? 
              'Уровень исполнительского мастерства, оригинальность, сложность танцевальных движений, артистизм и зрелищность.' :
              'Качество музыкальных произведений во всей его совокупности: инструментальный ряд, текст, мелодия, вокальное исполнение и др.'
            }
          </div>
        </div>

        <div className={`${block}__input`}>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Это обязательное поле"
              },
              validate: {
                inRange: (value): boolean | string => {
                  const valueInt = parseInt(value)
                  const maxValue = 10
                  const minValue = 1

                  if (valueInt <= maxValue && valueInt >= minValue) {
                    return true
                  } else {
                    return "Введите число в диапазоне"
                  }
                }
              }
            }}
            control={control}
            name="musicRating"
            defaultValue={null}
            render={({ onChange, value, name }): JSX.Element => (
              <MaskedInput
                className={classNames(
                  `${block}__input`,
                  errors.musicRating?.message && `${block}__input--error`
                )}
                onChange={(event): void => onChange(event.target.value)}
                name={name}
                value={value}
                mask={createNumberMask({
                  prefix: "",
                  includeThousandsSeparator: false,
                  allowDecimal: false,
                  integerLimit: 2
                })}
                placeholder={"0"}
                disabled={!!data?.musicRating}
              />
            )}
          />
        </div>
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-labels-block`}>
          <div className={`${block}__input-title`}>
            От 1 до 10 баллов, где 10 баллов – это высокий уровень эмоционального отклика, 1 балл – это низкий уровень эмоционального отклика
          </div>
          <div className={`${block}__input-label`}>
            Уровень эмоционального отклика члена экспертного совета на
            представленные произведения.
          </div>
        </div>

        <div className={`${block}__input`}>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Это обязательное поле"
              },
              validate: {
                inRange: (value): boolean | string => {
                  const valueInt = parseInt(value)
                  const maxValue = 10
                  const minValue = 1

                  if (valueInt <= maxValue && valueInt >= minValue) {
                    return true
                  } else {
                    return "Введите число в диапазоне"
                  }
                }
              }
            }}
            control={control}
            name="emotionalRating"
            defaultValue={null}
            render={({ onChange, value, name }): JSX.Element => (
              <MaskedInput
                className={classNames(
                  `${block}__input`,
                  errors.emotionalRating?.message && `${block}__input--error`
                )}
                onChange={(event): void => onChange(event.target.value)}
                name={name}
                value={value}
                mask={createNumberMask({
                  prefix: "",
                  includeThousandsSeparator: false,
                  allowDecimal: false,
                  integerLimit: 2
                })}
                placeholder={"0"}
                disabled={!!data?.emotionalRating}
              />
            )}
          />
        </div>
      </div>

      <div className={`${block}__input-block`}>
        <div className={`${block}__input-labels-block`}>
          <div className={`${block}__input-title`}>
            От 1 до 5 баллов, где 5 баллов – это большой опыт, 1 балл – это отсутствие опыта
          </div>
          <div className={`${block}__input-label`}>
            {genre?.label === 'dance' ? 
              'Опыт публичных выступлений и участия в фестивалях и конкурсах' :
              'Целесообразность выступления исполнителя в фестивальном формате (в т.ч. концертный опыт, актуальность музыки, бэкграунд артиста, зрелищность сценического перфоманса)'
            }
          </div>
        </div>

        <div className={`${block}__input`}>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Это обязательное поле"
              },
              validate: {
                inRange: (value): boolean | string => {
                  const valueInt = parseInt(value)
                  const maxValue = 5
                  const minValue = 1

                  if (valueInt <= maxValue && valueInt >= minValue) {
                    return true
                  } else {
                    return "Введите число в диапазоне"
                  }
                }
              }
            }}
            control={control}
            name="concertActivitiesRating"
            defaultValue={null}
            render={({ onChange, value, name }): JSX.Element => (
              <MaskedInput
                className={classNames(
                  `${block}__input`,
                  errors.concertActivitiesRating?.message &&
                    `${block}__input--error`
                )}
                onChange={(event): void => onChange(event.target.value)}
                name={name}
                value={value}
                mask={createNumberMask({
                  prefix: "",
                  includeThousandsSeparator: false,
                  allowDecimal: false,
                  integerLimit: 1
                })}
                placeholder={"0"}
                disabled={!!data?.concertActivitiesRating}
              />
            )}
          />
        </div>
      </div>

      <div className={`${block}__summary`}>
        <div className={`${block}__summary-title`}>Итого баллов:</div>
        <div className={`${block}__summary-amount`}>{getSummary()}</div>
      </div>

      {!(data?.musicRating &&
        data?.emotionalRating &&
        data?.concertActivitiesRating) && (
          <div className={`${block}__button`}>
            <Button
              onClick={handleSubmit(onSubmit)}
              background="purple"
              text="Отправить оценку"
              isDisabled={isLoading}
            />
          </div>
        )}

      {!formState.isValid && (
        <div className={`${block}__error`}>{getError()}</div>
      )}

      {!!error && <div className={`${block}__error`}>{error}</div>}
    </div>
  )
}

export default EvaluateFormComponent

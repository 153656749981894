import { ApiRequestStore } from "../../../common/apiRequest.store"
import { observable, action } from "mobx"
import {
  PerformanceListResponse, PerformanceGetModel, PerformanceStatuses, ScheduleTimelineItem
} from "../../../../models/ScenesModels/performances.model"
import moment from "moment"

class PerformanceListStore extends ApiRequestStore {
  @observable performances?: PerformanceGetModel[];
  @observable timelineData?: ScheduleTimelineItem[];

  @action
  setPerformances = (perfomancesData: PerformanceListResponse): void => {
    this.performances = perfomancesData.performances
    this.timelineData = perfomancesData.timeline
  }

  @action
  clearData = (): void => {
    this.performances = undefined
  }

  @action
  deletePerformance = (id: number): void => {
    this.performances = this.performances?.filter(performance => performance.id !== id)
    this.timelineData = this.timelineData?.filter(timelineDataItem => timelineDataItem.performanceId !== id)
  }

  @action
  updatePerformanceStatus = (id: number, status: PerformanceStatuses): void => {
    this.performances = this.performances?.map((performance) => {
      if (performance.id === id) {
        return {...performance, status}
      } else {
        return performance
      }
    })
  }

  @action
  updatePerformanceData = (id: number, data: PerformanceGetModel): void => {
    this.performances = this.performances?.map((performance) => {
      if (performance.id === id) {
        return data
      } else {
        return performance
      }
    })

    this.timelineData = this.timelineData?.map((timelineDataItem) => {
      if (timelineDataItem.performanceId === id) {
        if (timelineDataItem.type === 'performance') {
          return {
            ...timelineDataItem,
            start: moment(data.startTime, 'HH:mm:ss').toISOString(),
            end: moment(data.endTime, 'HH:mm:ss').toISOString()
          }
        } else {
          return {
            ...timelineDataItem,
            start: moment(data.soundcheckStartTime, 'HH:mm:ss').toISOString(),
            end: moment(data.soundcheckEndTime, 'HH:mm:ss').toISOString()
          }
        }
      } else {
        return timelineDataItem
      }
    })
  }
}

export const performanceListStore: PerformanceListStore = new PerformanceListStore()
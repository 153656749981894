import { ApiRequestStore } from "../../common/apiRequest.store"
import { observable, action } from "mobx"
import { 
  ArtistFormNotificationsResponse 
} from "../../../models/ArtistFormNotificationsModels/ArtistFormNotifications.model"
import moment from "moment"

class ArtistFormNotificationsStore extends ApiRequestStore {
  @observable notificationsData?: ArtistFormNotificationsResponse
  
  @action
  setNotificationsData(data: ArtistFormNotificationsResponse): void {
    this.notificationsData = data
  }

  @action
  clearData(): void {
    this.notificationsData = undefined
  }

  @action
  addNotificationOnApply = (): void => {
    if (this.notificationsData) {
      this.notificationsData = {
        ...this.notificationsData,
        status: 'MODERATION',
        notifications: [
          {
            type: 'SENT_TO_MODERATION',
            text: 'Ваша анкета была отправлена на модерацию.',
            createdAt: moment().toString()
          },
          ...this.notificationsData.notifications
        ]
      }
    }
  }
}

export const artistFormNotificationsStore: ArtistFormNotificationsStore = new ArtistFormNotificationsStore()
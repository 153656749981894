import React, { FC } from 'react'
import CircleButtonComponent from '../../common/CircleButton/CircleButton.component'
import { ReactComponent as ExitIcon } from '../../../../assets/icons/exit.svg'

import './style.sass'

interface Props {
  onLogout: () => void;
  title: string;
}

const block = 'selection-expert-header'

const SelectionExpertHeaderComponent: FC<Props> = (props) => {
  const { onLogout, title } = props

  return (
    <div className={block}>
      <div className="container row row--space-between">
        <div className="page-title">
          {title}
        </div>
        <CircleButtonComponent onClick={onLogout}>
          <ExitIcon />
        </CircleButtonComponent>
      </div>
    </div>
  )
}

export default SelectionExpertHeaderComponent
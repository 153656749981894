import { observer } from 'mobx-react'
import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router'
import 
  SelectionExpertDashboardComponent 
from 
  '../../../components/CRM/SelectionExpertCRM/SelectionExpertDashboard/SelectionExpertDashboard.component'
import { PATH_SELECTION_EXPERT_EVALUATION } from '../../../const/routes.const'
import { getSelectionExpertDashboardData } from '../../../services/CRM/SelectionExpertCRM/dashboard.service'
import { selectionExpertDashboardStore } from '../../../stores/CRM/SelectionExpertCRM/selectionExpertDashboard.store'

const SelectionExpertDashboardContainer: FC = () => {
  useEffect(() => {
    getSelectionExpertDashboardData()

    return (): void => {
      selectionExpertDashboardStore.clearData()
    }
  }, [])

  const history = useHistory()

  const goToEvaluation = (): void => {
    history.push(PATH_SELECTION_EXPERT_EVALUATION)
  }

  const goToReadEvaluation = (artistId: number): void => {
    history.push(`${PATH_SELECTION_EXPERT_EVALUATION}/${artistId}`)
  }

  const {
    isLoading,
    dashboardData
  } = selectionExpertDashboardStore

  return (
    <SelectionExpertDashboardComponent  
      isLoading={isLoading}
      data={dashboardData}
      onEvaluateClick={goToEvaluation}
      onReadEvaluateClick={goToReadEvaluation}
    />
  )
}

export default observer(SelectionExpertDashboardContainer)
import React from 'react'
import { ListSceneModel } from '../../../../../models/ScenesModels/scenesList.model'
import CircleButtonComponent from '../../../common/CircleButton/CircleButton.component'
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/icon-plus.svg"
import ModalComponent from '../../../../common/Modal/Modal.component'
import InputComponent from '../../../common/Input/Input.component'
import Button from '../../../../Button/Button.component'
import LoaderComponent from '../../../../common/Loader/Loader.component'
import { ReactComponent as Remove } from '../../../../../assets/icons/remove.svg'

import './ScenesList.style.sass'

interface PropTypes {
  scenes?: ListSceneModel[];
  onSceneAddClick: () => void;
  onSceneCreate: () => void;
  newSceneName: string;
  isScenesLoading: boolean;
  isModalVisible: boolean;
  onSceneNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isSceneCreating: boolean;
  onModalToggle: () => void;
  onSceneClick: (id: number, name: string) => void;
  onDelete: (sceneId: number) => void;
  isDeleteModalVisible: boolean;
  isDeleteLoading: boolean;
  onDeleteModalClose: () => void;
  onDeleteModalApply: () => void;
  isCheckedStatus: boolean; 
}

const block = 'scenes-list'

export default class ScenesListComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { 
      scenes, 
      onSceneAddClick, 
      isScenesLoading, 
      newSceneName, 
      isModalVisible,
      onSceneNameChange,
      onSceneCreate,
      isSceneCreating,
      onModalToggle,
      onSceneClick,
      onDelete,
      onDeleteModalApply,
      onDeleteModalClose,
      isDeleteModalVisible,
      isDeleteLoading,
      isCheckedStatus
    } = this.props

    const userRole = localStorage.getItem('userRole')

    return (
      <div className={`${block}`}>
        <div className="container">
          {isScenesLoading ? (
            <div className={`${block}__loading`}>
              <LoaderComponent width={100} height={100} />
            </div>
          ) : (
            <>
            <div className="page-title page-title--black">
              СЦЕНЫ
            </div>
            {userRole === 'ADMIN' && (
              <div className={`${block}__add-block margin--top-m`}>
                <CircleButtonComponent onClick={onSceneAddClick}>
                  <PlusIcon fill="#FFFFFF" />
                </CircleButtonComponent>
                <div className={`${block}__add-block-text`}>
                  Добавить новую сцену
                </div>
              </div>
            )}
            <div className={`${block}__scenes`}>
              {scenes?.map((scene, index): JSX.Element => (
                <div className="row" 
                  key={`list-scene-${index}`} 
                >
                  <div 
                    className={`${block}__scenes-item`} 
                    onClick={(): void => onSceneClick(scene.id, scene.name)}
                  >
                    <div className={`${block}__scenes-item-image`}></div>
                    <div className={`${block}__scenes-item-name`}>
                      {scene.name}
                    </div>
                  </div>
                  {!isCheckedStatus && <div 
                    className={`${block}__scenes-item-remove`}
                    onClick={(): void => onDelete(scene.id)}
                  >
                    <Remove width="30" height="30" />
                  </div>}
                </div>
              ))}

              {!scenes?.length && (
                <div>
                  Список сцен пуст
                </div>
              )}
            </div>
  
            <ModalComponent isVisible={isModalVisible} title="Создать новую сцену" onModalClose={onModalToggle}>
              <div className="margin margin--top-s">
                <InputComponent 
                  name="scene-name"
                  placeholder="Введите название сцены"
                  value={newSceneName}
                  onChange={onSceneNameChange}
                />
              </div>
              <div className="margin margin--top-s margin--auto margin--bottom-0">
                <Button
                  onClick={onSceneCreate}
                  text="Создать сцену"
                  isDisabled={!newSceneName.length}
                  background="purple"
                  isLoading={isSceneCreating}
                />
              </div>
            </ModalComponent>

            <ModalComponent 
              isVisible={isDeleteModalVisible} 
              title="Удалить сцену" 
              onModalClose={onDeleteModalClose}
            >
              <div className="margin margin--top-s margin--auto margin--bottom-0">
                Вы уверены, что хотите безвозвратно удалить сцену?
              </div>
              <div className="margin--top-l row row--space-evenly margin--bottom-xxs">
                <Button
                  onClick={onDeleteModalApply}
                  text="Удалить"
                  background="purple"
                  isLoading={isDeleteLoading}
                />
                <Button
                  onClick={onDeleteModalClose}
                  text="Отмена"
                  background="pale-blue"
                isDisabled={isDeleteLoading}
                />
              </div>
            </ModalComponent>
            </>
          )}
        </div>

      </div>
    )
  }
}
import { ApiRequestStore } from '../../../common/apiRequest.store'
import { action, observable } from 'mobx'
import { SceneSaveRequest, SceneGetResponse } from '../../../../models/ScenesModels/scene.model'

class SceneFormSaveStore extends ApiRequestStore {
  @observable saveTime?: Date;

  @action
  setSaveTime(date: Date): void {
    this.saveTime = date
  } 

  @action
  clearData = (): void => {
    this.saveTime = undefined
  }
}

class SceneFormGetStore extends ApiRequestStore {
  @observable sceneData?: SceneGetResponse;
  @observable formData?: SceneSaveRequest;

  @action
  setFormData(data: SceneSaveRequest): void {
    this.formData = data
  }

  @action
  setSceneData(data: SceneGetResponse): void {
    this.sceneData = data
  }

  @action
  clearData = (): void => {
    this.formData = undefined
  }
}

export const sceneFormGetStore: SceneFormGetStore = new SceneFormGetStore()
export const sceneFormSaveStore: SceneFormSaveStore = new SceneFormSaveStore()
import { api } from "../../../../utils/api"
import { Deserialize } from "cerialize"
import { RequestErrorResponse } from "../../../../models/common/error.model"
import { PlatformListGetResponse, ListPlatformModel, ExpertPlatformListGetResponse } from "../../../../models/ScenesModels/platformList.model"
import { adminPlatformListStore, expertPlatformListStore } from "../../../../stores/CRM/AdminCRM/scenes/platformList.store"

export function getPlatformList(): Promise<void> {
  const userRole = localStorage.getItem('userRole')
  const url = userRole === 'ADMIN' ? '/crm/admin/area_forms/' : '/crm/expert/area_forms/'

  if (userRole === 'ADMIN') {
    adminPlatformListStore.setIsLoading(true)
  } else {
    expertPlatformListStore.setIsLoading(true)
  }
  
  return api
    .get(url)
    .then((res) => {
      const adminPlatformsData: PlatformListGetResponse = Deserialize(
        res.data,
        PlatformListGetResponse
      )
      const expertPlatformsData: ExpertPlatformListGetResponse = Deserialize(
        res.data,
        ExpertPlatformListGetResponse
      )
      
      if (userRole === 'ADMIN') {
        adminPlatformListStore.setPlatformListData(adminPlatformsData)
        adminPlatformListStore.setIsLoading(false)
      } else {
        expertPlatformListStore.setPlatformListData(expertPlatformsData)
        expertPlatformListStore.setIsLoading(false)
      }
    })
    .catch((error) => {
      const errorResponseData: RequestErrorResponse = error.response.data
      if (userRole === 'ADMIN') {
        adminPlatformListStore.setIsLoading(false)
        adminPlatformListStore.setErrors(errorResponseData.errors)
      } else {
        expertPlatformListStore.setErrors(errorResponseData.errors)
        expertPlatformListStore.setIsLoading(false)
      }
    })
}

import React, { useState } from 'react'
import { ArtistIntersectionArtist } from "../../../../../models/ArtistsIntersectionsModels/ArtistsIntersections.model"
import { ReactComponent as Micro } from '../../../../../assets/icons/micro.svg'
import moment from 'moment'

import './ArtistIntersectionsItem.style.sass'

interface PropTypes {
  name: string;
  artists: ArtistIntersectionArtist[];
}

const block = 'artist-intersections'

export default function ArtistIntersections(props: PropTypes): JSX.Element {
  const { name, artists } = props

  const [isOpened, setIsOpened] = useState(false)

  return (
    <div className={block}>
      <div className={`${block}__header`} onClick={(): void => setIsOpened(!isOpened)}>
        <div className={`${block}__avatar`}>
          <Micro width={50} height={50} />
        </div>
        <div className={`${block}__name`}>
          {name}
        </div>
      </div>
      {isOpened && (
        <div className={`${block}__body`}>
          {artists.map(artist => (
            <div className={`${block}__item`} key={artist.id}>
              <div className={`${block}__artist`}>
                {artist.name}
              </div>
              <div className={`${block}__performances`}>
                {artist.performances.map(performance => (
                  <div key={performance.id} className={`${block}__performances-item`}>
                    <div className={`${block}__performance-time`}>
                      {moment(performance.startDatetime).format('HH:mm')}
                      {' - '}
                      {moment(performance.endDatetime).format('HH:mm')}
                    </div>
                    <div className={`${block}__performance-place`}>
                      {performance.place.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
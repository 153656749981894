import React from 'react'
import { Field, withTypes } from 'react-final-form'
import { RegisterFormFields } from '../../models/AuthModels/register.model'
import { ReactComponent as UserIcon } from '../../assets/icons/user-icon.svg'
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg'
import { ReactComponent as PasswordIcon } from '../../assets/icons/password.svg'
import AuthFormInput from '../AuthFormInput/AuthFormInput.component'
import Button from '../Button/Button.component'
import AuthFormLayoutComponent from '../AuthFormLayout/AuthFormLayout.component'
import { fieldRequired, composeValidators, incorrectEmail } from '../../const/validators.const'
import { RequestError } from '../../models/common/error.model'

interface PropTypes {
  onSubmit: (values: RegisterFormFields) => void;
  onInputChange: () => void;
  isLoading: boolean;
  submittionErrors?: RequestError[];
}

interface FormFieldsErrors {
  name?: string;
  email?: string;
  password?: string;
  repeatPassword?: string;
}

const { Form } = withTypes<RegisterFormFields>()
const block = 'register-form'

export default class RegisterFormComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { onSubmit, submittionErrors, isLoading } = this.props

    return(
      <AuthFormLayoutComponent title="ПОДАЧА ЗАЯВКИ" className={block}>
        <Form
          onSubmit={onSubmit}
          validate={(values): FormFieldsErrors => {
            const errors: FormFieldsErrors = {}

            if (!values.repeatPassword) {
              errors.repeatPassword = 'Это обязательное поле'
            } else if (values.repeatPassword !== values.password) {
              errors.repeatPassword = 'Пароли не совпадают'
            }
            return errors
          }}
          render={({ handleSubmit, pristine, errors, touched, hasValidationErrors }): JSX.Element => (
            <form onSubmit={handleSubmit}>
              <Field name="name" validate={fieldRequired}>
                {({ input, meta }): JSX.Element => (
                    <div className="margin margin--top-m">
                      <AuthFormInput
                        placeholder="Название вашего коллектива"
                        onChange={(event): void => {input.onChange(event); this.props.onInputChange()}}
                        name={input.name}
                        value={input.value}
                        type={input.type}
                        icon={<UserIcon fill={meta.error && meta.touched ? "#cd5555" : "#D2DAE4"} />}
                        error={meta.touched && meta.error}
                      />
                    </div>
                  )}
              </Field>
              <Field name="email" validate={composeValidators(fieldRequired, incorrectEmail)}>
                {({ input, meta }): JSX.Element => (
                  <div className="margin margin--top-xs">
                    <AuthFormInput
                      placeholder="Email"
                      onChange={input.onChange}
                      name={input.name}
                      value={input.value}
                      type={input.type}
                      icon={<EmailIcon fill={meta.error && meta.touched ? "#cd5555" : "#D2DAE4"} />}
                      error={meta.touched && meta.error}
                    />
                  </div>
                )}
              </Field>
              <Field name="password" type="password" validate={fieldRequired}>
                {({ input, meta }): JSX.Element => (
                  <div className="margin margin--top-xs">
                    <AuthFormInput
                      placeholder="Пароль"
                      onChange={input.onChange}
                      name={input.name}
                      value={input.value}
                      type={input.type}
                      icon={<PasswordIcon fill={meta.error && meta.touched ? "#cd5555" : "#D2DAE4"} />}
                      error={meta.touched && meta.error}
                    />
                  </div>
                )}
              </Field>
              <Field name="repeatPassword" type="password" validate={fieldRequired}>
                {({ input, meta }): JSX.Element => (
                  <div className="margin margin--top-xs">
                    <AuthFormInput
                      placeholder="Подтверждение пароля"
                      onChange={input.onChange}
                      name={input.name}
                      value={input.value}
                      type={input.type}
                      icon={<PasswordIcon fill={meta.error && meta.touched ? "#cd5555" : "#D2DAE4"} />}
                      error={meta.touched && meta.error}
                    />
                  </div>
                )}
              </Field>

              {hasValidationErrors && touched && touched[Object.keys(errors)[0]] ? (
                <div className={'auth-form-layout__error auth-form-layout__error--static'}>
                  {errors[Object.keys(errors)[0]]}
                </div>
              ) : (submittionErrors?.length && (
                  <div className={'auth-form-layout__error auth-form-layout__error--static'}>
                    {submittionErrors[0].text}
                  </div>
              ))}

              <button className="margin margin--top-m" disabled={pristine || isLoading}>
                <Button
                  text="Зарегистрироваться"
                  background="purple"
                  onClick={(): null => null}
                  isDisabled={pristine || isLoading}
                />
              </button>
            </form>
          )}
        />
      </AuthFormLayoutComponent>
    )
  }
}

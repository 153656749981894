import React from "react"
import LazyLoad from "react-lazyload"
import classNames from "classnames"
import { ReactComponent as Star } from '../../../../assets/icons/star.svg'

import "./CrmListItem.style.sass"

interface PropTypes {
  title: string;
  image?: string;
  statusText?: string;
  statusColor?: 'red' | 'grey' | 'yellow' | 'green' | 'blue';
  onClick?: () => void;
  rating?: number;
}

const block = "crm-list-item"

export default class CrmListItemComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    const { title, image, statusText, statusColor, rating } = this.props

    return (
      <LazyLoad height={70} offset={40} once>
        <div className={block} onClick={this.props.onClick}>
          <div
            className={`${block}__avatar`}
            style={image ? { backgroundImage: `url(${image})` } : {}}
          />
          <div className={`${block}__name`}>{title}</div>

          {statusText && (
            <div className={`${block}__status`}>
              {statusText}
            </div>
          )}
          {statusColor && (
            <div
              className={classNames(
                `${block}__status-indicator ${block}--${statusColor}`
              )}
            />
          )}

          {rating && (
            <div className={`${block}__rating`}>
              <div className={`${block}__rating-amount`}>
                {rating}
              </div>
              <Star />
            </div>
          )}
        </div>
      </LazyLoad>
    )
  }
}
